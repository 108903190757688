export function isSafari() {
  return (
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  );
}

export function isFirefox() {
  return (
    /Firefox/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  );
}

export function isInternetExplorer() {
  return (
    /Mozilla/.test(navigator.userAgent) &&
    !/Firefox/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent) &&
    !/Safari/.test(navigator.userAgent) &&
    !/Opera/.test(navigator.userAgent)
  );
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  effect,
  input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../services/local.storage.service';
import { VisitorService } from '../../services/visitor.service';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/utils/object.extensions';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';
import { catchError, debounceTime, fromEvent, of, tap } from 'rxjs';
import { VisitorPlace } from '../../models/visitor.place';
import { ILinkPreview } from '../link.previewer/link.previewer.component';
import {
  AIModel,
  IAIResponse,
  ITravelAssistantMessage,
} from '../../models/travel.assistant';
import { SocialLoginComponent } from '../social.login/social.login.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TravelBuddySettings } from '../../models/travel.buddy.settings';
import { DOCUMENT } from '@angular/common';

// const { GoogleGenerativeAI } = require('@google/generative-ai');
// const genAI = new GoogleGenerativeAI('AIzaSyDkHVhpnG5Yg2TM-iJ2mnsQ4jUIL01cEnc');

@Component({
  selector: 'ig-im-message-box',
  templateUrl: './im.message.box.component.html',
  styleUrls: ['./im.message.box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImMessageBoxComponent extends BaseComponent implements OnInit {
  @HostBinding('class') hostClass = 'ig-im';

  right = input<string>();
  left = input<string>();
  bottom = input<string>();
  top = input<string>();

  chatRight: string;
  chatLeft: string;
  chatTop: string;
  chatBottom: string;

  /** control the display of the entire chat popup */
  isShow = false;
  isScreenExpanded = false;

  /* isShowLandscreen = true means in the first indication page of the AI chat feature */
  isShowLandscreen = true;
  hasChatViewShowed = false;
  isAskingAfterLogin = false;
  isWaitingForAIResponse = false;

  // need to specify these Tailwind classes to make sure they are bundled in the final CSS
  style = 'h-[700px] w-[640px] w-[400px] h-[300px]';
  form: FormGroup;
  msg: string;
  assistantId: string;
  threadId: string;
  customProcessor: (data: IAIResponse) => IAIResponse;
  @Input() aiModel: AIModel = 'chatgpt-then-google';
  @Input() public target = '';
  @Input() public place: VisitorPlace;
  /* the content in the landing page is showing up one by one */
  @Input() public showLandscreenContent: boolean[] = [];
  @Input() public travelBuddySettings: TravelBuddySettings;

  @Output() onSendClick: EventEmitter<string> = new EventEmitter();
  @Output() onTryAgain: EventEmitter<string> = new EventEmitter();
  @Output() onMsgCreated: EventEmitter<any> = new EventEmitter();
  @Output() onExpandScreen: EventEmitter<boolean> = new EventEmitter();

  msgHistory: ITravelAssistantMessage[];
  hasScrollbar = false;
  /*
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section,
    the top bannder section will be folded after AI has responded
  */
  isAIRespond = false;
  isFolding = false;
  /*
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section,
    the top bannder section will be folded, and after the folding completed, isInNormalChat will be set to true
  */
  isInNormalChat = false;
  fallbackLinkPreviewData: ILinkPreview = null;
  isAtbottom = false;

  isFirstTime = false;
  isForDestination = false;

  isShowLogin = false;
  isAppendLoginToChatArea = false;
  isAnonmous = false;
  userEmail: string | undefined = undefined;
  emailErrorMsg = '';

  userProfilePicture: string | undefined = undefined;
  isAllowTrackingChat = true; // currently removed the checkbox for asking for user permission, so we will track the conversations for all users

  errorMsgs = [
    `Looks like you've asked a question that's above my pay grade—err. Got another question to throw my way? `,
    `To err is human, to forgive divine, but for your AI Travel Buddy, it's downright awkward! Got another zinger for me? `,
    `Wow, you've managed to stump your AI buddy. You just won a million dollars! Just kidding. Got another question to throw my way? `,
    `You've found a gap in my digital wisdom! Mind tossing another question my way? `,
  ];

  @ViewChild('container', {static: true}) container: ElementRef;
  @ViewChild('chatContainer', {static: false}) chatContainer: ElementRef;
  @ViewChild('allowTrackingCbox', {static: false})
  allowTrackingCbox: ElementRef;
  @ViewChild('socialLogin', {static: false})
  socialLogin: SocialLoginComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _localStorage: LocalStorageService,
    private _visitorService: VisitorService,
    private _snackBar: MatSnackBar,
    private _scriptService: ScriptService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    super({activatedRoute});

    this.form = this._fb.group({
      msg: [null, [Validators.required]],
      email: [null, []],
    });

    this.resetStepShowing();

    effect(() => {
      // will be called when `right or bottom` is initialized or changes.
      console.log('right', this.right());
      console.log('bottom', this.bottom());
      console.log('left', this.left());
      console.log('top', this.top());

      this.getChatBoxPosition();
    });
  }

  ngOnInit() {
    const SCRIPT_PATH = `https://cdn.jsdelivr.net/npm/marked/marked.min.js`;

    if (!this._scriptService.isScriptExist(SCRIPT_PATH)) {
      const scriptElement = this._scriptService.loadJsScript(
        this._renderer,
        SCRIPT_PATH
      );
      scriptElement.onload = () => {
        console.log('markdown script loaded');
      };
      scriptElement.onerror = () => {
        console.log('Could not load the script: ' + SCRIPT_PATH);
      };
    }

    if (this.place) {
      this.fallbackLinkPreviewData = {
        title: this.place.title,
        description:
          this.place.description ||
          `${this.place.category} @ ${this.place.address.address}`,
        image: this.place.cover?.source,
        url: this.place.website,
      };
    }
  }

  get isTipShowed() {
    // return this._localStorage.getItem<boolean>('travelbuddy_isTipShowed');
    return false;
  }

  getChatBoxPosition() {
    const toggleRight = parseFloat(this.right());
    const toggleLeft = parseFloat(this.left());
    const toggleTop = parseFloat(this.top());
    const toggleBottom = parseFloat(this.bottom());
    this.chatRight = toggleRight ? this.right() : 'auto';
    this.chatLeft = toggleLeft ? this.left() : 'auto';
    this.chatTop = toggleTop ? `${toggleTop + 64}px` : 'auto';
    this.chatBottom = toggleBottom ? `${toggleBottom + 64}px` : 'auto';
  }

  checkHasScrollbar() {
    setTimeout(() => {
      if (this.chatContainer) {
        var div = this.chatContainer.nativeElement;
        this.hasScrollbar = div.scrollHeight > div.clientHeight;
      }
    }, 150);

    if (!this.isInNormalChat) {
      this.isAIRespond = this.msgHistory?.any(
        (x) => x.type === 'AI' || x.type === 'AI_ERROR'
      );

      if (this.isAIRespond) {
        this.isFolding = true;
        setTimeout(() => {
          this.isFolding = false;
          this.isInNormalChat = true;
          this.cdRef.detectChanges();
        }, 700);
      }
    }
  }

  show() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this._localStorage.setItem('travelbuddy_isTipShowed', true);
    }

    if (!this.isShow) {
      this.resetStepShowing();
    } else {
      setTimeout(() => {
        let index = 0;
        this.buildStepShowing(index);
      }, 200);
    }
  }

  buildStepShowing(index: number) {
    if (index < 10) {
      setTimeout(() => {
        this.showLandscreenContent[index] = true;
        this.buildStepShowing(++index);
      }, 50);
    }
  }

  resetStepShowing() {
    for (let i = 0; i < 10; i++) {
      this.showLandscreenContent[i] = false;
    }
  }

  expandScreen() {
    this.isScreenExpanded = !this.isScreenExpanded;
    this.onExpandScreen.emit(this.isScreenExpanded);
  }

  get isUserLoggedIn() {
    return SocialLoginComponent.isUserLoggedIn || !!this.userEmail;
  }

  get userId() {
    return SocialLoginComponent.userId || this.userEmail;
  }

  get userSocialName() {
    return SocialLoginComponent.isUserLoggedIn
      ? SocialLoginComponent.socialName
      : this.userEmail
        ? 'email'
        : null;
  }

  emailKeypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.gotoChatWithEmail();
    }
  }

  gotoChatWithEmail() {
    const email = this.form.get('email').value;
    console.log('email', email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      this.emailErrorMsg = `Please enter a valid email address.`;
      // this._snackBar.open('Please enter a correct email address.', 'Close', {
      //   horizontalPosition: 'center',
      //   verticalPosition: 'bottom',
      //   panelClass: 'ig-error-snackbar',
      // });
      return;
    }

    this.emailErrorMsg = '';
    this.isShowLogin = false;
    this.isAppendLoginToChatArea = false;
    this.isAnonmous = false;

    this.userEmail = email;
    this._localStorage.setItem('travelbuddy_user_email', email);
    this.send(true);
  }

  async gotoChat(
    msg: string,
    isTryAgain = false,
    process?: (data: IAIResponse) => IAIResponse
  ) {
    if (!msg && !isTryAgain) {
      return;
    }

    this.isShowLandscreen = false;
    this.addScrollingEvent();

    this.customProcessor = process;
    this.msg = msg?.replace('{{target}}', this.target);

    const isAnonmous = this._localStorage.getItem(
      'travelbuddy_is_anonmous_use'
    );
    this.isAnonmous = !!isAnonmous;
    console.log('isAnonmous', this.isAnonmous);

    this.userEmail =
      this._localStorage.getItem('travelbuddy_user_email') || undefined;
    console.log('userEmail', this.userEmail);

    if (!this.isUserLoggedIn && !this.isAnonmous) {
      this.isShowLogin = true;
      this.scrollToTop();

      // has chat history, then add the login box at the bottom
      if (this.msgHistory?.length > 0) {
        this.isAppendLoginToChatArea = true;
        this.isAtbottom = false;
        setTimeout(() => {
          this.scrollToBottom();
          this.checkHasScrollbar();
          // this.disableScroll();
          // otherwise google login might not be shown the second time you see the login view
          this.socialLogin.googleLoginComponent.initializeGoogle();
        }, 300);
      }

      return;
    }

    this.userProfilePicture = SocialLoginComponent.profilePicture;
    this.isShowLogin = false;
    await this.send(false);
  }

  async send(isAfterLogin: boolean) {
    if (this.isWaitingForAIResponse) return;

    // question = question from suggestions, this.msg = user input question, this.msgHistory.last() = last user question, for retrying
    this.msg =
      this.msg ||
      this.msgHistory?.filter((x) => x.type === 'USER')?.last()?.msg;
    if (!this.msg) return;

    const history = this.msgHistory
      ?.filter((x) => x.type !== 'AI_ERROR')
      // .slice(1)
      .map((item) => {
        const type = item.type;
        return {
          msg: item.msg,
          type,
        };
      });

    this.isAskingAfterLogin = isAfterLogin;

    this.askAI(isAfterLogin);

    this.createMsg({
      msg: this.msg,
      type: 'USER',
    } as ITravelAssistantMessage);

    this.createMsg({
      msg: '',
      type: 'AI_THINKING',
      suggestions: [],
      questions: [],
    } as ITravelAssistantMessage);

    this.msg = '';
  }

  keypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      // this.gotoChat();
      this.onSendClick.emit(this.msg);
    }
  }

  scrollToTop() {
    setTimeout(() => {
      this.container &&
      this.container.nativeElement.scrollTo({
        top: 0,
      });
      this.isInNormalChat &&
      this.chatContainer &&
      this.chatContainer.nativeElement.scrollTo({
        top: 0,
      });
      this.cdRef.detectChanges();
    }, 10);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.container &&
      this.container.nativeElement.scrollTo({
        top: this.container.nativeElement.scrollHeight,
      });
      this.isInNormalChat &&
      this.chatContainer &&
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
      });
      this.cdRef.detectChanges();
    }, 100);
  }

  addScrollingEvent(): void {
    if (this.isInNormalChat) {
      setTimeout(() => {
        fromEvent(this.chatContainer.nativeElement, 'scroll')
          .pipe(
            debounceTime(10),
            tap((e) => {
              const {scrollTop, scrollHeight, clientHeight} =
                this.chatContainer.nativeElement;
              // calculate if is scrolled to bottom using scrollTop, scrollHeight, clientHeight
              // if user logout with social accounts,  when scrolling to bottom, show login box
              if (scrollHeight - scrollTop < clientHeight + 60) {
                this.isAtbottom = true;
                this.cdRef.detectChanges();
              } else {
                this.isAtbottom = false;
                this.cdRef.detectChanges();
              }
            })
          )
          .subscribe();
      });
    }
  }

  disableScroll() {
    setTimeout(() => {
      this.isInNormalChat &&
      this.chatContainer &&
      this.chatContainer.nativeElement.classList.add('overflow-y-hidden');
      this.cdRef.detectChanges();
    }, 1000);
  }

  enableScroll() {
    setTimeout(() => {
      this.isInNormalChat &&
      this.chatContainer &&
      this.chatContainer.nativeElement.classList.remove('overflow-y-hidden');
      // to hide the login box and update view
      this.cdRef.detectChanges();
    }, 100);
  }

  createMsg(msg: ITravelAssistantMessage): void {
    if (isNullOrUndefined(this.msgHistory)) {
      this.msgHistory = [];
    }

    if (msg.type === 'AI_THINKING') {
      this.msgHistory.push({
        msg: '',
        type: msg.type,
        name: this.travelBuddySettings.ui.avatar.name,
        questions: [],
        suggestions: [],
      });
      this.scrollToBottom();
      return;
    }

    // a non-ai-thinking message, remove previous ai-thinkings
    if (this.msgHistory.last()?.type === 'AI_THINKING') {
      this.msgHistory = this.msgHistory.slice(0, this.msgHistory.length - 1);
    }

    this.msgHistory.push({
      msg: msg.msg,
      type: msg.type,
      name:
        msg.type === 'USER' ? 'Me' : this.travelBuddySettings.ui.avatar.name,
      model: msg.model,
      questions: msg.questions,
      suggestions: msg.suggestions,
      timeConsumed: msg.timeConsumed,
      errorMsg: this.errorMsgs.getRandom(),
    });

    this.scrollToBottom();
    this.checkHasScrollbar();

    this.onMsgCreated.emit({
      lastMsg: msg,
    });
  }

  async askAI(isAfterLogin: boolean) {
    this.isWaitingForAIResponse = true;

    this._visitorService
      .travelBuddyConversation({
        question: this.msg,
        hubName: this.hubName,
        assistantId: this.assistantId,
        threadId: this.threadId,
        userId: this.userId,
        userImg: SocialLoginComponent.profilePicture,
        userEmail: SocialLoginComponent.email || this.userEmail,
        socialName: this.userSocialName,
        userName: SocialLoginComponent.userName,
        isAllowTrackingChat: isAfterLogin
          ? this.isAllowTrackingChat
          : undefined, // for after login, it need to remember if allow tracking chat. Otherwise, for normal chat, set this to undefined
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of({success: false});
        })
      )
      .subscribe((response) => {
        this.isWaitingForAIResponse = false;
        console.log('----------------------------------------', response);
        if (response.answer) {
          let processed = this.processMsg(response);
          this.customProcessor && (processed = this.customProcessor(processed));
          this.assistantId = response.assistantId || this.assistantId;
          this.threadId = response.threadId || this.threadId;

          this.createMsg({
            msg: processed.answer,
            type: 'AI',
            model: response.model,
            suggestions: [],
            questions: ['Please tell more'],
            timeConsumed: response.timeConsumed,
          } as ITravelAssistantMessage);
        } else {
          this.createMsg({
            type: 'AI_ERROR',
          } as ITravelAssistantMessage);
        }
      });
  }

  createGPTMsg(response) {
    const data = JSON.parse(response.data);
    this.createMsg({
      msg: data.answer,
      type: 'AI',
      model: response.model,
      questions: ['Please tell more', ...data.questions],
    } as ITravelAssistantMessage);
  }

  createGoogleBardMsg(response) {
    let text = response.data;
    const pattern = /### (.+?)\n/g;

    const matches = text.match(pattern);
    if (matches && matches.length) {
      for (const match of matches) {
        console.log(match);
        text = text.replace(match, match + '</h3>\n');
      }
    }

    let processed = text.replaceAll('/【.*?】/', '').replaceAll('###', '<h3>');
    // .replaceAll('\n', '')
    // // .replaceAll('\n', '<br>')
    // // .replaceAll('*', '<br>-')
    // .replaceAll('&quot;', '')
    // .replaceAll('json\n', '')
    // .replaceAll('"answer": {', '"answer":')
    // .replaceAll('"text":', '')
    // .replaceAll('```{', '{')
    // .replaceAll('}```', '}')
    // .replaceAll('```json{', '{');

    console.log('processed', processed);

    try {
      const data = JSON.parse(processed);
      processed = window['marked'].parse(data.answer);
      // processed = processed.replaceAll('* ', '<br>* ');
      processed = processed.replaceAll('<a href=', '<a target="_blank" href=');
      console.log('processed0', processed);

      this.createMsg({
        msg: processed, //window['marked'].parse(response.all),
        type: 'AI',
        model: response.model,
        suggestions: data.nearbyPlaces,
        questions: ['Please tell more', ...data.questions],
      } as ITravelAssistantMessage);
    } catch (e) {
      console.error(e);

      processed = window['marked'].parse(processed);
      // processed = processed.replaceAll('* ', '<br><br>');
      processed = processed.replaceAll('<a href=', '<a target="_blank" href=');

      console.log('processed1', processed);
      const names = processed.match(/<strong>(.*?)<\/strong>/g);
      console.log('names', names);

      if (names) {
        const contents = names.map((match) =>
          match.replace(/<\/?strong>/g, '')
        );
        console.log('contents', contents);

        for (let i = 0; i < names.length; i++) {
          processed = processed.replace(
            names[i],
            `<a style="font-weight: bold" target="_blank" href="https://baidu.com">${contents[i]}</a>`
          );
        }
      }

      this.createMsg({
        msg: processed,
        type: 'AI',
        model: response.model,
        suggestions: [],
        questions: ['Please tell more'],
      } as ITravelAssistantMessage);
    }
  }

  processMsg(response: IAIResponse): IAIResponse {
    const pattern = /### (.+?)\n/g;

    const matches = response.answer.match(pattern);
    if (matches && matches.length) {
      for (const match of matches) {
        console.log(match);
        response.answer = response.answer.replace(match, match + '</h3>\n');
      }
    }

    response.answer = response.answer
      .replace(/【.*?】/g, '')
      // .replaceAll('\n', '')
      .replaceAll('\n', '<br>')
      // .replaceAll('**', '<br>')
      .replaceAll('###', '<h3>')
      .replaceAll(' in the documents.', '.')
      .replaceAll(' in the provided documentation.', '.')
      .replaceAll(' in the documentation.', '.')
      .replaceAll(' in the provided documents.', '.');

    try {
      response.answer = JSON.parse(response.answer);
      response.answer = window['marked']
        .parse(response.answer)
        .replace(/<\/?p>/g, '');
    } catch (ex) {
      console.warn('parse error', ex.message);
      console.log(`window['marked']`, window['marked']);
      console.log('response.answer: ', response.answer);

      if (window['marked']) {
        response.answer = window['marked'].parse(response.answer);
      } else {
        // for some version of iOS, the marked js cannot work, we parse the markdown here
        response.answer = response.answer.replaceAll('**', '*');
        const matches = response.answer.matchAll(/\*([^*]+)\*/g);

        for (const match of matches) {
          console.log('match[1]', match[1]);
          response.answer = response.answer.replaceAll(
            `*${match[1]}*`,
            `<em>${match[1]}</em>`
          );
        }

        console.log('replaced, ', response.answer);
      }
    }

    response.answer = response.answer.replaceAll(
      '<a href=',
      '<a target="_blank" href='
    );

    return response;
  }

  createErrorMsg(response) {
    this.createMsg({
      msg: response.model + ` didn't respond correctly`,
      type: 'AI',
      model: response.model,
    } as ITravelAssistantMessage);
  }

  removeLastOccurrence(str, substring) {
    const lastIndex = str.lastIndexOf(substring);

    if (lastIndex !== -1) {
      return str.slice(0, lastIndex) + str.slice(lastIndex + substring.length);
    }

    return str;
  }

  anonymousUse() {
    this.isShowLogin = false;
    this.isAppendLoginToChatArea = false;
    this.isAnonmous = true;
    this._localStorage.setItem('travelbuddy_is_anonmous_use', true);
    console.log('anonymous use -----------');
    this.send(true);
  }

  loginStatusChanged(status: boolean) {
    console.log('login status', status);
    this.userProfilePicture = status
      ? SocialLoginComponent.profilePicture
      : undefined;
    // this.isShowLogin = !SocialLoginComponent.isUserLoggedIn;

    this.emailErrorMsg = '';

    if (status && SocialLoginComponent.isUserLoggedIn) {
      this.isShowLogin = false;
      this.isAppendLoginToChatArea = false;
      this.send(true);
    }
  }

  logout() {
    try {
      this.socialLogin.logout();
      this.userProfilePicture = undefined;
      this.userEmail = undefined;
      this._localStorage.removeItem('travelbuddy_user_email');
      this._localStorage.removeItem('travelbuddy_is_anonmous_use');
      this.threadId = undefined;
      this.assistantId = undefined;
      this.cdRef.detectChanges();
    } catch (error) {
      console.warn(error);
    }
  }

  backToLandScreen() {
    this.isShowLandscreen = true;
    this.hasChatViewShowed = true;
  }

  isImg(url: string) {
    if (!url) return false;
    return url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null;
  }
}

import { createAction, props } from '@ngrx/store';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import { PlaceType } from 'src/app/modules/shared/models/place.type';

export const setQueryCommand = createAction(
  '[PlaceQueryCommand] Set place query command',
  props<CalendarQueryCommand>()
);

export const setFavoritePlaceBtnId = createAction(
  '[PlaceQueryCommand] Set fav event btn id',
  props<{favBtnId: string}>()
);

export const setCumtomDefaultCategoryList = createAction(
  '[PlaceQueryCommand] Set custom default category list',
  props<{
    categoryList: string[]; // for querying custom place categories from server side
    categoryName?: string;
    categoryNode?: PlaceType; // for displying specified (from URL) custom place category dropdown
  }>()
);

export const setDefaultCategory = createAction(
  '[PlaceQueryCommand] Set ImGoing default category list',
  props<{category: string; categoryName?: string}>()
);

export const setSearchText = createAction(
  '[PlaceQueryCommand] Set search text',
  props<{search: string}>()
);

export const setIsChoice = createAction(
  '[PlaceQueryCommand] Set is editor choice',
  props<{choice: boolean | null}>()
);

export const setLocation = createAction(
  '[PlaceQueryCommand] Set location filter',
  props<{
    // feedId: string
    location: string;
  }>()
);
export const setLocationGroups = createAction(
  '[PlaceQueryCommand] Set location group filter',
  props<{
    locations: string[];

  }>()
);

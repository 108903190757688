import { PlaceType } from './place.type';

export class CalendarActionCommand {
  public queryCommand = new CalendarQueryCommand();
  public isEventCalendar = true;
  public isMapView = false;
}

export class CalendarQueryCommand {
  public page?: number = 0;
  public limit?: number = 16;
  public from?: Date;
  public to?: Date;
  public category? = 'All';
  public categoryList?: string[]; // for custom default place categories
  public categoryNode?: PlaceType; // for custom default place categories
  public search?: string;
  public choice?: boolean;
  public favoriteEventBtnId?: string;
  public type?: string; // custom favorite place id
  public source? = 'google';
  public isForMapView?: boolean;
  public isForUpcoming?: boolean;
  public fromIndex?: number;
  public location?: string; // for location filter
  public locations?: string[]; // for location filter groups
  // public id?: string;
}

<ig-im-message-box #immessager *ngIf="travelBuddySettings"
                   [showLandscreenContent]="showLandscreenContent" [aiModel]="aiModel" (onSendClick)="gotoChat($event)"
                   (onTryAgain)="tryAgain()" (onMsgCreated)="onMsgCreated($event)"
                   (onExpandScreen)="expandScreen($event)" [left]="left"
                   [right]="right" [top]="top" [bottom]="bottom" [travelBuddySettings]="travelBuddySettings">

  <ng-container landscreen>

    <div class="mt-14 flex justify-left pl-6 gap-4 mobile:!justify-center mobile:!pl-0 mobile:pr-8">
      <ig-image [src]="travelBuddySettings.ui.avatar.profile"
                class="w-24 mobile:!w-20 mobile:max-w-[80px] mobile:max-h-[100px]"></ig-image>
      <div [style.color]="travelBuddySettings.ui.color.text" class="flex flex-col justify-end">
        <p class="text-2xl font-bold">Hi there, I'm your</p>
        <p class="text-2xl font-bold"> <span
          [style.color]="travelBuddySettings.ui.color.name">{{ travelBuddySettings.ui.avatar.name }}</span>
        </p>
      </div>
    </div>

    <!-- gap-5 here is not working on ios, so use mt-5 for each child -->
    <div *ngIf="showLandscreenContent[0]" class="flex flex-col mt-4">
      <div class="animate-bottom-up text-white px-6">
        <div [style.color]="travelBuddySettings.ui.color.text">
          <!-- <p class="font-semibold text-base">Chat with me</p> -->
          <p class="text-sm mt-1">{{ intro }}</p>
        </div>
      </div>

      <div *ngIf="showLandscreenContent[1] && question1" class="im-landscreen-box animate-bottom-up"
           (click)="gotoChat(question1)">
        <div class="">
          <p class="font-semibold text-sm">You can ask me</p>
          <p class="text-sm mt-1">{{ question1 }}</p>
        </div>
        <mat-icon class="min-w-[24px] text-right">chevron_right</mat-icon>
      </div>

      <div *ngIf="showLandscreenContent[2] && question2" class="im-landscreen-box animate-bottom-up"
           (click)="gotoChat(question2)">
        <div class="">
          <p class="font-semibold text-sm">Or, ask me</p>
          <p class="text-sm mt-1">{{ question2 }}</p>
        </div>
        <mat-icon class="min-w-[24px] text-right">chevron_right</mat-icon>
      </div>

      <div *ngIf="showLandscreenContent[3]"
           class="h-12 mx-5 mt-5 flex justify-between items-center relative rounded-lg animate-bottom-up outline outline-1 outline-[#d3d3d352]">
        <input type="text" [(ngModel)]="msg" (keypress)="keypress($event)"
               placeholder="Or, ask me anything about traveling"
               class="h-full w-full bg-[#ffffffd4] text-base shadow-sm flex items-center px-5 rounded-lg outline-none pr-[58px]">
        <mat-icon class="absolute right-[10px] top-4 text-cyan-500 cursor-pointer"
                  (click)="gotoChat(msg)">send
        </mat-icon>
      </div>

      <div *ngIf="showLandscreenContent[4]"
           class="text-xs text-center text-white animate-bottom-up flex flex-col !gap-1 !py-3 !px-0 !cursor-default">
        <p>The {{ travelBuddySettings.ui.avatar.name }} is powered by OpenAI & ChatGPT.</p>
        <p>ChatGPT can make mistakes. Check important info.</p>
      </div>

      <div *ngIf="showLandscreenContent[5] && hasIntroduction"
           class="im-landscreen-box animate-bottom-up flex-col !items-start !mt-0">
        <p class="font-semibold text-base text-gray-500">{{ travelBuddySettings.ui.introduction.title }}</p>
        <img *ngIf="travelBuddySettings.ui.introduction.imageUrl" class="rounded-xl mt-3"
             [src]="travelBuddySettings.ui.introduction.imageUrl" alt="">
        <div class="text-sm mt-3">
          {{ travelBuddySettings.ui.introduction.content }}
        </div>
        <!-- <img src="https://iti-images.s3.amazonaws.com/places/iti-user-upload/49dd0bb1-f443-90d0-bf76-38331d24829e.webp"
          alt=""> -->
      </div>


    </div>
  </ng-container>

  <!-- <ng-container addition>
    <div><button (click)="hideImg(null)">GO</button></div>
  </ng-container> -->

  <ng-container adding>
    <div *ngIf="isShowMap" class="h-[250px]">
      <ig-google-map #map class="ig-ai-map w-full h-full" [markers]="mapMarkers" [center]="center"
                     [useDefaultMarkerClickStyle]="false" (onMarkerClick)="onMarkerClick($event)"></ig-google-map>
      <div *ngIf="isScreenExpanded && mapMarkers.length>1" class="absolute bottom-4 w-full flex justify-center gap-3">
        <button *ngIf="!directionType" (click)="drawDirectionRoute()"
                class="bg-blue-400 cursor-pointer py-1.5 px-2 rounded text-white">Draw
          Route
        </button>
        <button *ngIf="directionType==='normal'" (click)="optimizeDirectionRouteByDistance()"
                class="bg-blue-400 cursor-pointer py-1.5 px-2 rounded text-white">
          Optimize Route
        </button>
        <button *ngIf="directionType" (click)="getDirections()"
                class="bg-blue-400 cursor-pointer py-1.5 px-2 rounded text-white">Get
          Directions
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container addition>

    <div *ngIf="msgHistory.last()?.timeConsumed"
         class="flex gap-4 justify-end items-center text-xs relative -top-3 px-6 ">
      <div>Time: {{ msgHistory.last()?.timeConsumed }}s</div>
      <div *ngIf="hasImg">
        <mat-slide-toggle [checked]="true" [color]="'primary'" (change)="hideImg()">
          Images
        </mat-slide-toggle>
      </div>
      <div *ngIf="mapMarkers.length">
        <mat-slide-toggle [checked]="isShowMap" [color]="'primary'" (change)="isShowMap=!isShowMap">
          Map
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>


</ig-im-message-box>

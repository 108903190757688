<ig-accordion *ngIf="settings" #accordion [mode]="mode" [title]="title" class="fav-event-filter"
  (onIconClicked)="clear()">
  <ng-container expand *ngIf="mode==='expand'">
    <ng-container *ngTemplateOutlet="favCustomBtns">
    </ng-container>
  </ng-container>

  <ng-container dropdown *ngIf="mode==='dropdown'">
    <ng-container *ngTemplateOutlet="favCustomBtns">
    </ng-container>
  </ng-container>

  <ng-template #favCustomBtns>
    <button mat-menu-item (click)="gotoCategory()">ALL</button>
    <button *ngIf="!settings.customSettings.isHideEditorChoice" mat-menu-item
      (click)="gotoEditorChoice(settings.customSettings.customEditorChoice.editorChoiceForPlace)"
      class="!flex flex-row-reverse !justify-end items-center choice-btn"
      [class.btn-selected]="title===settings.customSettings.customEditorChoice.editorChoiceForPlace">
      {{settings.customSettings.customEditorChoice.editorChoiceForPlace}}
      <mat-icon class="ml-3 !text-red-400">favorite</mat-icon>
    </button>
    <button mat-menu-item *ngFor="let btn of settings.customSettings.customPlaces" (click)="gotoCategory(btn)"
      [class.btn-selected]="title===btn.btnText">
      {{btn.btnText}}
    </button>
  </ng-template>
</ig-accordion>
import { FieldComponent } from 'src/app/common/components/field/field.component';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { GoogleMapService } from 'src/app/modules/shared/services/google.map.service';

@Component({
  selector: 'ig-address-input',
  templateUrl: './address.input.component.html',
  styleUrls: ['./address.input.component.scss'],
})
export class AddressInputComponent implements AfterViewInit {
  googleLoaded$: Observable<boolean>;

  @Input() label: string = 'Location / Address * (or check Virtual Events)';
  @Input() hint: string =
    'Start typing the physical address and select from a verified Google Maps address below';
  @Input() control: FormControl;

  @ViewChild('inputElement', { static: true }) inputElement: FieldComponent;

  constructor(public googleMapService: GoogleMapService) {
    this.googleLoaded$ = this.googleMapService.loadGoogleMapApi();
  }

  ngAfterViewInit() {
    this.googleLoaded$.subscribe((isLoaded: boolean) => {
      if (!isLoaded) return;

      const autoComplete = new google.maps.places.Autocomplete(
        this.inputElement.input.nativeElement
      );
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();

        if (place.geometry) {
          let stateAbbr = '',
            cityName = '',
            country = '';
          if (place.address_components) {
            place.address_components.forEach((x) => {
              if (x.types.includes('administrative_area_level_1')) {
                stateAbbr = x.short_name;
              }
              if (x.types.includes('locality')) {
                cityName = x.long_name;
              }
              if (x.types.includes('country')) {
                country = x.long_name;
              }
            });
          }
          this.control.setValue({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: place.formatted_address,
            placeId: place.place_id,
            cityName,
            stateAbbr,
            country,
          });
          this.inputElement.input.nativeElement.value = place.formatted_address;
        } else {
          this.control.setValue({
            address: place.name,
          });
          this.inputElement.input.nativeElement.value = place.name;
        }
      });
    });
  }

  clear() {
    this.control.setValue(null);
    this.inputElement.input.nativeElement.value = '';
  }
}

import { createAction, props } from '@ngrx/store';
import { IFrontendSettings } from './states';

export const getFrontendSettings = createAction(
  '[FrontendSettings] Get FrontendSettings',
  props<{ hubName: string }>()
);
export const getFrontendSettingsSuccess = createAction(
  '[FrontendSettings] Get FrontendSettings Success',
  props<{ frontendSettings: IFrontendSettings }>()
);
export const getFrontendSettingsFailure = createAction(
  '[FrontendSettings] Get FrontendSettings Failure',
  props<{ error: string }>()
);

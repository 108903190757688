<div class="ig-share-wrap" [class.ig-share-wrap-white]="appearance==='white'">
  <div first *ngIf="showTip">Share<span extra> this {{type}}</span></div>
  <div last class="flex">
    <a tabindex="0" [class.detail-share-fb]="appearance==='normal'" [class.detail-share-fb-white]="appearance==='white'"
      [class.share-fb-rounded]="appearance==='rounded'" (click)="shareToFacebook()"
      (keypress)="onKeyPress($event, 'facebook')" matTooltip="Share to facebook" role="button"
      aria-label="share to facebook"></a>
    <a tabindex="1" [class.detail-share-tweet]="appearance==='normal'"
      [class.detail-share-tweet-white]="appearance==='white'" [class.share-tweet-rounded]="appearance==='rounded'"
      (click)="tweetEvent()" (keypress)="onKeyPress($event, 'tweet')" matTooltip="Share to X" role="button"
      aria-label="share to X"></a>
    <a tabindex="2" [class.detail-share-email]="appearance==='normal'"
      [class.detail-share-email-white]="appearance==='white'" [class.share-email-rounded]="appearance==='rounded'"
      (click)="mailEvent()" (keypress)="onKeyPress($event, 'email')" matTooltip="Share by email" role="button"
      aria-label="share by email"></a>
    <a *ngIf="appearance==='rounded'" tabindex="3" (click)="getLink()" matTooltip="Get link" role="button"
      class="relative -top-[1px]">
      <ig-image src="https://iti-images.s3.amazonaws.com/imgs/link.png"></ig-image>
    </a>
  </div>
</div>
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'ig-search-field',
  templateUrl: './search.field.component.html',
  styleUrls: ['./search.field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFieldComponent),
      multi: true,
    },
  ],
})
export class SearchFieldComponent implements ControlValueAccessor {
  @Input() outline: 'outline' | 'fill' = 'fill';
  @Input() label: string = 'search';
  @Input() icon: string = '';
  @Input() value: string = '';
  @Input() formControl: FormControl = new FormControl();

  public clear() {
    this.formControl.setValue('');
  }

  onChange = (value: string) => {};
  onTouch = () => {};

  writeValue(value: string): void {
    this.value = value ? value : '';
  }
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
}

import { guid } from 'src/app/common/utils/guid';
import { isNumber } from 'src/app/common/utils/object.extensions';

export class PlaceType {
  _id: string;
  title: string;
  icon?: string;
  subCategories: PlaceType[];
  categoryList: string[];
  isEditing: boolean;
  id?: string;
  level: number | undefined;

  constructor(title?: string, level?: number) {
    this.title = title;
    this.icon = null;
    this.subCategories = [];
    this.categoryList = [];
    this.isEditing = false;
    this.id = guid();
    this.level = isNumber(level) ? level : undefined;
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ig-loading-dot',
  templateUrl: './loading.dot.component.html',
  styleUrls: ['./loading.dot.component.scss'],
})
export class LoadingDotComponent implements OnInit {
  @Input() loadingTips = [''];

  tip: string = '';

  ngOnInit(): void {
    this.tip = this.loadingTips.getRandom();

    if (this.loadingTips.length > 1) {
      setInterval(() => {
        this.selectTip();
      }, 5000);
    }
  }

  selectTip() {
    let selected = this.loadingTips.getRandom();
    while(selected === this.tip) {
      selected = this.loadingTips.getRandom();
    }
    this.tip = selected;
  }
}

import { ActivatedRoute } from '@angular/router';
import { VisitorPlaceService } from './../../../event-calendar/services/visitor.place.service';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'src/app/common/components/base.component';
import { IAppState } from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { Store, select } from '@ngrx/store';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import { CustomPlaceLocationFilterGroups, LocationFeed } from 'src/app/modules/shared/models/visitor.custom.settings';
import { AccordionComponent } from 'src/app/common/components/accordion/accordion.component';
import * as PlaceQueryCommandActions from 'src/app/modules/place-calendar/ngrx.stores/place.query.command/actions';
import { Observable, takeUntil } from 'rxjs';
import { placeQueryCommandSelector } from '../../ngrx.stores/place.query.command/selectors';
import { setLocationGroups } from 'src/app/modules/place-calendar/ngrx.stores/place.query.command/actions';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'ig-place-location-filter',
  templateUrl: './place.location.filter.component.html',
  styleUrls: ['./place.location.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ig-place-location-filter',
  },
})
export class PlaceLocationFilterComponent extends BaseComponent {
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Input() menuClass: string = '';
  @ViewChild('accordion', {static: true}) accordion: AccordionComponent;

  isMenuOpened = false;

  initialTitle: string = 'FILTER BY LOCATION';
  displayTitle: string = 'FILTER BY LOCATION';
  isItemSelected = this.initialTitle !== this.displayTitle;

  @Input() set title(title: string) {
    this.displayTitle = title;
    if (!this.initialTitle) {
      this.initialTitle = this.displayTitle;
    }
    this.isItemSelected = this.initialTitle !== this.displayTitle;
  }

  locationFeeds: LocationFeed[] = [];
  locationFilter$: Observable<{locality: string}[] | null>;
  locationFilterGroups: CustomPlaceLocationFilterGroups;

  constructor(
    public activatedRoute: ActivatedRoute,
    public placeService: VisitorPlaceService,
    protected _store: Store<IAppState>
  ) {
    super({activatedRoute});

    this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
      settings && (this.locationFilterGroups = settings.customSettings.customPlaceLocationFilterGroups);
    });

    // this.locationFilter$ = this.placeService.getLocationFilter(this.hubName);

    // set title and selected outline
    this._store
      .pipe(takeUntil(this.isDestroyed), select(placeQueryCommandSelector))
      .subscribe((cmd) => {
        if (cmd?.locations) {
          console.log('cmd.locations', cmd.locations);
          const group = this.frontendSettings.customSettings.customPlaceLocationFilterGroups.placeGroups.find(x => x.cities === cmd.locations);
          group && (this.title = group.title);
        }
      });
  }

  changeFilter(location?: {locality: string}) {
    this._store.dispatch(
      PlaceQueryCommandActions.setLocation({
        location: location?.locality,
      })
    );
    this.title = location?.locality || 'FILTER BY LOCATION';
  }

  selectGroup(group: {id: string, title: string, cities: string[]}) {
    this._store.dispatch(
      PlaceQueryCommandActions.setLocationGroups({
        locations: group.cities,
      })
    );
    this.title = group.title || 'FILTER BY LOCATION';
  }

  selectCity(city: string) {
    this._store.dispatch(
      PlaceQueryCommandActions.setLocation({
        location: city,
      })
    );
    this.title = city || 'FILTER BY LOCATION';
  }

  selectAll() {
    this._store.dispatch(
      PlaceQueryCommandActions.setLocationGroups({
        locations: [],
      })
    );
    this.title = 'FILTER BY LOCATION';
  }

  iconClick($event) {
    console.log('click', this.isItemSelected);
    if (this.isItemSelected) {
      $event.stopPropagation();
      this.selectAll();
    }
  }

  clear() {
    this.changeFilter();
  }

  menuOpened(trigger: MatMenuTrigger) {
    this.isMenuOpened = true;
    setTimeout(() => {
      const menu = document.querySelector('.ig-place-location-filter-menu');
      const entireTrigger = this.shadowRoot?.querySelector('.ig-place-location-filter');
      const rect = entireTrigger?.getBoundingClientRect();

      if (!menu || !rect) return;

      (menu as HTMLElement).style.width = rect.width + 'px';
      (menu as HTMLElement).style.maxWidth = rect.width + 'px';
    });
  }
}

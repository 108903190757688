<ig-accordion *ngIf="frontendSettings$ | async as setings" #accordion [mode]="mode" [title]="title"
  class="fav-event-filter" (onIconClicked)="clear()">
  <ng-container expand *ngIf="mode==='expand'">
    <ng-container *ngTemplateOutlet="favCustomBtns">
    </ng-container>
  </ng-container>

  <ng-container dropdown *ngIf="mode==='dropdown'">
    <ng-container *ngTemplateOutlet="favCustomBtns">
    </ng-container>
  </ng-container>

  <ng-template #favCustomBtns>
    <button mat-menu-item (click)="gotoCategory()">ALL</button>
    <button *ngIf="!setings.customSettings.isHideEditorChoice" mat-menu-item
      (click)="gotoEditorChoice(setings.customSettings.customEditorChoice.editorChoiceForEvent)"
      class="!flex flex-row-reverse !justify-end items-center choice-btn"
      [class.btn-selected]="title===setings.customSettings.customEditorChoice.editorChoiceForEvent">
      {{setings.customSettings.customEditorChoice.editorChoiceForEvent}}
      <mat-icon class="ml-3 !text-red-400">favorite</mat-icon>
    </button>
    <button mat-menu-item *ngFor="let btn of setings.customSettings.customEvents" (click)="gotoCategory(btn)"
      [class.btn-selected]="title===btn.btnText">{{btn.btnText}}</button>
  </ng-template>
</ig-accordion>
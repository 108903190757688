import { combineLatestWith, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { SubscriptionDisposable } from '../SubscriptionDisposable';
import { isInternetExplorer } from '../utils/browser.check';
import { isNullOrUndefined } from '../utils/object.extensions';
import { of } from 'rxjs';
import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import * as FrontendSettingsActions from 'src/app/modules/shared/ngrx.stores/frontend.settings/actions';

export class BaseComponent extends SubscriptionDisposable {
  public noChangeUrlWhenRedirect = false;
  public isIE = false;
  public hubName: string;
  public allRouteParams: Params;
  ngRxStore = inject(Store<IAppState>);
  frontendSettings: IFrontendSettings;

  constructor(
    public componentOptions: BaseComponentOption = new BaseComponentOption()
  ) {
    super();
    this.isIE = isInternetExplorer();

    if (componentOptions.activatedRoute) {
      let allParams = componentOptions.activatedRoute.params;
      let parent = componentOptions.activatedRoute.parent;
      let child = componentOptions.activatedRoute.firstChild;
      while (!isNullOrUndefined(child)) {
        allParams = allParams.pipe(
          combineLatestWith(child.params),
          switchMap(([a, b]) => {
            return of(Object.assign({}, a, b));
          })
        );
        child = child.firstChild;
      }

      while (!isNullOrUndefined(parent)) {
        allParams = allParams.pipe(
          combineLatestWith(parent.params),
          switchMap(([a, b]) => {
            return of(Object.assign({}, a, b));
          })
        );
        parent = parent.parent;
      }

      allParams.pipe(takeUntil(this.isDestroyed)).subscribe((params) => {
        this.allRouteParams = params;
      });

      this.hubName = this.allRouteParams['hubName'];
    }

    this.noChangeUrlWhenRedirect = this.isScriptSolution;

    this.ngRxStore
      .pipe(select(frontendSettingsSelector))
      .subscribe((settings) => {
        settings && (this.frontendSettings = settings);
      });
  }

  public getParam(paramName: string) {
    return this.componentOptions.activatedRoute.snapshot.paramMap.get(
      paramName
    );
  }

  public getScriptValue(valueName: string) {
    return (
      this.imgoingWrapper?.attributes[valueName] ||
      this.imgoingWrapper?.attributes[`data-${valueName}`]
    )?.value;
  }

  public get isScriptSolution() {
    return !!this.imgoingWrapper;
  }

  public get imgoingWrapper() {
    return (
      document.getElementById('imgoingcalendar-wrapper') ||
      document.getElementById('imgoingcalendar-wrapper-wix')
    );
  }

  public get shadowRoot() {
    return (this.imgoingWrapper || document).querySelector('app-root')
      .shadowRoot;
  }
}

export class BaseComponentOption {
  activatedRoute?: ActivatedRoute;
}

<ng-container *ngIf="mode==='dropdown'">
  <button #matMenuTrigger #trigger trigger [matMenuTriggerFor]="menu" aria-label="open" (menuOpened)="menuOpened()"
    class="flex items-center px-4 justify-between text-sm" matRipple [matRippleCentered]="true"
    [matRippleColor]="'rgba(255, 255, 255, 0.1)'" [class.accordion-selected]="isItemSelected">
    {{displayTitle}}
    <mat-icon (click)="iconClicked($event)">{{!isMenuOpened ? isItemSelected ? 'clear' : 'chevron_left' :
      'expand_more'}}</mat-icon>
  </button>

  <mat-menu #menu="matMenu" class="ig-accordion-menu {{menuClass}}" (closed)="isMenuOpened=false">
    <div [class.script-desktop-sm]="mediaQueryService.isDesktopSm$ | async"
      [class.script-desktop-lg]="mediaQueryService.isDesktopLg$ | async"
      [class.script-desktop-xl]="mediaQueryService.isDesktopXl$ | async"
      [class.script-desktop-2xl]="mediaQueryService.isDesktop2Xl$ | async">
      <ng-content select="[dropdown]"></ng-content>
    </div>

  </mat-menu>
</ng-container>

<ng-container *ngIf="mode==='expand'">
  <mat-accordion>
    <mat-expansion-panel hideToggle (opened)="isMenuOpened = true" (closed)="isMenuOpened = false"
      [class.accordion-selected]="isItemSelected">
      <mat-expansion-panel-header class="ig-expansion-header">
        <mat-panel-title>
          {{displayTitle}}
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="absolute right-[16px]" (click)="iconClicked($event)"
            matTooltip="{{isItemSelected?'clear filter':''}}">{{isItemSelected ?
            'clear' : !isMenuOpened ? 'chevron_left' :
            'expand_more'}}</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-content select="[expand]"></ng-content>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError/; // /Loading chunk [\d]+ failed/;
    const chunkFailedMessage1 = /Loading chunk/;

    console.warn('GlobalErrorHandler', error);

    // sometimes ImGoing chunks loading fails, so we need to reload the page
    // https://itidigital.atlassian.net/jira/software/c/projects/DXP/boards/13?selectedIssue=DXP-1346
    if (
      chunkFailedMessage.test(error.message) ||
      chunkFailedMessage1.test(error.message)
    ) {
      window.location.reload();
    } else {
      console.log('error.message', error.message);
      console.log(
        'chunkFailedMessage.test(error.message)',
        chunkFailedMessage.test(error.message)
      );
    }
  }
}

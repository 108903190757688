import { Component, Input } from '@angular/core';

@Component({
  selector: 'ig-no-content',
  templateUrl: './no.content.component.html',
  styleUrls: ['./no.content.component.scss'],
})
export class NoContentComponent {
  @Input() textCls: string = 'text-5xl';
  @Input() showImg: boolean = true;
}

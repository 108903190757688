import { VisitorService } from './../../services/visitor.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import * as FrontendSettingsActions from './actions';

@Injectable({
  providedIn: 'root',
})
export class FrontendSettingsEffects {
  getSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontendSettingsActions.getFrontendSettings),
      mergeMap((action) => {
        return this.visitorService.getFrontendSettings(action.hubName).pipe(
          map((frontendSettings) =>
            FrontendSettingsActions.getFrontendSettingsSuccess({
              frontendSettings,
            })
          ),
          catchError((error) =>
            of(
              FrontendSettingsActions.getFrontendSettingsFailure({
                error: error.message,
              })
            )
          )
        );
      })
    )
  );

  redirectAfterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FrontendSettingsActions.getFrontendSettingsSuccess),
        tap(() => {})
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private visitorService: VisitorService
  ) {}
}

import { environment } from './../../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, share, throwError, timeout } from 'rxjs';
import { localStorageKeys } from '../../constants/local.storage.keys';
import {
  bindFunctionsToThis,
  isNullOrUndefined,
} from '../../utils/object.extensions';
import { ApiErrorHandler } from './api.error.handler';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private headers = new HttpHeaders();
  private apiAddress = environment.apiAddress;

  constructor(
    protected http: HttpClient,
    protected apiErrorHandler: ApiErrorHandler
  ) {
    bindFunctionsToThis(this);

    let authToken;
    try {
      authToken = localStorage.getItem(localStorageKeys.authToken);
    } catch (error) {
      if (error && error.message.indexOf('localStorage') >= 0) {
        alert(
          'Your chrome (other browsers are similar) is set to block 3rd party cookie in incognito mode, you can change this under Settings > Privacy > Content settings, change the cookies\' settings to "Allow local data to be set" and refresh the page'
        );
      }
    }

    if (!!authToken) {
      this.headers = this.headers.set('Authorization', authToken);
    }
  }

  public get(
    url: string,
    paramsObj?: any,
    options = new ApiRequestOptions()
  ): Observable<any> {
    let params = ApiService.getParamsFromObject(paramsObj);
    return this.http
      .get(`${this.apiAddress}${url}`, {
        params,
        headers: this.headers,
      })
      .pipe(
        timeout(options.timeout),
        map((response) => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError.bind(this))
      );
  }

  public put(
    url: string,
    body?: any,
    options = new ApiRequestOptions()
  ): Observable<any> {
    return this.http
      .put(`${this.apiAddress}${url}`, body, {
        headers: this.headers,
      })
      .pipe(
        timeout(options.timeout),
        map((response) => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError.bind(this))
      );
  }

  public post(
    url: string,
    body?: any,
    options = new ApiRequestOptions()
  ): Observable<any> {
    return this.http
      .post(`${this.apiAddress}${url}`, body, {
        headers: this.headers,
      })
      .pipe(
        timeout(options.timeout),
        map((response) => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError.bind(this))
      );
  }

  public delete(
    url: string,
    paramsObj?: any,
    options = new ApiRequestOptions()
  ): Observable<any> {
    let params = ApiService.getParamsFromObject(paramsObj);

    return this.http
      .delete(`${this.apiAddress}${url}`, {
        params,
        headers: this.headers,
      })
      .pipe(
        timeout(options.timeout),
        map((response) => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError.bind(this))
      );
  }

  public setAuthorizationHeader(token: string) {
    let authToken = `Bearer ${token}`;

    this.headers = this.headers.set('Authorization', authToken);
    localStorage.setItem(localStorageKeys.authToken, authToken);
  }

  public clearAuthorizationHeader() {
    this.headers = this.headers.delete('Authorization');
    localStorage.removeItem(localStorageKeys.authToken);
  }

  private handleError(error: HttpErrorResponse) {
    console.log('error', error);
    console.log('this', this);

    this.apiErrorHandler.handle(error);
    return throwError(() => error);
  }

  private static parseResponse(response, options: ApiRequestOptions): any {
    if (!response) {
      return response;
    }

    return options.isJson && response.hasOwnProperty('_body')
      ? response['_body']
        ? response.json()
        : null
      : response;
  }

  private static getParamsFromObject(paramsObj: any) {
    let urlSearchParams = new HttpParams();

    if (paramsObj) {
      for (let key in paramsObj) {
        if (paramsObj.hasOwnProperty(key)) {
          let paramValue = paramsObj[key];

          if (paramValue instanceof Date) {
            paramsObj[key] = paramsObj[key].toISOString();
          }

          if (isNullOrUndefined(paramValue)) {
            delete paramsObj[key];
          } else {
            urlSearchParams = urlSearchParams.set(key, paramsObj[key]);
          }
        }
      }
    }

    return urlSearchParams;
  }
}

export class ApiRequestOptions {
  public isJson? = true;
  public timeout = 40 * 1000;
}

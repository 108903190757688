import { createAction, props } from '@ngrx/store';

export const setDateRange = createAction(
  '[EventQueryCommand] Set date range',
  props<{from: Date; to: Date}>()
);

export const setLocation = createAction(
  '[EventQueryCommand] Set location',
  props<{location: string}>()
);
export const setLocationGroups = createAction(
  '[EventQueryCommand] Set location group filter',
  props<{
    locations: string[];
  }>()
);

export const setFavoriteEventBtnId = createAction(
  '[EventQueryCommand] Set fav event btn id',
  props<{favBtnId: string}>()
);

export const setSearchText = createAction(
  '[EventQueryCommand] Set search text',
  props<{search: string}>()
);

export const setIsChoice = createAction(
  '[EventQueryCommand] Set is editor choice',
  props<{choice: boolean | null}>()
);

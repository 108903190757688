import { SafeUrl } from '@angular/platform-browser';
import { ImageService } from './../../services/image/image.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';

@Component({
  selector: 'ig-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() src: string;
  @Input() cls: string;
  @Input() alt: string = '';

  safeSrc: SafeUrl = null;
  frontendSettings$: Observable<IFrontendSettings | null>;
  logo = '';

  @Output() public onLoaded = new EventEmitter<Event>();

  constructor(
    public imageService: ImageService,
    private _store: Store<IAppState>
  ) {
    this.frontendSettings$ = this._store.pipe(select(frontendSettingsSelector));
    this.frontendSettings$.subscribe((settings) => {
      if (!settings) return;

      this.logo = settings.customSettings.logo.url;
    });
  }

  ngOnInit(): void {
    // TODO need to check whether use SafeUrl the event images in calendar seems to be cached (changing from all evenes and custom events, the images not changed)
    // this.safeSrc = this.imageService.safeImage(
    //   this.imageService.imageSourceUrl(this.src)
    // );

    this.src = this.imageService.imageSourceUrl(this.src || this.logo);
  }

  onError() {
    // this.safeSrc = this.imageService.safeImage(
    //   this.imageService.imageSourceUrl(this.logo)
    // );
    this.src = this.imageService.imageSourceUrl(this.logo);
  }

  onLoad(e: Event) {
    this.onLoaded.emit(e);
  }
}

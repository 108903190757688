<div class="ig-side-bar bg-white h-full">
  <!-- <a *ngIf="!visitorPromotionImage.isDisabled&&visitorPromotionImage.image&&visitorPromotionImage.isTop"
       class="bottom-img"
       href="{{visitorPromotionImage.link}}" target="_blank">
        <img [src]="imageService.safeImage(imageService.imageSourceUrl(visitorPromotionImage.image))" width="100%"
             alt="promotional image">
    </a> -->

  <div class="position-wrap relative">
    <p>You are now viewing</p>
    <h1>{{command.isEventCalendar ? 'EVENTS' : 'PLACES'}}</h1>

    <mat-icon class="absolute right-3 top-6 !text-[32px] !w-8 !h-8 text-gray-500 hidden mobile:block"
      (click)="close()">chevron_left</mat-icon>
  </div>

  <div class="sidebar-btn-wrap">
    <ig-search-field [value]="searchText" [label]="command.isEventCalendar ? 'SEARCH EVENTS' : 'SEARCH PLACES'"
      [formControl]="searchControl" class="block mb-[6px]"></ig-search-field>

    <ig-button (trigger)="viewChange()" icon="room">
      <span class="text-sm tracking-normal">CLICK FOR {{command.isMapView ? 'LIST' : 'MAP'}}
        VIEW</span>
    </ig-button>

    <ng-container *ngIf="command.isEventCalendar&&isEventEnabled">
      <ig-event-date-filter class="mb-4" (dateSelected)="filterEventsByDate($event)"></ig-event-date-filter>
      <ig-event-location-filter *ngIf="settings.toggle.eventLocationFilter" class="mb-4"
        mode="expand"></ig-event-location-filter>
      <ig-favorite-custom-event-filter class="mb-4" (favSelected)="close()"></ig-favorite-custom-event-filter>

      <ig-button *ngIf="featuresToggle.manualEvents" icon="input" (trigger)="addEventsManually()">
        <span class="text-sm tracking-normal xl:text-xs 2xl:text-sm" translate="no">SUBMIT YOUR EVENT</span>
      </ig-button>

    </ng-container>

    <ng-container *ngIf="!command.isEventCalendar&&isPlaceEnabled">

      <ig-custom-default-place-category-filter class="mb-3"
        [isOnlyShowNodeFromUrl]="true"></ig-custom-default-place-category-filter>
      <ig-favorite-custom-place-filter mode="expand" class="mb-4"></ig-favorite-custom-place-filter>
      <ig-place-location-filter *ngIf="settings.toggle.placeLocationFilter" class="mb-4"
        mode="expand"></ig-place-location-filter>

      <ig-button icon="input" (trigger)="addPlacesManually()">
        <span class="notranslate tracking-normal" translate="no">Submit Your Place</span>
      </ig-button>
    </ng-container>

    <ig-powered-by [style]="'iti-horizontal'" class="mt-8"></ig-powered-by>

  </div>

  <!-- <a *ngIf="!visitorPromotionImage.isDisabled&&visitorPromotionImage.image&&!visitorPromotionImage.isTop"
        class="bottom-img" href="{{visitorPromotionImage.link}}" target="_blank">

        <img [src]="imageService.safeImage(imageService.imageSourceUrl(visitorPromotionImage.image))" width="100%"
            alt="promotional">
    </a> -->

</div>
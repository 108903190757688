import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'ig-multi-date-time-picker',
  templateUrl: './multi.date.time.picker.component.html',
  styleUrls: ['./multi.date.time.picker.component.scss'],
})
export default class MultiDateTimePickerComponent {
  @Input() controls: FormArray;
  @Input() timezoneFormatter: string;

  constructor(private fb: FormBuilder) {}

  addDates() {
    this.controls.controls.push(
      this.fb.group({
        startTime: [null, [Validators.required]],
        endTime: [null, []],
      })
    );
  }
  removeDates(i: number) {
    this.controls.controls.splice(i, 1);
  }
}

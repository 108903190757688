import { Injectable, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  map,
  Observable,
  of,
  switchMap,
  Subject,
  fromEvent,
  BehaviorSubject,
} from 'rxjs';
import { combineLatestWith, debounceTime, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService implements OnInit {
  isPhone$: Observable<boolean> | undefined;
  isTablet$: Observable<boolean> | undefined;
  isMobile$: Observable<boolean> | undefined;
  isPhoneOrTablet$: Observable<boolean> | undefined;
  resized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // for script solution
  isDesktop$: Observable<boolean> | undefined;
  isDesktopSm$: Observable<boolean> | undefined;
  isDesktopLg$: Observable<boolean> | undefined;
  isDesktopXl$: Observable<boolean> | undefined;
  isDesktop2Xl$: Observable<boolean> | undefined;
  isDesktop3Xl$: Observable<boolean> | undefined;

  /**
   * https://github.com/angular/components/blob/main/src/cdk/layout/breakpoints.ts
   * @param Breakpoints
   * export const Breakpoints = {
   XSmall: '(max-width: 599.98px)',
   Small: '(min-width: 600px) and (max-width: 959.98px)',
   Medium: '(min-width: 960px) and (max-width: 1279.98px)',
   Large: '(min-width: 1280px) and (max-width: 1919.98px)',
   XLarge: '(min-width: 1920px)',

   Handset:
   '(max-width: 599.98px) and (orientation: portrait), ' +
   '(max-width: 959.98px) and (orientation: landscape)',
   Tablet:
   '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), ' +
   '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
   Web:
   '(min-width: 840px) and (orientation: portrait), ' +
   '(min-width: 1280px) and (orientation: landscape)',

   HandsetPortrait: '(max-width: 599.98px) and (orientation: portrait)',
   TabletPortrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
   WebPortrait: '(min-width: 840px) and (orientation: portrait)',

   HandsetLandscape: '(max-width: 959.98px) and (orientation: landscape)',
   TabletLandscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
   WebLandscape: '(min-width: 1280px) and (orientation: landscape)',
   };
   */

  constructor(
    public activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    const wrapper =
      document.getElementById('imgoingcalendar-wrapper') ||
      document.getElementById('imgoingcalendar-wrapper-wix');
    if (wrapper) {
      const wrapperRect = wrapper.getBoundingClientRect();
      console.log('wrapperRect', wrapperRect.width);

      this.isMobile$ = of(wrapperRect.width <= 1080);
      this.isDesktop$ = of(wrapperRect.width > 1080);
      this.isDesktopSm$ = of(
        wrapperRect.width > 1080 && wrapperRect.width <= 1280
      );
      this.isDesktopLg$ = of(
        wrapperRect.width > 1280 && wrapperRect.width <= 1390
      );
      this.isDesktopXl$ = of(
        wrapperRect.width > 1390 && wrapperRect.width <= 1536
      );
      this.isDesktop2Xl$ = of(
        wrapperRect.width > 1536 && wrapperRect.width <= 1716
      );
      this.isDesktop3Xl$ = of(wrapperRect.width > 1716);
      return;
    }

    this.isPhone$ = this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(map(({matches}) => matches));

    this.isTablet$ = this.breakpointObserver
      .observe([Breakpoints.Tablet])
      .pipe(map(({matches}) => matches));

    this.isMobile$ = this.breakpointObserver
      .observe(['(max-width: 1080px)'])
      .pipe(map(({matches}) => matches));

    this.isPhoneOrTablet$ = this.isPhone$.pipe(
      combineLatestWith(this.isTablet$),
      debounceTime(300),
      switchMap(([handset, tablet]) => of(handset || tablet))
    );

    this.isDesktopSm$ = this.breakpointObserver
      .observe(['(min-width: 1081px) and (max-width: 1280px)'])
      .pipe(map(({matches}) => matches));

    this.isDesktopLg$ = this.breakpointObserver
      .observe(['(min-width: 1281px) and (max-width: 1390px)'])
      .pipe(map(({matches}) => matches));

    this.isDesktopXl$ = this.breakpointObserver
      .observe(['(min-width: 1391px) and (max-width: 1536px)'])
      .pipe(map(({matches}) => matches));

    this.isDesktop2Xl$ = this.breakpointObserver
      .observe(['(min-width: 1537px) and (max-width: 1716px)'])
      .pipe(map(({matches}) => matches));

    this.isDesktop3Xl$ = this.breakpointObserver
      .observe(['(min-width: 1718px)'])
      .pipe(map(({matches}) => matches));

    fromEvent(window, 'resize')
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.resized.next(true);
      });
  }

  ngOnInit(): void {
  }
}

import { BehaviorSubject } from 'rxjs';
import { GoogleLoginComponent } from './google.login/google.login.component';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FacebookLoginComponent } from './facebook.login/facebook.login.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ig-social-login',
  templateUrl: './social.login.component.html',
  styleUrls: ['./social.login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialLoginComponent {
  public googleReady$ = GoogleLoginComponent.googleReady$;
  public profilePicture: string = '';
  public static loginStatus$ = new BehaviorSubject(false);

  @ViewChild('google', { static: true })
  public googleLoginComponent: GoogleLoginComponent;
  @ViewChild('facebook', { static: false })
  public facebookLoginComponent: FacebookLoginComponent;

  @Output() onLoginStatusChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() onEmailIconClick: EventEmitter<string> = new EventEmitter();

  constructor(private _fb: FormBuilder) {
    SocialLoginComponent.loginStatus$.subscribe((status) => {
      this.loginStatusChanged(status);
    });
  }

  public static get isUserLoggedIn() {
    return (
      GoogleLoginComponent.isUserLoggedIn ||
      FacebookLoginComponent.isUserLoggedIn
    );
  }

  public static get userId() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      return GoogleLoginComponent.googleUserId;
    }
    if (FacebookLoginComponent.isUserLoggedIn) {
      return FacebookLoginComponent.userFacebookId;
    }
    return '';
  }

  public static get profilePicture() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      return GoogleLoginComponent.profilePicture;
    }
    if (FacebookLoginComponent.isUserLoggedIn) {
      return FacebookLoginComponent.profilePicture;
    }
    return '';
  }

  public static get socialName() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      return 'google';
    }
    if (FacebookLoginComponent.isUserLoggedIn) {
      return 'facebook';
    }
    return '';
  }
  public static get userName() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      return GoogleLoginComponent.userName;
    }
    // if (FacebookLoginComponent.isUserLoggedIn) {
    //   return '';
    // }
    return '';
  }
  public static get email() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      return GoogleLoginComponent.email;
    }
    // if (FacebookLoginComponent.isUserLoggedIn) {
    //   return FacebookLoginComponent.profilePicture;
    // }
    return '';
  }

  public logout() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      this.googleLoginComponent.logout();
    }
    if (FacebookLoginComponent.isUserLoggedIn) {
      this.facebookLoginComponent.logout();
      // trigger the Google login button to be initialized again
      // this.googleLoginComponent.logout();
    }
  }

  // should use SocialLoginComponent.loginStatus$ to subscribe login status change notifications
  // as this
  public loginStatusChanged(status: boolean) {
    console.log('loginStatusChanged', status);
    if (!status) {
      this.profilePicture = '';
    }

    if (GoogleLoginComponent.isUserLoggedIn) {
      this.profilePicture = GoogleLoginComponent.profilePicture;
    }
    if (FacebookLoginComponent.isUserLoggedIn) {
      this.profilePicture = FacebookLoginComponent.profilePicture;
    }
    this.onLoginStatusChanged.emit(status);
  }
}

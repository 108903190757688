import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Observable,
  catchError,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public loadJsScript(
    renderer: Renderer2,
    src: string,
    isAsync = true
  ): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = isAsync;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public isScriptExist(src: string): boolean {
    const scripts = this.document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === src) {
        return true;
      }
    }
    return false;
  }

  public removeScript(src: string): void {
    const scripts = this.document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === src) {
        scripts[i].remove();
      }
    }
  }

  public loadCss(styleUrl: string, onLoad?: () => void): void {
    const linkElem = this.document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', styleUrl);
    this.document.head.appendChild(linkElem);

    onLoad && onLoad();
  }

  public isCssExist(src: string): boolean {
    const scripts = this.document.getElementsByTagName('link');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('href') === src) {
        return true;
      }
    }
    return false;
  }

  public preloadItems(items: { type: 'image' | 'video'; src: string }[]) {
    let loadedCount = 0;
    const loaded = () => {
      loadedCount++;
      if (items.length === loadedCount) {
        //all images loaded
      }
    };
    for (var i = 0; i < items.length; i++) {
      let img = new Image();
      img.onload = () => {
        loaded();
      };
      img.src = items[i].src;
    }
  }

  public preloadImages(imageUrls: string[]): Observable<boolean> {
    const observables = imageUrls.map(
      (url) =>
        new Observable<boolean>((observer) => {
          const img = new Image();
          img.onload = () => {
            console.log(`${url} loaded`);
            observer.next(true);
            observer.complete();
          };
          img.onerror = () => {
            observer.next(false);
            observer.complete();
          };
          img.src = url;
        })
    );

    return forkJoin(observables).pipe(
      // Check if all images loaded successfully
      map((results: boolean[]) => results.every((result) => result)),
      // Return a loaded symbol if all images loaded successfully
      map((allLoaded: boolean) => (allLoaded ? true : false))
    );
  }

  preloadVideo(videoUrls: string[]) {
    var req = new XMLHttpRequest();
    req.open('GET', 'video.mp4', true);
    req.responseType = 'blob';

    req.onload = function () {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        // video.src = vid;
      }
    };
    req.onerror = function () {
      // Error
    };

    req.send();
  }

  preloadVideos(videoUrls: string[]): Observable<boolean> {
    return from(videoUrls).pipe(
      // Concurrently preload each video using mergeMap
      mergeMap((url) => {
        const videoElement = document.createElement('video');
        videoElement.preload = 'auto'; // Or set a specific preload value

        // Create an observable to track the video's loading state
        return from(
          new Promise((resolve, reject) => {
            videoElement.addEventListener('loadeddata', () => {
              console.log(`${url} loaded`);
              resolve(url);
            });
            videoElement.addEventListener('error', reject);
            videoElement.src = url;
          })
        ).pipe(
          // Handle potential errors during loading
          catchError((error) => {
            console.error('Error preloading video:', url, error);
            return of(false); // Or handle the error differently
          })
        );
      }),
      // Wait for all preloading observables to complete
      map(() => true), // Emit a symbol after all videos are loaded
      // Catch any errors from the mergeMap process
      catchError((error) => {
        console.error('Error preloading videos:', error);
        return of(false); // Or handle the error differently
      })
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getRouteUrl, routeConfigs } from '../../constants/route.config';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandler {
  constructor(private _router: Router, private _snackBar: MatSnackBar) {
  }

  public handle(error: HttpErrorResponse) {
    if (error.status === 500) {
      this.showApplicationError(error);
    } else if (error.status === 401) {
      this._router.navigateByUrl(getRouteUrl(routeConfigs.auth.login));
    } else {
      this.showApplicationError(error);
    }
  }

  public showError(msg: string) {
    this._snackBar.open(msg, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'ig-error-snackbar',
    });
  }

  private showApplicationError(error: HttpErrorResponse) {
    if (['/ai-assistant'].some((x) => error.message.includes(x))) {
      // handle in ai-assistant talk box
      return;
    }

    let position: MatSnackBarVerticalPosition = 'top';
    let message = error.message;
    if (['/travel-buddy'].some((x) => error.message.includes(x))) {
      message = error.error;
      position = 'bottom';
    }

    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: position,
      panelClass: 'ig-error-snackbar',
    });
  }
}

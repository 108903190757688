export const timezoneConfig = [
  //   {"value":"America/Adak","name":"Adak"},
  //   {"value":"America/Anchorage","name":"Anchorage"},
  //   {"value":"America/Anguilla","name":"Anguilla"},
  //   {"value":"America/Antigua","name":"Antigua"},
  //   {"value":"America/Araguaina","name":"Araguaina"},
  //   {"value":"America/Argentina/Buenos_Aires","name":"Argentina - Buenos Aires"},
  //   {"value":"America/Argentina/Catamarca","name":"Argentina - Catamarca"},
  //   {"value":"America/Argentina/ComodRivadavia","name":"Argentina - ComodRivadavia"},
  //   {"value":"America/Argentina/Cordoba","name":"Argentina - Cordoba"},
  //   {"value":"America/Argentina/Jujuy","name":"Argentina - Jujuy"},
  //   {"value":"America/Argentina/La_Rioja","name":"Argentina - La Rioja"},
  //   {"value":"America/Argentina/Mendoza","name":"Argentina - Mendoza"},
  //   {"value":"America/Argentina/Rio_Gallegos","name":"Argentina - Rio Gallegos"},
  //   {"value":"America/Argentina/Salta","name":"Argentina - Salta"},
  //   {"value":"America/Argentina/San_Juan","name":"Argentina - San Juan"},
  //   {"value":"America/Argentina/San_Luis","name":"Argentina - San Luis"},
  //   {"value":"America/Argentina/Tucuman","name":"Argentina - Tucuman"},
  //   {"value":"America/Argentina/Ushuaia","name":"Argentina - Ushuaia"},
  //   {"value":"America/Aruba","name":"Aruba"},
  //   {"value":"America/Asuncion","name":"Asuncion"},
  //   {"value":"America/Atikokan","name":"Atikokan"},
  //   {"value":"America/Atka","name":"Atka"},
  //   {"value":"America/Bahia","name":"Bahia"},
  //   {"value":"America/Barbados","name":"Barbados"},
  //   {"value":"America/Belem","name":"Belem"},
  //   {"value":"America/Belize","name":"Belize"},
  //   {"value":"America/Blanc-Sablon","name":"Blanc-Sablon"},
  //   {"value":"America/Boa_Vista","name":"Boa Vista"},
  //   {"value":"America/Bogota","name":"Bogota"},
  //   {"value":"America/Boise","name":"Boise"},
  //   {"value":"America/Buenos_Aires","name":"Buenos Aires"},
  //   {"value":"America/Cambridge_Bay","name":"Cambridge Bay"},
  //   {"value":"America/Campo_Grande","name":"Campo Grande"},
  //   {"value":"America/Cancun","name":"Cancun"},
  //   {"value":"America/Caracas","name":"Caracas"},
  //   {"value":"America/Catamarca","name":"Catamarca"},
  //   {"value":"America/Cayenne","name":"Cayenne"},
  //   {"value":"America/Cayman","name":"Cayman"},
  //   {"value":"America/Chicago","name":"Chicago"},
  //   {"value":"America/Chihuahua","name":"Chihuahua"},
  //   {"value":"America/Coral_Harbour","name":"Coral Harbour"},
  //   {"value":"America/Cordoba","name":"Cordoba"},
  //   {"value":"America/Costa_Rica","name":"Costa Rica"},
  //   {"value":"America/Cuiaba","name":"Cuiaba"},
  //   {"value":"America/Curacao","name":"Curacao"},
  //   {"value":"America/Danmarkshavn","name":"Danmarkshavn"},
  //   {"value":"America/Dawson","name":"Dawson"},
  //   {"value":"America/Dawson_Creek","name":"Dawson Creek"},
  //   {"value":"America/Denver","name":"Denver"},
  //   {"value":"America/Detroit","name":"Detroit"},
  //   {"value":"America/Dominica","name":"Dominica"},
  //   {"value":"America/Edmonton","name":"Edmonton"},
  //   {"value":"America/Eirunepe","name":"Eirunepe"},
  //   {"value":"America/El_Salvador","name":"El Salvador"},
  //   {"value":"America/Ensenada","name":"Ensenada"},
  //   {"value":"America/Fortaleza","name":"Fortaleza"},
  //   {"value":"America/Fort_Wayne","name":"Fort Wayne"},
  //   {"value":"America/Glace_Bay","name":"Glace Bay"},
  //   {"value":"America/Godthab","name":"Godthab"},
  //   {"value":"America/Goose_Bay","name":"Goose Bay"},
  //   {"value":"America/Grand_Turk","name":"Grand Turk"},
  //   {"value":"America/Grenada","name":"Grenada"},
  //   {"value":"America/Guadeloupe","name":"Guadeloupe"},
  //   {"value":"America/Guatemala","name":"Guatemala"},
  //   {"value":"America/Guayaquil","name":"Guayaquil"},
  //   {"value":"America/Guyana","name":"Guyana"},
  //   {"value":"America/Halifax","name":"Halifax"},
  //   {"value":"America/Havana","name":"Havana"},
  //   {"value":"America/Hermosillo","name":"Hermosillo"},
  //   {"value":"America/Indiana/Indianapolis","name":"Indiana - Indianapolis"},
  //   {"value":"America/Indiana/Knox","name":"Indiana - Knox"},
  //   {"value":"America/Indiana/Marengo","name":"Indiana - Marengo"},
  //   {"value":"America/Indiana/Petersburg","name":"Indiana - Petersburg"},
  //   {"value":"America/Indiana/Tell_City","name":"Indiana - Tell City"},
  //   {"value":"America/Indiana/Vevay","name":"Indiana - Vevay"},
  //   {"value":"America/Indiana/Vincennes","name":"Indiana - Vincennes"},
  //   {"value":"America/Indiana/Winamac","name":"Indiana - Winamac"},
  //   {"value":"America/Indianapolis","name":"Indianapolis"},
  //   {"value":"America/Inuvik","name":"Inuvik"},
  //   {"value":"America/Iqaluit","name":"Iqaluit"},
  //   {"value":"America/Jamaica","name":"Jamaica"},
  //   {"value":"America/Jujuy","name":"Jujuy"},
  //   {"value":"America/Juneau","name":"Juneau"},
  //   {"value":"America/Kentucky/Louisville","name":"Kentucky - Louisville"},
  //   {"value":"America/Kentucky/Monticello","name":"Kentucky - Monticello"},
  //   {"value":"America/Knox_IN","name":"Knox IN"},
  //   {"value":"America/La_Paz","name":"La Paz"},
  //   {"value":"America/Lima","name":"Lima"},
  //   {"value":"America/Louisville","name":"Louisville"},
  //   {"value":"America/Maceio","name":"Maceio"},
  //   {"value":"America/Managua","name":"Managua"},
  //   {"value":"America/Manaus","name":"Manaus"},
  //   {"value":"America/Marigot","name":"Marigot"},
  //   {"value":"America/Martinique","name":"Martinique"},
  //   {"value":"America/Matamoros","name":"Matamoros"},
  //   {"value":"America/Mazatlan","name":"Mazatlan"},
  //   {"value":"America/Mendoza","name":"Mendoza"},
  //   {"value":"America/Merida","name":"Merida"},
  //   {"value":"America/Mexico_City","name":"Mexico City"},
  //   {"value":"America/Miquelon","name":"Miquelon"},
  //   {"value":"America/Moncton","name":"Moncton"},
  //   {"value":"America/Monterrey","name":"Monterrey"},
  //   {"value":"America/Montevideo","name":"Montevideo"},
  //   {"value":"America/Montreal","name":"Montreal"},
  //   {"value":"America/Montserrat","name":"Montserrat"},
  //   {"value":"America/Nassau","name":"Nassau"},
  {
    value: 'America/Los_Angeles',
    name: 'America/Los_Angeles (PDT/PST)',
    code: 'PST',
  },
  {
    value: 'America/Menominee',
    name: 'America/Menominee (CST/CDT)',
    code: 'CST',
  },
  {
    value: 'America/New_York',
    name: 'America/New_York (EST/EDT)',
    code: 'EST',
  },
  { value: 'America/Nome', name: 'America/Nome (AKST/AKDT)', code: 'AKST' },
  { value: 'America/Phoenix', name: 'America/Phoenix (MST)', code: 'MST' },
  { value: 'America/Denver', name: 'America/Denver (MST/MDT)', code: 'MST' },
  {
    value: 'Pacific/Honolulu',
    name: 'Pacific/Honolulu (HST/HDT)',
    code: 'HST',
  },
  { value: 'Europe/Madrid', name: 'Europe/Madrid (CEST/CEDT)', code: 'CEST' },
  //   {"value":"America/Nipigon","name":"Nipigon"},
  //   {"value":"America/Noronha","name":"Noronha"},
  //   {"value":"America/North_Dakota/Center","name":"North Dakota - Center"},
  //   {"value":"America/North_Dakota/New_Salem","name":"North Dakota - New Salem"},
  //   {"value":"America/Ojinaga","name":"Ojinaga"},
  //   {"value":"America/Panama","name":"Panama"},
  //   {"value":"America/Pangnirtung","name":"Pangnirtung"},
  //   {"value":"America/Paramaribo","name":"Paramaribo"},
  //   {"value":"America/Port-au-Prince","name":"Port-au-Prince"},
  //   {"value":"America/Porto_Acre","name":"Porto Acre"},
  //   {"value":"America/Port_of_Spain","name":"Port of Spain"},
  //   {"value":"America/Porto_Velho","name":"Porto Velho"},
  //   {"value":"America/Puerto_Rico","name":"Puerto Rico"},
  //   {"value":"America/Rainy_River","name":"Rainy River"},
  //   {"value":"America/Rankin_Inlet","name":"Rankin Inlet"},
  //   {"value":"America/Recife","name":"Recife"},
  //   {"value":"America/Regina","name":"Regina"},
  //   {"value":"America/Resolute","name":"Resolute"},
  //   {"value":"America/Rio_Branco","name":"Rio Branco"},
  //   {"value":"America/Rosario","name":"Rosario"},
  //   {"value":"America/Santa_Isabel","name":"Santa Isabel"},
  //   {"value":"America/Santarem","name":"Santarem"},
  //   {"value":"America/Santiago","name":"Santiago"},
  //   {"value":"America/Santo_Domingo","name":"Santo Domingo"},
  //   {"value":"America/Sao_Paulo","name":"Sao Paulo"},
  //   {"value":"America/Scoresbysund","name":"Scoresbysund"},
  //   {"value":"America/Shiprock","name":"Shiprock"},
  //   {"value":"America/St_Barthelemy","name":"St Barthelemy"},
  //   {"value":"America/St_Johns","name":"St Johns"},
  //   {"value":"America/St_Kitts","name":"St Kitts"},
  //   {"value":"America/St_Lucia","name":"St Lucia"},
  //   {"value":"America/St_Thomas","name":"St Thomas"},
  //   {"value":"America/St_Vincent","name":"St Vincent"},
  //   {"value":"America/Swift_Current","name":"Swift Current"},
  //   {"value":"America/Tegucigalpa","name":"Tegucigalpa"},
  //   {"value":"America/Thule","name":"Thule"},
  //   {"value":"America/Thunder_Bay","name":"Thunder Bay"},
  //   {"value":"America/Tijuana","name":"Tijuana"},
  //   {"value":"America/Toronto","name":"Toronto"},
  //   {"value":"America/Tortola","name":"Tortola"},
  //   {"value":"America/Vancouver","name":"Vancouver"},
  //   {"value":"America/Virgin","name":"Virgin"},
  //   {"value":"America/Whitehorse","name":"Whitehorse"},
  //   {"value":"America/Winnipeg","name":"Winnipeg"},
  //   {"value":"America/Yakutat","name":"Yakutat"},
  //   {"value":"America/Yellowknife","name":"Yellowknife"},
  // { "value": "Europe/Amsterdam", "name": "Amsterdam" },
  // { "value": "Europe/Andorra", "name": "Andorra" },
  // { "value": "Europe/Athens", "name": "Athens" },
  // { "value": "Europe/Belfast", "name": "Belfast" },
  // { "value": "Europe/Belgrade", "name": "Belgrade" },
  // { "value": "Europe/Berlin", "name": "Berlin" },
  // { "value": "Europe/Bratislava", "name": "Bratislava" },
  // { "value": "Europe/Brussels", "name": "Brussels" },
  // { "value": "Europe/Bucharest", "name": "Bucharest" },
  // { "value": "Europe/Budapest", "name": "Budapest" },
  // { "value": "Europe/Chisinau", "name": "Chisinau" },
  // { "value": "Europe/Copenhagen", "name": "Copenhagen" },
  // { "value": "Europe/Dublin", "name": "Dublin" },
  // { "value": "Europe/Gibraltar", "name": "Gibraltar" },
  // { "value": "Europe/Guernsey", "name": "Guernsey" },
  // { "value": "Europe/Helsinki", "name": "Helsinki" },
  // { "value": "Europe/Isle_of_Man", "name": "Isle of Man" },
  // { "value": "Europe/Istanbul", "name": "Istanbul" },
  // { "value": "Europe/Jersey", "name": "Jersey" },
  // { "value": "Europe/Kaliningrad", "name": "Kaliningrad" },
  // { "value": "Europe/Kiev", "name": "Kiev" },
  // { "value": "Europe/Lisbon", "name": "Lisbon" },
  // { "value": "Europe/Ljubljana", "name": "Ljubljana" },
  // { "value": "Europe/London", "name": "London" },
  // { "value": "Europe/Luxembourg", "name": "Luxembourg" },
  // { "value": "Europe/Malta", "name": "Malta" },
  // { "value": "Europe/Mariehamn", "name": "Mariehamn" },
  // { "value": "Europe/Minsk", "name": "Minsk" },
  // { "value": "Europe/Monaco", "name": "Monaco" },
  // { "value": "Europe/Moscow", "name": "Moscow" },
  // { "value": "Europe/Nicosia", "name": "Nicosia" },
  // { "value": "Europe/Oslo", "name": "Oslo" },
  // { "value": "Europe/Paris", "name": "Paris" },
  // { "value": "Europe/Podgorica", "name": "Podgorica" },
  // { "value": "Europe/Prague", "name": "Prague" },
  // { "value": "Europe/Riga", "name": "Riga" },
  // { "value": "Europe/Rome", "name": "Rome" },
  // { "value": "Europe/Samara", "name": "Samara" },
  // { "value": "Europe/San_Marino", "name": "San Marino" },
  // { "value": "Europe/Sarajevo", "name": "Sarajevo" },
  // { "value": "Europe/Simferopol", "name": "Simferopol" },
  // { "value": "Europe/Skopje", "name": "Skopje" },
  // { "value": "Europe/Sofia", "name": "Sofia" },
  // { "value": "Europe/Stockholm", "name": "Stockholm" },
  // { "value": "Europe/Tallinn", "name": "Tallinn" },
  // { "value": "Europe/Tirane", "name": "Tirane" },
  // { "value": "Europe/Tiraspol", "name": "Tiraspol" },
  // { "value": "Europe/Uzhgorod", "name": "Uzhgorod" },
  // { "value": "Europe/Vaduz", "name": "Vaduz" },
  // { "value": "Europe/Vatican", "name": "Vatican" },
  // { "value": "Europe/Vienna", "name": "Vienna" },
  // { "value": "Europe/Vilnius", "name": "Vilnius" },
  // { "value": "Europe/Volgograd", "name": "Volgograd" },
  // { "value": "Europe/Warsaw", "name": "Warsaw" },
  // { "value": "Europe/Zagreb", "name": "Zagreb" },
  // { "value": "Europe/Zaporozhye", "name": "Zaporozhye" },
  // { "value": "Europe/Zurich", "name": "Zurich" }
];

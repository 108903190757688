import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setItem(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  getItem<T>(key: string): T | string {
    const data = localStorage.getItem(key);
    if (!data) return null;
    try {
      return JSON.parse(data) as T;
    } catch (error) {
      return localStorage.getItem(key);
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewEncapsulation,
  ContentChild,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AIService } from '../ai.service';
import { ITravelAssistantMessage } from '../../../models/travel.assistant';
import { TravelBuddySettings } from '../../../models/travel.buddy.settings';

@Component({
  selector: 'ig-msg-box',
  templateUrl: './msg.box.component.html',
  styleUrls: ['./msg.box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MsgBoxComponent implements OnInit {
  defaultAvatar = `https://iti-images.s3.amazonaws.com/events/46a894bb-0ef0-270c-7118-e3bacdad1c9a.webp`;
  @Input() isAllowClick = false;

  @Input() message: ITravelAssistantMessage;
  @Input() errorMsg: string;
  @Input() isScreenExpanded: boolean = false;
  @Input() travelBuddySettings: TravelBuddySettings;
  @Input() avatar: string;
  @Input() letterAvatar: string | undefined = undefined;
  @Output() public ask = new EventEmitter<string>();
  @Output() public tryAgain = new EventEmitter<string>();
  @Output() public avatarClicked = new EventEmitter<any>();

  // @ContentChild('[adding]', { static: false }) addingContent: any;
  @ViewChild('addingContent', { static: false }) addingContent: ElementRef;
  @ViewChild('addingContentExpanded', { static: false })
  addingContentExpanded: ElementRef;

  // errorMsgs = [
  //   `Looks like you've asked a question that's above my pay grade—err. Got another question to throw my way? `,
  //   `To err is human, to forgive divine, but for your AI Travel Buddy, it's downright awkward! Got another zinger for me? `,
  //   `Wow, you've managed to stump your AI buddy. You just won a million dollars! Just kidding. Got another question to throw my way? `,
  //   `You've found a gap in my digital wisdom! Mind tossing another question my way? `,
  // ];

  constructor(private _aiService: AIService) {}

  ngOnInit(): void {
    this._aiService.aiResponseSubject$.subscribe((response) => {
      console.log(this.message);
      if (this.message.type === 'AI_STREAM') {
        this.message.msg += response;
      }
    });
  }

  askQ(question: string) {
    this.ask.emit(question);
  }
  tryAskAgain() {
    this.tryAgain.emit();
  }

  get getModel() {
    return this.message.model ? `(${this.message.model})` : '';
  }

  hasAddingContent() {
    const html = this.addingContent?.nativeElement.innerHTML;
    if (html) {
      const noContent = html.startsWith('<!-') && html.endsWith('-->');
      return !noContent;
    }
    return false;
  }
}

// export interface ITravelAssistantMessage {
//   // direction: 'left' | 'right';
//   type: 'USER' | 'AI' | 'AI_THINKING' | 'AI_ERROR' | 'AI_STREAM';
//   msg: string;
//   name?: string;
//   model?: 'google-bard' | 'chatgpt' | 'all';
//   suggestions?: string[];
//   questions?: string[];
//   errorMsg?: string;
// }

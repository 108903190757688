import { EventTimeService } from './../../../modules/event-calendar/services/event.time.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'ig-date-time-picker',
  templateUrl: './date.time.picker.component.html',
  styleUrls: ['./date.time.picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  @Input() startFormControl: FormControl;
  @Input() endFormControl: FormControl;
  @Input() startTimeFormControl: FormControl;
  @Input() endTimeFormControl: FormControl;

  @Input() timezoneFormatter: string;

  @ViewChild('start', { static: true }) startDateEle: MatFormField;
  @ViewChild('starttime', { static: true }) startTimeEle: MatFormField;
  @ViewChild('end', { static: true }) endDateEle: MatFormField;
  @ViewChild('endtime', { static: true }) endTimeEle: MatFormField;

  timeValues: {
    value: string;
    text: string;
  }[] = [];

  constructor(public timeService: EventTimeService) {}

  ngOnInit(): void {
    if (!this.timezoneFormatter) {
      console.warn(
        'No timezone formatter set for date time picker! Default America/New_York will be used!'
      );
      this.timezoneFormatter = 'America/New_York';
    }

    this.setTimeSelector();
  }

  setFormControlValue(control: AbstractControl, timeSelect: MatSelect) {
    let date = new Date(control.value || new Date());

    const dateStr = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${timeSelect.value || '00:00'}`;

    const iso = this.timeService.toISOTime(dateStr, this.timezoneFormatter);
    control.setValue(iso);
  }

  today() {
    return new Date();
  }

  onFocus() {
    this.setLabelWidth();
  }

  private setTimeSelector() {
    for (let i = 0; i < 24; i++) {
      this.timeValues.push({
        value: `${i}:00`,
        text: `${i >= 13 ? i - 12 : i}:00 ` + (i >= 12 ? 'PM' : 'AM'),
      });
      this.timeValues.push({
        value: `${i}:15`,
        text: `${i >= 13 ? i - 12 : i}:15 ` + (i >= 12 ? 'PM' : 'AM'),
      });
      this.timeValues.push({
        value: `${i}:30`,
        text: `${i >= 13 ? i - 12 : i}:30 ` + (i >= 12 ? 'PM' : 'AM'),
      });
      this.timeValues.push({
        value: `${i}:45`,
        text: `${i >= 13 ? i - 12 : i}:45 ` + (i >= 12 ? 'PM' : 'AM'),
      });
    }
  }

  private setLabelWidth() {
    // solve the outline mode label not wide enough when focusing
    setTimeout(() => {
      const notch = this.startDateEle._elementRef.nativeElement.querySelector(
        '.mdc-notched-outline__notch'
      );
      notch && (notch.style.width = 70 + 'px');

      const notchEnd = this.endDateEle._elementRef.nativeElement.querySelector(
        '.mdc-notched-outline__notch'
      );
      notchEnd && (notchEnd.style.width = 70 + 'px');

      const notchStartTime =
        this.startTimeEle._elementRef.nativeElement.querySelector(
          '.mdc-notched-outline__notch'
        );
      notchStartTime && (notchStartTime.style.width = 40 + 'px');

      const notchEndTime =
        this.endTimeEle._elementRef.nativeElement.querySelector(
          '.mdc-notched-outline__notch'
        );
      notchEndTime && (notchEndTime.style.width = 40 + 'px');
    });
  }
}

<ng-container *ngIf="!hideForClients.includes(hubName)">
  <ng-container *ngIf="style==='iti-horizontal'">
    <div class="flex items-center justify-center">
      <div class="text-xs 2xl:text-sm text-gray-500 font-semibold mr-3">Powered by</div>
      <a href="https://iti-digital.com/" target="_blank" class="relative -top-0.5"><img [src]="itiLogoUrl" alt="ITI"
          class="h-3.5 xl:h-4"></a>
    </div>
  </ng-container>
  <div *ngIf="style==='iti-vertical'">
    <div>
      <div class="text-xs 2xl:text-sm text-gray-500 font-semibold mb-1">Powered by</div>
      <a href="https://iti-digital.com/" target="_blank"><img [src]="itiLogoUrl" alt="ITI" class="h-[14px]"></a>
    </div>
  </div>
</ng-container>
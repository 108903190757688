import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export abstract class SubscriptionDisposable implements OnDestroy {
  protected _isDestroyed: boolean = false;
  protected _isDestroyedSubject = new ReplaySubject<boolean>(1);

  constructor() {
    /** rewrite the this.ngOnDestroy here is not gonna work, so in the child component which extends this class and has own ngOnDestory, must call super.ngOnDestory() */
    if (this.ngOnDestroy !== SubscriptionDisposable.prototype.ngOnDestroy) {
      let onDestroyFunc = this.ngOnDestroy.bind(this);

      this.ngOnDestroy = () => {
        onDestroyFunc();
        SubscriptionDisposable.prototype.ngOnDestroy.bind(this)();
      };
    }
  }

  public get isDestroyed(): Observable<boolean> {
    return this._isDestroyedSubject.asObservable();
  }

  public ngOnDestroy() {
    this._isDestroyed = true;
    this._isDestroyedSubject.next(true);
    this._isDestroyedSubject.complete();
  }
}

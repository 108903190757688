<!-- <ng-container *ngIf="!isLoading&&!isSuccess">
    <button mat-raised-button [class.has-icon]="icon&&!iconAtLeft" aria-label="button" matRipple
        [matRippleCentered]="true" [matRippleUnbounded]="false" [class.has-left-icon]="icon&&iconAtLeft"
        [disabled]="disabled" [color]="color" (click)="onClick($event)" [class]="cls">
        <mat-icon *ngIf="icon&&iconAtLeft">
            {{icon}}
        </mat-icon>
        <ng-content></ng-content>
        <mat-icon *ngIf="icon&&!iconAtLeft">
            {{icon}}
        </mat-icon>
    </button>

    <div *ngIf="style==='flat'" btn [class]="cls" (click)="onClick($event)" matRipple [matRippleCentered]="true"
        [matRippleUnbounded]="false"><ng-content></ng-content></div>
</ng-container> -->

<button *ngIf="!isLoading&&!isSuccess&&style==='raised'" mat-raised-button [class.has-icon]="icon&&!iconAtLeft"
    aria-label="button" matRipple [matRippleCentered]="true" [matRippleUnbounded]="false"
    [class.has-left-icon]="icon&&iconAtLeft" [disabled]="disabled" [color]="color" (click)="onClick($event)"
    [class]="cls">
    <mat-icon *ngIf="icon&&iconAtLeft">
        {{icon}}
    </mat-icon>
    <ng-content></ng-content>
    <mat-icon *ngIf="icon&&!iconAtLeft">
        {{icon}}
    </mat-icon>
</button>

<!-- <div *ngIf="!isLoading&&!isSuccess&&style==='flat'" btn [class]="cls" (click)="onClick($event)" matRipple
    [matRippleCentered]="true" [matRippleUnbounded]="false"><ng-content></ng-content></div> -->

<ig-spinner *ngIf="isLoading"></ig-spinner>

<div *ngIf="isSuccess" class="flex gap-4 items-center">
    <div class="checkmark-wrapper ml-2"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    </div>
    <p class="text-sm text-[#7ac142]">{{successText}}</p>
</div>
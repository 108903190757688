<!--<ig-accordion #accordion [mode]="mode" [title]="title" class="event-date-filter" [menuClass]="menuClass"-->
<!--  (onIconClicked)="clear()">-->
<!--  <ng-container expand>-->
<!--    <ng-container *ngTemplateOutlet="eventDateFilter">-->
<!--    </ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container dropdown>-->
<!--    <ng-container *ngTemplateOutlet="eventDateFilter">-->
<!--    </ng-container>-->
<!--  </ng-container>-->

<!--  <ng-template #eventDateFilter>-->

<!--    <button mat-menu-item (click)="changeFilter()">ALL</button>-->
<!--    <button *ngFor="let btn of locationFilter$ | async" mat-menu-item-->
<!--      (click)="changeFilter(btn)">{{btn.locality}}</button>-->
<!--  </ng-template>-->
<!--</ig-accordion>-->

<button mat-button #trigger="matMenuTrigger" [matMenuTriggerFor]="groups" (menuOpened)="menuOpened(trigger)"
        [matRippleCentered]="true" matRipple
        [matRippleColor]="'rgba(255, 255, 255, 0.1)'" [class.accordion-selected]="isItemSelected">
  <span>{{ displayTitle || 'FILTER BY LOCATION' }}</span>
  <mat-icon class="mat-icon notranslate" aria-hidden="true"
            (click)="iconClick($event)">{{ isItemSelected ? 'clear' : isMenuOpened ? 'expand_more' : 'chevron_left' }}
  </mat-icon>
</button>

<mat-menu #groups="matMenu" class="ig-place-location-filter-menu" (closed)="isMenuOpened=false">
  <button mat-menu-item (click)="selectAll()">ALL</button>

  @if (locationFilterGroups) {
    @if (!locationFilterGroups.isCityLevelFilter) {
      <button *ngFor="let group of locationFilterGroups.eventGroups" mat-menu-item
              (click)="selectGroup(group)">{{ group.title }}
      </button>
    } @else {
      <ng-container *ngFor="let group of locationFilterGroups.eventGroups">

        <button type="button" mat-menu-item #trigger="matMenuTrigger" [matMenuTriggerFor]="tabledriven"
                class="top-bar-font-size" [class.filtering]="false">
          {{ group.title || 'FILTER BY CATEGORY' }}
        </button>

        <mat-menu #tabledriven="matMenu" class="ig-nested-menu">
          <button *ngIf="group.cities.length > 1" mat-menu-item (click)="selectGroup(group)">ALL</button>

          <button *ngFor="let city of group.cities" mat-menu-item
                  (click)="selectCity(city)">{{ city }}
          </button>

        </mat-menu>
      </ng-container>
    }
  }

</mat-menu>



import { BaseComponent } from './../../../../common/components/base.component';
import { DateRangePickerComponent } from 'src/app/common/components/date.range.picker/date.range.picker.component';
import { AccordionComponent } from 'src/app/common/components/accordion/accordion.component';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IDateRange } from 'src/app/common/components/date.range.picker/date.range';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import { VisitorEventService } from '../../services/visitor.event.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ig-event-date-filter',
  templateUrl: './event.date.filter.component.html',
  styleUrls: ['./event.date.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventDateFilterComponent extends BaseComponent {
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Input() menuClass: string = '';
  @ViewChild('accordion', { static: true }) accordion: AccordionComponent;
  @ViewChild('dateRangePicker', { static: false })
  dateRangePicker: DateRangePickerComponent;

  isShowSpecificDates = false;
  title = 'FILTER BY DATE';

  @Output() dateSelected = new EventEmitter<{ from: Date; to: Date }>();

  constructor(
    public activatedRoute: ActivatedRoute,
    private _eventService: VisitorEventService,
    private _router: Router,
    private _store: Store<IAppState>
  ) {
    super({ activatedRoute });
  }

  dateRangeChanged(dateRange: IDateRange) {
    const from =
      this.mode === 'expand'
        ? new Date(dateRange.start).toLocaleDateString()
        : new Date(dateRange.start).toLocaleDateString().substring(0, 5);
    const to =
      this.mode === 'expand'
        ? new Date(dateRange.end).toLocaleDateString()
        : new Date(dateRange.end).toLocaleDateString().substring(0, 5);

    this.title = 'FROM ' + from + ' TO ' + to;
    this.accordion.closeMenu();

    this.changeDate('specific', dateRange);
  }

  changeDate(date: string, dateRange?: IDateRange) {
    let from = new Date(new Date().setHours(0, 0, 0, 0));
    let to = new Date(new Date().setHours(24, 0, 0, 0));
    const command = new CalendarQueryCommand();

    switch (date) {
      case 'all':
        break;
      case 'today':
        command.from = from;
        command.to = to;
        break;
      case 'tomorrow':
        command.from = new Date(from.setDate(from.getDate() + 1));
        command.to = new Date(to.setDate(to.getDate() + 1));
        break;
      case 'this week':
        command.from = new Date(
          from.setDate(from.getDate() + (from.getDay() - 2))
        );
        command.to = new Date(to.setDate(to.getDate() + (7 - to.getDay() + 1)));
        break;
      case 'this weekend':
        command.from = new Date(
          from.setDate(from.getDate() + (7 - from.getDay() - 2))
        );
        command.to = new Date(to.setDate(to.getDate() + (7 - to.getDay() + 1)));
        break;
      case 'specific':
        command.from = new Date(new Date(dateRange.start).setHours(0, 0, 0, 0));
        command.to = new Date(new Date(dateRange.end).setHours(24, 0, 0, 0));
        break;
      default:
        throw Error('wrong date');
    }

    // this._store.dispatch(
    //   EventQueryCommandActions.setDateRange({
    //     from: command.from,
    //     to: command.to,
    //   })
    // );

    if (date !== 'specific') {
      this.title = date === 'all' ? 'FILTER BY DATE' : date.toUpperCase();
      this.dateRangePicker?.reset();
      this.isShowSpecificDates = false;
    }

    // this._router.navigateByUrl(
    //   getRouteUrl(routeConfigs.visitor.eventCalendar, {
    //     hubName: this.hubName,
    //   }),
    //   { skipLocationChange: this.noChangeUrlWhenRedirect }
    // );

    this.dateSelected.emit({
      from: command.from,
      to: command.to,
    });
  }

  clear(isEmitEvent: boolean = true) {
    isEmitEvent ? this.changeDate('all') : (this.title = 'FILTER BY DATE');
    this.dateRangePicker?.reset();
  }
}

<ng-container *ngIf="isCssReady">
  <div
    class="transition-all hover:scale-[1.1] fixed top-1 px-3 py-1 flex items-center gap-3 cursor-pointer mobile:top-3"
    (click)="close()">
    <ig-image src="https://iti-images.s3.amazonaws.com/imgs/back-arrow.png"
              cls="w-[60px] h-[60px] mobile:w-[50px] mobile:h-[50px]"></ig-image>
    <span class="text-xl font-semibold text-white mobile:text-lg">BACK TO ALL EVENTS</span>
  </div>

  <div class="create-wrapper flex justify-center items-center relative">
    <div
      class="h-full flex shadow-2xl max-w-[1400px] mobile:block mobile:w-full mobile:h-full mobile:overflow-y-auto mobile:overflow-x-hidden"
      [class.w-full]="frontendSettings.customSettings.menuStyle.isSideBar"
      [class.mx-6]="frontendSettings.customSettings.menuStyle.isSideBar"
      [class.w-[80%]]="!frontendSettings.customSettings.menuStyle.isSideBar">

      <section class="left-wrapper !w-1/3 relative hidden xl:block">
        <div class="w-full h-full bg-black opacity-30 absolute"></div>
        <div class="w-full h-full absolute text-white text-center pt-20 flex flex-col justify-between">
          <div>
            <div class="text-3xl font-semibold">Welcome</div>
            <div class="text-base mt-4 2xl:text-lg">Thank you for submitting your events</div>
          </div>

          <div class="mb-24 mx-auto w-[90%] text-left">
            <div class="flex items-center justify-start"><span class="text-3xl mr-2 leading-6">·</span><span
              class="text-xs 2xl:text-sm">
                Please fill in your event info on the right side</span></div>
            <div class="flex items-center justify-center"><span class="text-3xl mr-2 leading-6">·</span><span
              class="text-xs 2xl:text-sm">
                Please wait at least 5 business days for approval after submitting your event</span></div>
          </div>
        </div>
      </section>

      <div class="w-full bg-white rounded-r-2xl pl-9 pr-4 py-6 text-gray-600 xl:w-2/3 mobile:px-6 mobile:pb-16">
        <div class="text-2xl">Please fill in your event info</div>
        <div class="text-sm text-gray-400">Complete all required fields marked with an * to submit your event for
          approval
        </div>
        <section class="w-full mt-4 py-2 h-[calc(100%-4rem)] overflow-y-auto pr-5 mobile:pr-0">
          <ig-field [control]="form.get('name')" label="Title" icon="stars"></ig-field>
          <ig-field [control]="form.get('host')" label="Host" icon="person"></ig-field>
          <ig-field [control]="form.get('venue')" label="Location / Venue Name" icon="location_city"></ig-field>
          <ig-address-input [control]="form.get('address')" class="block mobile:mb-6"></ig-address-input>
          <div class="flex gap-4 mb-1">
            <mat-checkbox *ngIf="frontendSettings.customSettings.isAllowOnlineEvents" class="text-gray-400"
                          (change)="onlineEventChange($event)"
                          [checked]="!!form.get('isOnlineEvent').value"
                          matTooltip="Virtual events (online events) don't have physical addresses">Virtual Event
            </mat-checkbox>
            <mat-checkbox class="example-margin" (change)="hybridChange($event)"
                          [checked]="!!form.get('isHybridEvent').value"
                          matTooltip="Hybrid events are both virtual and in-person">Hybrid Event
            </mat-checkbox>
          </div>
          <ig-field [control]="form.get('organizerEmail')" label="Event Organizer Email Address" icon="mail"></ig-field>
          <ig-field [control]="form.get('eventLink')" label="Event URL for more information (if applicable)"
                    icon="link"></ig-field>
          <ig-field [control]="form.get('hostLink')" label="Event Host Website URL" icon="link"></ig-field>
          <ig-field [control]="form.get('ticketUri')" label="Get Tickets URL (if applicable)" icon="receipt"></ig-field>
          <ig-field [control]="form.get('description')" mode="textarea" label="Description"
                    icon="more_horiz"></ig-field>

          <div *ngIf="frontendSettings">
            <ig-multi-date-time-picker [controls]="form.get('timeRanges')"
                                       [timezoneFormatter]="frontendSettings.customSettings.timezoneFormatter"></ig-multi-date-time-picker>

            <div class="text-gray-500 text-xs relative -top-4">
              {{ timeService.getTZNameByFormatter(frontendSettings.customSettings.timezoneFormatter) }}
            </div>
          </div>

          <ig-image-link-file-selector #imgUploader></ig-image-link-file-selector>

          <ig-button (trigger)="submit($event)" cls="w-[160px] mb-6 mobile:w-full" [isAsync]="true"
                     [disabled]="isFormInValid"
                     successText="Thank you for submitting your event! Please allow at least 5 business days for review and approval.">
            SUBMIT EVENT
          </ig-button>

        </section>
      </div>
    </div>
  </div>
</ng-container>

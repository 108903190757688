export class FeatureToggle {
  public tripPlanner: boolean;
  public itinerary: boolean;
  public placeCalendar: boolean;
  public manualEvents: boolean;
  public eventByGeolocation: boolean;
  public eventTicketUrl: boolean;
  public multipleCities: boolean;
  public googleReviews: boolean;
  public facebookPosts: boolean;
  public nearbyPlacesRight: boolean;
  public googlePlaceGeofence: boolean;
  public tour: {
    limit: {
      limited: boolean;
      limitation: number;
    };
  };
  public placeCategory: {
    activity: boolean;
    dining: boolean;
    accommodation: boolean;
    shopping: boolean;
    outdoor: boolean;
    entertainment: boolean;
    travel: boolean;
    other: boolean;
    art: boolean;
    thingsToDo: boolean;
  };
  public clientDashboard: {
    placeByGeoLocationTab: boolean;
  };
  public nearbyEventsInPlace: boolean;
  public nearbyPlacesInEvent: boolean;
  public placeLocationFilter: boolean;
  public eventLocationFilter: boolean;

  constructor() {
    this.tripPlanner = true;
    this.itinerary = true;
    this.placeCalendar = true;
    this.manualEvents = true;
    this.eventByGeolocation = true;
    this.eventTicketUrl = true;
    this.multipleCities = false;
    this.googleReviews = true;
    this.facebookPosts = true;
    this.placeCategory = {
      activity: true,
      dining: true,
      accommodation: true,
      shopping: true,
      outdoor: true,
      entertainment: true,
      travel: true,
      other: true,
      art: true,
      thingsToDo: true,
    };
    this.clientDashboard = {
      placeByGeoLocationTab: true,
    };
    this.nearbyEventsInPlace = false;
  }
}

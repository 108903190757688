import { IFrontendSettingsState } from './states';
import { createReducer, on } from '@ngrx/store';
import * as FrontendSettingsActions from './actions';

export const initialState: IFrontendSettingsState = {
  isLoading: false,
  frontendSettings: null,
  error: null,
};

export const frontendSettingsReducers = createReducer(
  initialState,
  on(FrontendSettingsActions.getFrontendSettings, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FrontendSettingsActions.getFrontendSettingsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    frontendSettings: action.frontendSettings,
  })),
  on(FrontendSettingsActions.getFrontendSettingsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  }))
);

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ig-stars',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent {
  public stars = [];
  public lastHalf = false;
  @Input() set rating(count: number) {
    const starCount = Math.floor(count);
    this.stars = Array.from(Array(starCount).keys());
    if (starCount < count) {
      this.lastHalf = true;
    }
    // https://iti-images.s3.amazonaws.com/imgs/rating-1.png
  }
}

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../services/local.storage.service';
import { VisitorService } from '../../services/visitor.service';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/utils/object.extensions';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';
import { catchError, of } from 'rxjs';
import { AIService } from './ai.service';
import { VisitorPlace } from '../../models/visitor.place';
import { ILinkPreview } from '../link.previewer/link.previewer.component';
import {
  AIModel,
  ITravelAssistantMessage,
} from '../../models/travel.assistant';

const { GoogleGenerativeAI } = require('@google/generative-ai');

// Access your API key as an environment variable (see "Set up your API key" above)
const genAI = new GoogleGenerativeAI('AIzaSyDkHVhpnG5Yg2TM-iJ2mnsQ4jUIL01cEnc');

@Component({
  selector: 'ig-im-messager',
  templateUrl: './im.messager.component.html',
  styleUrls: ['./im.messager.component.scss'],
})
export class ImMessagerComponent extends BaseComponent implements OnInit {
  @HostBinding('class') hostClass = 'ig-im';

  /** control the display of the entire chat popup */
  isShow = false;

  /* isShowLandscreen = true means in the first indication page of the AI chat feature */
  isShowLandscreen = true;
  hasChatViewShowed = false;
  /* the content in the landing page is showing up one by one */
  showLandscreenContent: boolean[] = [];
  // need to specify these Tailwind classes to make sure they are bundled in the final CSS
  style = 'h-[700px] w-[640px] w-[400px] h-[300px]';
  form: FormGroup;
  msg: string;
  aiModel: AIModel = 'chatgpt-then-google';
  @Input() public target = '';
  @Input() public place: VisitorPlace;

  msgHistory: ITravelAssistantMessage[];
  AIName = 'AI Travel Buddy';
  hasScrollbar = false;
  /* 
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section, 
    the top bannder section will be folded after AI has responded
  */
  isAIRespond = false;
  isFolding = false;
  /* 
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section, 
    the top bannder section will be folded, and after the folding completed, isInNormalChat will be set to true
  */
  isInNormalChat = false;
  fallbackLinkPreviewData: ILinkPreview = null;

  isFirstTime = true;
  isForDestination = false;

  errorMsgs = [
    `Looks like you've asked a question that's above my pay grade—err. Got another question to throw my way? `,
    `To err is human, to forgive divine, but for your AI Travel Buddy, it's downright awkward! Got another zinger for me? `,
    `Wow, you've managed to stump your AI buddy. You just won a million dollars! Just kidding. Got another question to throw my way? `,
    `You've found a gap in my digital wisdom! Mind tossing another question my way? `,
  ];

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('chatContainer', { static: false }) chatContainer: ElementRef;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _localStorage: LocalStorageService,
    private _visitorService: VisitorService,
    private _scriptService: ScriptService,
    private _renderer: Renderer2,
    private _aiService: AIService
  ) {
    super({ activatedRoute });

    this.form = this._fb.group({
      msg: [null, [Validators.required]],
    });

    this.resetStepShowing();
  }
  ngOnInit(): void {
    const SCRIPT_PATH = `https://cdn.jsdelivr.net/npm/marked/marked.min.js`;

    if (!this._scriptService.isScriptExist(SCRIPT_PATH)) {
      const scriptElement = this._scriptService.loadJsScript(
        this._renderer,
        SCRIPT_PATH
      );
      scriptElement.onload = () => {
        console.log('markdown script loaded');
      };
      scriptElement.onerror = () => {
        console.log('Could not load the script: ' + SCRIPT_PATH);
      };
    }

    // this.createMsg({
    //   msg: `<div>I'm your personal travel assistant. Simply ask me a question, I will provide you with relevant travel information and suggestions.
    //     <br><br> For example, you can let me to introduce ${this.target} to you.</div>`,
    //   type: 'AI',
    //   // questions: [`Please introduce ${this.target}`],
    // } as ITravelAssistantMessage);

    // this.msg = this.msgHistory[0].questions[0];

    if (this.place) {
      this.fallbackLinkPreviewData = {
        title: this.place.title,
        description:
          this.place.description ||
          `${this.place.category} @ ${this.place.address.address}`,
        image: this.place.cover?.source,
        url: this.place.website,
      };
    }
  }

  get isTipShowed() {
    // return this._localStorage.getItem<boolean>('ai_assistant_isTipShowed');
    return false;
  }

  checkHasScrollbar() {
    setTimeout(() => {
      if (this.chatContainer) {
        var div = this.chatContainer.nativeElement;
        this.hasScrollbar = div.scrollHeight > div.clientHeight;
      }
    }, 150);

    if (!this.isInNormalChat) {
      this.isAIRespond = this.msgHistory?.any(
        (x) => x.type === 'AI' || x.type === 'AI_ERROR'
      );

      if (this.isAIRespond) {
        this.isFolding = true;
        setTimeout(() => {
          this.isFolding = false;
          this.isInNormalChat = true;
        }, 700);
      }
    }
  }

  show() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this._localStorage.setItem('ai_assistant_isTipShowed', true);
    }

    if (!this.isShow) {
      this.resetStepShowing();
    } else {
      setTimeout(() => {
        let index = 0;
        this.buildStepShowing(index);
      }, 200);
    }
  }

  buildStepShowing(index: number) {
    if (index < 10) {
      setTimeout(() => {
        this.showLandscreenContent[index] = true;
        this.buildStepShowing(++index);
      }, 50);
    }
  }

  resetStepShowing() {
    for (let i = 0; i < 10; i++) {
      this.showLandscreenContent[i] = false;
    }
  }

  gotoChat(msg?: string) {
    this.isShowLandscreen = false;
    this.send(msg?.replace('{{target}}', this.target));
  }

  // get isAIRespond() {
  //   return this.msgHistory?.any(
  //     (x) => x.type === 'AI' || x.type === 'AI_ERROR'
  //   );
  // }

  send(question?: string) {
    // question = question from suggestions, this.msg = user input question, this.msgHistory.last() = last user question, for retrying
    this.msg =
      question ||
      this.msg ||
      this.msgHistory?.filter((x) => x.type === 'USER')?.last()?.msg;
    if (!this.msg) return;

    const history = this.msgHistory
      ?.filter((x) => x.type !== 'AI_ERROR')
      // .slice(1)
      .map((item) => {
        const type = item.type;
        return {
          msg: item.msg,
          type,
        };
      });

    this.askAI(history);

    this.createMsg({
      msg: this.msg,
      type: 'USER',
    } as ITravelAssistantMessage);

    this.createMsg({
      msg: '',
      type: 'AI_THINKING',
      suggestions: [],
      questions: [],
    } as ITravelAssistantMessage);

    this.msg = '';
  }

  keypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.gotoChat();
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      this.container &&
        this.container.nativeElement.scrollTo({
          top: this.container.nativeElement.scrollHeight,
        });
      this.isInNormalChat &&
        this.chatContainer &&
        this.chatContainer.nativeElement.scrollTo({
          top: this.chatContainer.nativeElement.scrollHeight,
        });
    });
  }

  createMsg(msg: ITravelAssistantMessage): void {
    if (isNullOrUndefined(this.msgHistory)) {
      this.msgHistory = [];
    }

    if (msg.type === 'AI_THINKING') {
      this.msgHistory.push({
        msg: '',
        type: msg.type,
        name: this.AIName,
        questions: [],
        suggestions: [],
      });
      this.scrollToBottom();
      return;
    }

    if (this.msgHistory.last()?.type === 'AI_THINKING') {
      this.msgHistory = this.msgHistory.slice(0, this.msgHistory.length - 1);
    }

    this.msgHistory.push({
      msg: msg.msg,
      type: msg.type,
      name: msg.type === 'USER' ? 'Me' : this.AIName,
      model: msg.model,
      questions: msg.questions,
      suggestions: msg.suggestions,
      errorMsg: this.errorMsgs.getRandom(),
    });

    this.scrollToBottom();
    this.checkHasScrollbar();
  }

  async askAI(history: { msg: string; type: string }[], retryTimes?: number) {
    if (!retryTimes) retryTimes = 0;

    this._visitorService
      .sendAIQuery(
        this.hubName,
        'place',
        this.aiModel,
        this.msg,
        history,
        this.target
      )
      .pipe(
        catchError((err) => {
          console.log(err);
          return of({ success: false });
        })
      )
      .subscribe((response) => {
        console.log(response);

        // aiModel = all
        if (Array.isArray(response)) {
          const bard = response.find((x) => x.model === 'google-bard');
          if (bard && bard.success) {
            this.createGoogleBardMsg(bard);
          } else {
            this.createErrorMsg(bard);
          }

          const palm = response.find((x) => x.model === 'google-palm');
          setTimeout(() => {
            if (palm && palm.success) {
              this.createGoogleBardMsg(palm);
            } else {
              this.createErrorMsg(palm);
            }
          }, 200);

          const chatgpt = response.find((x) => x.model === 'chatgpt');
          setTimeout(() => {
            if (chatgpt && chatgpt.success) {
              this.createGPTMsg(chatgpt);
            } else {
              this.createErrorMsg(chatgpt);
            }
          }, 400);

          return;
        }

        if (!response.success) {
          if (retryTimes < 0) {
            retryTimes++;
            this.askAI(history, retryTimes);
          } else {
            this.createMsg({
              type: 'AI_ERROR',
            } as ITravelAssistantMessage);
          }
          return;
        }
        if (response.data) {
          this.createGoogleBardMsg(response);
        }
      });
  }

  createGPTMsg(response) {
    const data = JSON.parse(response.data);
    this.createMsg({
      msg: data.answer,
      type: 'AI',
      model: response.model,
      questions: ['Please tell more', ...data.questions],
    } as ITravelAssistantMessage);
  }

  createGoogleBardMsg(response) {
    let text = response.data;
    const pattern = /### (.+?)\n/g;

    const matches = text.match(pattern);
    if (matches && matches.length) {
      for (const match of matches) {
        console.log(match);
        text = text.replace(match, match + '</h3>\n');
      }
    }

    let processed = text
      .replaceAll('/【(.*?)source】/', '')
      .replaceAll('\n', '')
      // .replaceAll('\n', '<br>')
      .replaceAll('###', '<h3>')
      // .replaceAll('*', '<br>-')
      .replaceAll('&quot;', '')
      .replaceAll('json\n', '')
      .replaceAll('"answer": {', '"answer":')
      .replaceAll('"text":', '')
      .replaceAll('```{', '{')
      .replaceAll('}```', '}')
      .replaceAll('```json{', '{');

    console.log('processed', processed);

    try {
      const data = JSON.parse(processed);
      processed = window['marked'].parse(data.answer);
      // processed = processed.replaceAll('* ', '<br>* ');
      processed = processed.replaceAll('<a href=', '<a target="_blank" href=');
      console.log('processed0', processed);

      this.createMsg({
        msg: processed, //window['marked'].parse(response.all),
        type: 'AI',
        model: response.model,
        suggestions: data.nearbyPlaces,
        questions: ['Please tell more', ...data.questions],
      } as ITravelAssistantMessage);
    } catch (e) {
      console.error(e);

      processed = window['marked'].parse(processed);
      // processed = processed.replaceAll('* ', '<br><br>');
      processed = processed.replaceAll('<a href=', '<a target="_blank" href=');

      console.log('processed1', processed);
      const names = processed.match(/<strong>(.*?)<\/strong>/g);
      console.log('names', names);

      if (names) {
        const contents = names.map((match) =>
          match.replace(/<\/?strong>/g, '')
        );
        console.log('contents', contents);

        processed = processed.replace(
          names[0],
          `<a style="font-weight: bold" target="_blank" href="https://baidu.com">${contents[0]}</a>`
        );
      }

      this.createMsg({
        msg: processed,
        type: 'AI',
        model: response.model,
        suggestions: [],
        questions: ['Please tell more'],
      } as ITravelAssistantMessage);
    }
  }

  createErrorMsg(response) {
    this.createMsg({
      msg: response.model + ` didn't respond correctly`,
      type: 'AI',
      model: response.model,
    } as ITravelAssistantMessage);
  }

  removeLastOccurrence(str, substring) {
    const lastIndex = str.lastIndexOf(substring);

    if (lastIndex !== -1) {
      return str.slice(0, lastIndex) + str.slice(lastIndex + substring.length);
    }

    return str;
  }

  loginStatusChanged(status: boolean) {}
}

<div *ngIf="isCssReady" [class.invisible]="!isOpened" [class.scale-75]="!isOpened" [class.scale-100]="isOpened"
     class="transition-all hover:scale-[1.1] fixed top-1 px-3 py-1 flex items-center gap-3 cursor-pointer mobile:top-3"
     (click)="close()">
  <ig-image src="https://iti-images.s3.amazonaws.com/imgs/back-arrow.png"
            cls="!w-[60px] !h-[60px] mobile:w-[50px] mobile:h-[50px]"></ig-image>
  <span class="text-xl font-semibold text-white mobile:text-lg">BACK TO {{ getFrom() }}</span>
</div>

<!-- <div>
  @if(event&&isCssReady) {
  @defer (on timer(50)) {
  <div>event and css are ready!</div>
  }
  } @else {
  <div>not ready</div>
  }

  @for (checklist of checklists; track checklist.id) {
    <li>{{checklist.title}}</li>
  }
  @empty {
    <p>Create your first list</p>
  }
</div> -->

<div *ngIf="event&&isCssReady&&!isLoading"
     class="ig-fancy-event-details ig-modal-shadow flex h-full justify-center bg-color mobile:overflow-y-auto">
  <div class="w-full h-full absolute z-10">
    <div class="w-full h-full relative">
      <div class="w-full h-full absolute bg-white opacity-60 z-20"></div>
      <ig-image [src]="event?.cover?.source" class="block w-full h-full blur-md" cls="h-full w-full"></ig-image>
    </div>
  </div>
  <div class="flex max-w-[1600px] w-full z-20 mobile:block">
    <section class="main-event-info h-full bg-white relative w-[75%] mr-[1.2vw] mobile:w-full">
      <ig-image [src]="event.cover?.source" [alt]="event?.name" class="block w-[calc(100%-12px)]"
                (onLoaded)="checkImageVague($event)"
                cls="w-[calc(100%-12px)] aspect-[3/1] object-cover mobile:aspect-[3/2] mobile:w-full"></ig-image>
      <div mask *ngIf="isVagueImage"
           class="w-full aspect-[3/1] bg-white opacity-50 absolute top-0 left-0 mobile:hidden"></div>
      <ig-image *ngIf="isVagueImage" [alt]="event?.name" [src]="event.cover?.source"
                class="absolute top-0 w-1/2 h-[400px] left-1/4 mobile:hidden" cls="w-full h-full"></ig-image>
      <div
        class="h-full w-full overflow-y-auto absolute shadow-2xl top-0 pt-[285px] 2xl:pt-[340px] mobile:block mobile:overflow-y-visible mobile:pt-[195px]">

        <!-- <div
          class="absolute top-4 right-4 w-8 h-8 rounded-full bg-gray-400 transition-all p-1.5 cursor-pointer hover:shadow-md hover:p-1"
          matTooltip="view picture" (click)="viewImage()">
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-10dohqv" focusable="false" aria-hidden="true"
            viewBox="0 0 32 32" data-testid="LargePreview.svgIcon">
            <path d="M14.667 8H12v4H8v2.667h4v4h2.667v-4h4V12h-4V8z" class="fill-white"></path>
            <path class="fill-white"
              d="M21.747 19.867c1.399-1.787 2.243-4.067 2.243-6.544 0-5.891-4.776-10.667-10.667-10.667S2.656 7.432 2.656 13.323 7.432 23.99 13.323 23.99c2.477 0 4.757-.844 6.567-2.261l7.563 7.604 1.88-1.88zm-8.414 1.466c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z">
            </path>
          </svg>
        </div> -->
        <div
          class="px-3 py-1 rounded-2xl absolute right-4 top-4 bg-[#00bfff65] outline outline-2 outline-[#41abf3] text-white text-xs cursor-pointer transition-all hover:scale-[1.05]"
          (click)="viewImage()">EXPAND IMAGE
        </div>

        <div
          class="ig-fancy-details-ticket relative mx-auto bg-white rounded-2xl min-h-[300px] shadow-xl flex justify-between w-[70%] desktop:min-w-[700px] mobile:block mobile:w-[90%]">
          <div class="p-6 relative w-[60%] mobile:w-full">
            <div class="text-lg 2xl:text-[22px] text-gray-600 font-bold mb-4">{{ event.name }}</div>
            <div class="mb-6">
              <div>
                <span class="text-xs text-gray-400">Date and Time</span>
              </div>
              <span *ngIf="event.eventTimes.length>1" class="text-sm text-gray-500 font-semibold mr-1">Multiple times
                ·</span>
              <span class="text-sm text-gray-500 font-semibold">{{ event.timeTwoLines?.date }}</span>
              <span *ngIf="event.eventTimes.length>1||event.timeTwoLines?.time"
                    class="mx-2 text-base text-gray-500 mobile:hidden">·</span>
              <span *ngIf="event.eventTimes.length>1" class="text-base text-gray-500 mobile:hidden">See below</span>
              <span *ngIf="event.eventTimes.length<=1"
                    class="text-sm text-gray-500 font-semibold mobile:block">{{ event.timeTwoLines?.time }}</span>

              <ig-add-to-calendar-btn [event]="event"
                                      class="block desktop:w-1/2 desktop:min-w-[200px]"></ig-add-to-calendar-btn>

            </div>
            <div class="mb-6">
              <div>
                <span class="text-xs text-gray-400">Address</span>
              </div>
              <div *ngIf="event.hostLink&&eventService.venueName(event)">
                <a class="text-sm font-semibold" [href]="getLink(event.hostLink)" target="_blank"
                   matTooltip="View host website">{{ eventService.venueName(event) }}</a>
              </div>
              <div *ngIf="!event.hostLink&&eventService.venueName(event)" class="text-sm text-gray-500 font-semibold">
                <span>{{ eventService.venueName(event) }}</span>
              </div>
              <span class="text-sm text-gray-500">{{
                  event.isOnlineEvent ? '(Online event)' :
                    event.address?.address
                }}</span>
            </div>
            <div class="justify-between items-end mobile:block" [class.flex]="favCustomCategories.length<3">
              <div *ngIf="favCustomCategories.length" class="ig-event-category flex gap-2 flex-wrap mobile:mb-6">
                <div *ngFor="let category of favCustomCategories" target="_blank"
                     class="py-1 px-2 bg-[#E4E6EB] text-xs text-gray-700 rounded-[4px] h-7 leading-5 ellipsis">
                  {{ category }}
                </div>
              </div>
              <!-- <div *ngIf="event.categories?.length" class="ig-event-category flex gap-2 mobile:mb-6">
                <a *ngFor="let category of event.categories" [href]="category.link" target="_blank"
                  class="py-1 px-2 bg-[#E4E6EB] text-xs text-gray-700 rounded-[4px] h-7 leading-5">
                  {{category.name}}
                </a>
              </div> -->
              <div class="mobile:mb-2">
                <ig-social-share *ngIf="event" [item]="event" appearance="rounded" [showTip]="false"
                                 (onGetLink)="showLink()"></ig-social-share>
              </div>


            </div>
          </div>

          <div
            class="center-devider border-l-[3px] border-r-0 border-dashed border-gray-200 relative flex items-center w-[16px] mobile:w-full mobile:border-t-[3px] mobile:border-l-0 mobile:h-0">
            <div
              class="w-10 h-10 rounded-full bg-gray-100 absolute -left-[22px] mobile:relative mobile:left-[calc(50%-1.25rem)] mobile:-top-[2px]">
            </div>
          </div>

          <div class="p-6 w-[40%] mobile:w-full">
            <ig-info-ticket [event]="event"></ig-info-ticket>
            <!-- ShelbyCoIN uses the script solution inside an iframe due to the limit of their CMS, which we are not able to show QRcode -->
            <!-- https://visitshelbycounty.com/festivals-events -->
            <div *ngIf="hubName!=='ShelbyCoIN'"
                 class="h-[50%] w-full border-t-[1px] border-solid border-gray-100 border-b-0 border-l-0 border-r-0 flex items-center mobile:hidden"
                 [class.pt-2]="!event.ticketInfo||event.ticketInfo.length<2"
                 [class.height-38]="settings.toggle.tripPlanner&&event.ticketInfo?.length>=2"
                 [class.height-42]="settings.toggle.tripPlanner&&(event.ticketInfo?.length===1||event.ticketUri)">
              <div class="w-[65%] text-sm">Scan the QR code to view this event on your mobile phone</div>
              <ig-qrcode class="w-[35%] block"></ig-qrcode>
            </div>
            <ig-add-to-trip *ngIf="settings.toggle.tripPlanner" [item]="event"
                            class="block mobile:mt-2"></ig-add-to-trip>
          </div>
        </div>

        <div class="w-[70%] desktop:min-w-[700px] mx-auto bg-white rounded-t-2xl p-6 mt-6 mobile:w-full">
          <div *ngIf="event.eventTimes.length>1" class="mb-10">
            <div class="text-2xl text-gray-600 font-bold mb-4">Dates & Times</div>
            <ig-multi-event-times [event]="event"></ig-multi-event-times>
          </div>

          <ng-container *ngIf="event.multiHosts&&event.multiHosts.length">
            <div class="text-2xl text-gray-600 font-bold">Meet the Host{{ event.multiHosts.length > 1 ? 's' : '' }}
            </div>

            <div class="py-4 grid gap-4 ig-host">
              <div *ngFor="let host of event.multiHosts" class="p-4 border border-solid rounded-xl">
                <div class="flex gap-4 mb-4">
                  <ig-image
                    cls="!w-[70px] max-w-[70px] h-[70px] rounded-full mobile:w-[60px] mobile:h-[60px] outline outline-[#41abf3]"
                    [src]="host.img"></ig-image>
                  <div class="flex flex-col justify-center">
                    <a class="text-sm font-bold" [href]="host.link" target="_blank">{{ host.name }}</a>
                    <div class="text-xs text-gray-600">{{ host.about }}</div>
                  </div>
                </div>
                <hr class="my-3">
                <div class="text-xs text-gray-500 mt-2" [innerHTML]="host.description||host.link"></div>
              </div>
            </div>
          </ng-container>

          <div class="mt-2">
            <div class="text-2xl text-gray-600 font-bold">Description</div>
            <div class="!my-4 !p-0 text-gray-600 sun-editor-editable"
                 [innerHTML]="sanitizer.bypassSecurityTrustHtml(displayDesc || '(No Description)')">
            </div>
          </div>

          <div *ngIf="settings.toggle.nearbyPlacesInEvent" class="mt-8">
            <div class="text-2xl text-gray-600 font-bold mb-4">Nearby Places to Explore</div>
            <ig-nearby-places *ngIf="!event.isOnlineEvent" #nearbyPlacesCom [place]="targetForNearbyPlaces"
                              [range]="settings.nearbyPlacesDefaultRange || 2"
                              (rangeChanged)="isShowingNearbyPlacesOnMap&&toggleShowNearbyPlacesOnMap({checked:true})">
            </ig-nearby-places>
          </div>

          <div *ngIf="!event.isOnlineEvent" class="mt-8">
            <div class="text-2xl text-gray-600 font-bold">View on Map</div>

            <div class="my-4">
              <div *ngIf="event.venueInfo?.rating">
                <div *ngIf="event.hostLink&&eventService.venueName(event)" class="text-sm font-semibold text-gray-600">
                  {{ eventService.venueName(event) }}
                </div>
                <span class="mr-3 text-sm text-gray-500">
                  Rating: {{ event.venueInfo.rating }}</span>
                <ig-stars class="relative top-[2px] mr-3" [rating]="event.venueInfo?.rating"></ig-stars>
                <span *ngIf="event.venueInfo?.reviews" class="text-sm text-gray-600">{{ event.venueInfo?.reviews }}
                  reviews</span>
              </div>

              <div class="flex justify-between items-center gap-4 mobile:flex-col mobile:h-auto mobile:gap-2"
                   [class.mb-3]="!settings?.toggle?.nearbyPlacesInEvent">
                <div class="text-gray-600 text-sm">{{ event.address?.address }}</div>
                <div
                  class="w-[160px] mobile:!w-full !text-base mb-2 bg-[#41abf3] text-white flex justify-center items-center h-8 cursor-pointer transition-all rounded-sm hover:shadow-md"
                  (click)="getDirection(event)">Get Directions
                </div>
                <!-- <ig-button [style]="'flat'" class="mobile:!w-full" cls="w-[160px] mobile:w-full mb-2"
                  (trigger)="getDirection(event)">Get
                  Directions</ig-button> -->
              </div>
              <div *ngIf="settings?.toggle?.nearbyPlacesInEvent" class="flex gap-4 relative -left-[10px] -top-[6px]">
                <mat-checkbox [checked]="isShowingNearbyPlacesOnMap" (change)="toggleShowNearbyPlacesOnMap($event)">
                  Show nearby places
                </mat-checkbox>
              </div>

              <ig-google-map #map class="h-[300px] w-full transition-all mobile:h-[200px]"
                             [class.h-[400px]]="isShowingNearbyPlacesOnMap" [markers]="mapMarkers"
                             [useDefaultMarkerClickStyle]="false"
                             (onMarkerClick)="markerClicked($event)"></ig-google-map>

              <hr class="!mt-10">
              <ig-powered-by [style]="'iti-horizontal'" class="mt-6 mx-auto flex justify-center"></ig-powered-by>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="w-[25%] other-upcoming bg-white mobile:w-full mobile:hidden">
      <ig-upcoming-events mode="fancy" [currentEvent]="event" [allEvents]="allEvents" (onMoreClick)="close()"
                          (onUpcomingEventClick)="close()"></ig-upcoming-events>
    </section>
  </div>
</div>

<div *ngIf="!isCssReady" class="py-12 px-40 flex h-full w-full min-w-[1200px] max-w-[1600px] mobile:px-4 mobile:py-4">
  <section class="w-[80%] h-full mobile:w-full">
    <div class="!rounded-xl w-full h-1/3 ig-block"></div>
    <div class="mt-8 w-[20%] h-6 ig-block mobile:w-3/5"></div>
    <div class="mt-4 w-[10%] h-6 ig-block mobile:w-2/5"></div>
    <div class="mt-4 w-[60%] h-6 ig-block mobile:w-full"></div>

    <div class="flex gap-4 mt-8 h-[14vw] justify-between mobile:gap-2 mobile:h-[40vw]">
      <div class="ig-block"></div>
      <div class="ig-block"></div>
      <div class="ig-block mobile:hidden"></div>
      <div class="ig-block mobile:hidden"></div>
    </div>
  </section>

  <section class="w-[20%] h-full gap-6 flex-col flex ml-[2vw] mobile:hidden">
    <div class="w-full h-[25vh] max-h-[237px] ig-block"></div>
    <div class="w-full h-[25vh] max-h-[237px] ig-block"></div>
    <div class="w-full h-[25vh] max-h-[237px] ig-block"></div>
  </section>
</div>

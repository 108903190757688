<div class="w-full h-fit">
  <div
    class="border-4 border-dashed border-gray-200 h-fit rounded-lg flex flex-col items-center justify-center cursor-pointer"
    (click)="imageSelector.click()">
    <div class="text-gray-300 text-center flex justify-center gap-4 items-center h-14">
      <mat-icon class="text-xl text-gray-300">upload</mat-icon>
      <span class="text-base">{{ selectedImageData ? 'Change' : 'Upload an' }} image</span>

    </div>
    <div class="text-gray-300 mb-4 text-sm text-center px-3">Please upload images in JPG, PNG or WEBP format. Landscape
      images
      recommended
    </div>
  </div>
  <input type="file" class="invisible" [disabled]="disabled" accept="image/png,image/gif,image/jpeg" #imageSelector
         (change)="onImageChange($event)">

  <div *ngIf="selectedImageData">
    <p class="text-sm text-gray-500">Preview</p>
    <ig-image class="block m-auto" [ngStyle]="{'max-width': selectedImageSize.width + 'px'}"
              [src]="imgService.safeImage(imgService.imageSourceUrl(selectedImageData))"></ig-image>
  </div>
</div>

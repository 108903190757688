import { createSelector } from '@ngrx/store';
import { IAppState } from './states';

export const selectFeature = (state: IAppState) => state.frontendSettings;

export const frontendSettingsIsLoadingSelector = createSelector(
  selectFeature,
  (state) => state.isLoading
);

export const frontendSettingsSelector = createSelector(
  selectFeature,
  (state) => state.frontendSettings
);

export const frontendSettingsErrorSelector = createSelector(
  selectFeature,
  (state) => state.error
);

import { ColorStyle } from './../../models/color.style';
import {
  IAppState,
  IFrontendSettings,
} from './../../ngrx.stores/frontend.settings/states';
import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  HostBinding,
} from '@angular/core';
import { RouterOutlet, ActivatedRoute, Router } from '@angular/router';
import { filter, Observable, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { MediaQueryService } from 'src/app/common/services/media.query/media.query.service';
import { select, Store } from '@ngrx/store';
import {
  frontendSettingsErrorSelector,
  frontendSettingsIsLoadingSelector,
  frontendSettingsSelector,
} from '../../ngrx.stores/frontend.settings/selectors';
import * as FrontendSettingsActions from '../../ngrx.stores/frontend.settings/actions';
import { routeConfigs } from 'src/app/common/constants/route.config';

@Component({
  selector: 'ig-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisitorComponent extends BaseComponent implements OnInit {
  @HostBinding('class') hostClass = 'h-full block';
  public isSettingsLoaded = true;
  public isShowUpgradeBrowser = true;
  public isMobile = false;

  isLoading$: Observable<boolean>;
  error$: Observable<string | null>;
  frontendSettings$: Observable<IFrontendSettings | null>;

  @ViewChild('outlet', {static: false}) private _outlet: RouterOutlet;

  isOneRowMode = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public mediaQueryService: MediaQueryService,
    private _store: Store<IAppState>
  ) {
    super({activatedRoute});

    this.mediaQueryService.isMobile$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.isLoading$ = this._store.pipe(
      select(frontendSettingsIsLoadingSelector)
    );
    this.error$ = this._store.pipe(select(frontendSettingsErrorSelector));
    this.frontendSettings$ = this._store.pipe(select(frontendSettingsSelector));
  }

  ngOnInit(): void {
    this._store.dispatch(
      FrontendSettingsActions.getFrontendSettings({hubName: this.hubName})
    );

    this.frontendSettings$.subscribe((settings: IFrontendSettings) => {
      if (settings) this.setCustomerColor(settings.customSettings.Style);
    });
  }

  get isShowTravelBuddy() {
    return (
      // !this.isOneRow() &&
      this.frontendSettings?.ai?.travelBuddy?.isActive &&
      (this.isAiAssistant() ||
        this.getScriptValue('travel-buddy') === 'true' ||
        this.frontendSettings.ai.travelBuddy.showOnAllClientWidgets ||
        !this.isScriptSolution)
    );
  }

  public isTopbar(settings: IFrontendSettings) {
    return (
      !settings.customSettings.menuStyle.isSideBar &&
      !this.isMobile &&
      this.isNeedSideBar
    );
  }

  public isSidebar(settings: IFrontendSettings) {
    return (
      settings.customSettings.menuStyle.isSideBar &&
      !this.isMobile &&
      this.isNeedSideBar
    );
  }

  // this is for the one row scrolling widget
  public isOneRow() {
    // return this._outlet?.activatedRoute.component === OneRowWidgetComponent;

    // setTimeout(() => {
    //   this.isOneRowMode =
    //     this._outlet?.activatedRoute.component === OneRowWidgetComponent;
    //   console.log('this.isOneRowMode', this.isOneRowMode);
    // });

    return this.router.url.includes(routeConfigs.visitor.eventCalendar.oneRow.path);
  }

  /** there are 2 types of embedding TravelBuddy
   * 1. only embed TravelBuddy to any client webpage
   * 2. embed both TravelBuddy and normal events/places calendar
   * here is for the first type, check if the embed URL is for embed TravelBuddy, then only load it without the calendar
   */
  public isAiAssistant() {
    if (this.router.url.includes(routeConfigs.visitor.ai.travelBuddy.path)) {
      return true;
    }
    return false;
  }

  private setCustomerColor(data: ColorStyle) {
    if (!data) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let s = document.createElement('style');
    s.setAttribute('type', 'text/css');
    let css = '';
    if (data.defaultButton.backgroundColor) {
      css += `--default-btn-background-color: ${data.defaultButton.backgroundColor};`;
    }
    if (data.eventButton.backgroundColor) {
      css += `--event-btn-background-color: ${data.eventButton.backgroundColor};`;
    }
    if (data.placeButton.backgroundColor) {
      css += `--place-btn-background-color: ${data.placeButton.backgroundColor};`;
    }
    if (data.pinnedEventBorder.borderColor) {
      css += `--pinned-event-border-color: ${data.pinnedEventBorder.borderColor};`;
    }
    if (data.detailButton.backgroundColor) {
      css += `--detail-btn-background-color: ${data.detailButton.backgroundColor};`;
    }
    if (data.sidebarUnderline.color) {
      css += `--sidebar-underline-color: ${data.sidebarUnderline.color};`;
    }
    s.appendChild(
      document.createTextNode(`
            :root {
              ${css}
            }
        `)
    );
    head.appendChild(s);
  }

  public get isNeedSideBar() {
    try {
      /**
       * VisitorPlaceMapComponent needs to have topbar (in topbar mode) is because the multi-level nested dropdown menu would have UX issue
       * after view changed. So keep the topbar alive but hide it when switching to place map view
       * */
      // return this._outlet.activatedRoute.component === VisitorCalendarComponent ||
      //     //this._outlet.activatedRoute.component === VisitorEventMapComponent ||
      //     (!this.allFrontendSettings.customSettings.menuStyle.isSideBar && this._outlet.activatedRoute.component === VisitorPlaceMapComponent) ||
      //     this._outlet.activatedRoute.component === VisitorCreateEventComponent ||
      //     this._outlet.activatedRoute.component === VisitorCreatePlaceComponent;
      return true;
    } catch (e) {
      return true;
    }

    // don't know why using the Component Name VisitorTripComponent causes a script error
    //this._outlet.activatedRoute.component.toString().indexOf('VisitorTripComponent') !== -1 ;
  }

  public get isNoTopMenuForMobile() {
    return (
      this._outlet.activatedRoute.component
        .toString()
        .indexOf('VisitorTripComponent') !== -1
    );
  }

  public upgradeBrowser() {
    window.open(
      'https://browser-update.org/update-browser.html?force_outdated=true#3.3.15:browser-update.org',
      '_blank'
    );
  }

  public hideUpgradeBrowser() {
    this.isShowUpgradeBrowser = false;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ig-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit {
  qrdata: string;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.qrdata = window.location.href;
    });
  }
}

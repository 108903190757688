<div class="w-full h-fit">
  <ig-field [control]="form.get('imageLink')" label="Image Url" icon="image"></ig-field>
  <div class="text-sm text-gray-500 relative -top-2">or</div>
  <ig-image-selector #imgSelector (onImageChanged)="imgSelected($event)" [class.mb-6]="form.get('imgFile').value"
    class="block"></ig-image-selector>

  <mat-checkbox *ngIf="(isUsingFile&&imgSelector?.isImageChanged)||(isUsingLink)" [(ngModel)]="hasCopyrights"
    class="mb-2">Please note that any images uploaded or used in this software must either be royalty-free or owned by
    the client. By uploading or using an image, you
    acknowledge that you have the necessary rights to use it and that you will be solely responsible for any
    copyright infringement or other legal issues that may arise from the use of the image. We strongly advise our
    users to exercise due diligence and ensure that they have the necessary rights to use any images uploaded or
    used in this software.
  </mat-checkbox>
</div>
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImageComponent } from '../image/image.component';

@Component({
  selector: 'ig-image-gallery',
  // standalone: true,
  // imports: [],
  templateUrl: './image.gallery.component.html',
  styleUrl: './image.gallery.component.scss',
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  @Input() images: string[] = [];
  @Input() interval: number = 3000;
  @Input() mode: 'transparent' = 'transparent';

  topIndex = 0;
  bottomIndex = 1;
  intervalObj = undefined;

  @ViewChild('topImage', { static: false }) topImage: ImageComponent;
  @ViewChild('bottomImage', { static: false }) bottomImage: ImageComponent;

  constructor() {}
  ngOnDestroy(): void {
    this.intervalObj && clearInterval(this.intervalObj);
  }
  ngOnInit(): void {
    if (this.images.length >= 2) {
      this.loop();
    }
  }

  loop() {
    this.intervalObj = setInterval(() => {
      if (this.images.length < 2) {
        clearInterval(this.intervalObj);
        return;
      }
      this.topImage.cls += ' duration-1000';
      this.topImage.cls += ' opacity-0';
      setTimeout(() => {
        this.topIndex = this.images[this.topIndex + 1] ? this.topIndex + 1 : 0;
        this.topImage.cls = this.topImage.cls.replace('duration-1000', '');
        this.topImage.cls = this.topImage.cls.replace('opacity-0', '');
        this.bottomIndex = this.images[this.bottomIndex + 1]
          ? this.bottomIndex + 1
          : 0;
      }, 1000);
    }, this.interval);
  }
}

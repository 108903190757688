import { IAppState } from './../../../../ngrx.stores/frontend.settings/states';
import { VisitorEventService } from 'src/app/modules/event-calendar/services/visitor.event.service';
import { MediaQueryService } from 'src/app/common/services/media.query/media.query.service';
import { VisitorMenuBarBaseComponent } from './../visitor.menu.bar.base.component';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalService } from 'src/app/common/services/modal/modal.service';

@Component({
  selector: 'ig-top-bar',
  templateUrl: './visitor.top.bar.component.html',
  styleUrls: ['./visitor.top.bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisitorTopBarComponent extends VisitorMenuBarBaseComponent {
  constructor(
    public override activatedRoute: ActivatedRoute,
    public override mediaQueryService: MediaQueryService,
    public override eventService: VisitorEventService,
    public override modalService: ModalService,
    protected override _router: Router,
    protected override _store: Store<IAppState>
  ) {
    super(
      activatedRoute,
      mediaQueryService,
      eventService,
      modalService,
      _router,
      _store
    );
  }
}

import { ConfigService } from './common/services/config/config.service';
import {
  NgModule,
  isDevMode,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { config } from './config/config';
import { environment } from 'src/environments/environment';
import { buildSpecificModules } from './build.specifics/ngrx.store.devtools/module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { SharedModule } from './modules/shared/shared.module';
import { GlobalErrorHandler } from './global.error.handler';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatDialogModule,
    MatChipsModule,

    /** for lazying loading */
    SharedModule,

    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    // remember to check if there is script error if not register service worker on client site, as PanamaCity client wants to remove the service worker not register script error
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    ...buildSpecificModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        ConfigService.config = config;

        return () =>
          new Promise((resolve) => {
            // here we can load different config file for different environment
            // if (environment.production) {}
            console.log('is production', environment.production);
            resolve(true);
          });

        // here we can load different config file for different environment
        // const httpClient = inject(HttpClient);
        // return () => {
        //   httpClient
        //     .get('./config/config.ts')
        //     .pipe(
        //       tap((data) => {
        //         console.log('data', data);
        //       })
        //     )
        //     .subscribe();
        // };
      },
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

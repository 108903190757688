<div *ngIf="isCssReady" [class.invisible]="!isOpened" [class.scale-75]="!isOpened" [class.scale-100]="isOpened"
     class="transition-all hover:scale-[1.1] fixed top-1 px-3 py-1 flex items-center gap-3 cursor-pointer mobile:top-3"
     (click)="close()">
  <ig-image src="https://iti-images.s3.amazonaws.com/imgs/back-arrow.png"
            cls="w-[60px] h-[60px] mobile:w-[50px] mobile:h-[50px]"></ig-image>
  <span class="text-xl font-semibold text-white mobile:text-lg">BACK TO {{ getFrom() }}</span>
</div>

<div *ngIf="isCssReady&&place&&!isLoading"
     class="ig-modal-shadow flex h-full justify-center bg-color mobile:overflow-y-auto">
  <div class="flex max-w-[1600px] w-full mobile:block justify-center mobile:flex-col">
    <section class="main-place-info h-full bg-white relative w-[75%] mr-4 mobile:w-full mobile:h-auto">
      <div class="relative">
        <ig-image [src]="currentImg" (onLoaded)="imgLoaded($event)" class="block m-8 mobile:m-0"
                  cls="w-[calc(100%-12px)] aspect-[7/3] object-cover rounded-3xl mobile:aspect-[3/2] mobile:w-full mobile:rounded-none"></ig-image>
        <div *ngIf="isLoadingImg" class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <ig-spinner mode="block"></ig-spinner>
        </div>

        <div *ngIf="!place.isManuallyCreated&&hasMultiImages"
             class="absolute right-16 top-0 h-[92%] z-10 flex flex-col gap-8 justify-center mobile:gap-4 mobile:h-full mobile:right-4">
          <div
            class="w-12 h-12 bg-[#e5e5e5b3] rounded-full flex justify-center items-center cursor-pointer transition-all hover:scale-[1.08] mobile:w-10 mobile:h-10"
            (click)="prevImg()" [class.disabled-arrow]="!hasPrevImg">
            <mat-icon class="!text-[40px] !w-10 !h-10 !leading-10 text-gray-600">chevron_left</mat-icon>
          </div>
          <div
            class="w-12 h-12 bg-[#e5e5e5b3] rounded-full flex justify-center items-center cursor-pointer transition-all hover:scale-[1.08] mobile:w-10 mobile:h-10"
            (click)="nextImg()" [class.disabled-arrow]="!hasMultiImages||!hasNextImg">
            <mat-icon class="!text-[40px] !w-10 !h-10 !leading-10 text-gray-600">chevron_right</mat-icon>
          </div>
        </div>

        <div class="absolute top-0 left-8 h-full w-2/5 p-12 flex flex-col justify-center gap-6 rounded-l-3xl
          bg-gradient-to-r from-[#172139] to-transparent mobile:w-full mobile:bg-gradient-to-t mobile:h-1/2 mobile:left-0
          mobile:p-4 mobile:rounded-none mobile:top-auto mobile:bottom-0 mobile:justify-end">
          <div class="text-3xl font-bold text-white animate-left-in mobile:text-lg mobile:leading-5">{{ place.name }}
          </div>
          <div class="text-base text-gray-300 animate-right-in mobile:hidden">{{ getDisplayCategory(place) }}
          </div>
          <!-- <div class="text-base mobile:hidden">&nbsp;</div> -->
        </div>

        <div *ngIf="place.business_status?.includes('CLOSED'); else hours"
             class="absolute top-0 right-[10%] text-white font-semibold bg-red-400 text-center px-3 py-1 rounded-b-lg mobile:w-full mobile:right-0 mobile:left-0">
          {{ place.business_status.replace('_', ' ') }}
        </div>
        <ng-template #hours>
          <div *ngIf="place?.opening_hours" class="absolute top-0 right-[10%] z-10 mobile:w-full mobile:right-0">
            <ig-opening-hours [openingHours]="place.opening_hours"></ig-opening-hours>
          </div>
        </ng-template>
      </div>

      <div class="ig-place-details details-animate-bottom-up w-full h-full overflow-y-auto absolute top-0 pt-[31.5vw] mt-[65px]
        mobile:relative mobile:mt-0 mobile:pt-0 mobile:overflow-visible">
        <div class="w-4/5 h-auto relative left-[10%] -top-[132px] rounded-3xl shadow-xl pt-7 pb-5 px-6 xl:pt-4 2xl:pt-7 xl:px-2 2xl:px-6
          mobile:px-0 mobile:top-0"
             style="background: linear-gradient(to bottom, rgba(255,255,255,0.7),  #f7f7f7 58px)">
          <div class="flex mobile:block">
            <div class="pt-4 px-6 hidden mobile:block">
              <div class="text-gray-700 font-semibold text-base mb-1">Category</div>
              <div class="text-gray-500 font-sm">{{ place.category }}</div>
            </div>
            <div class="pt-4 px-6 border-r-2 border-solid border-gray-300 min-w-[273px] mobile:border-none">
              <div class="text-gray-700 font-semibold text-base mb-1">Address</div>
              <div class="text-gray-500 font-sm max-w-[240px] 2xl:max-w-[330px]">{{ place.address.address }}</div>
              <ig-add-to-trip *ngIf="settings.toggle.tripPlanner" [item]="place" class="block mt-2"></ig-add-to-trip>
            </div>
            <div *ngIf="place.rating"
                 class="pt-4 px-6 border-r-2 border-solid border-gray-300 w-[180px] mobile:border-none">
              <div class="text-gray-700 font-semibold text-base">Rating</div>
              <div class="flex items-center gap-2">
                <div class="text-gray-500 font-sm">{{ place.rating }}</div>
                <ig-stars [rating]="place.rating"></ig-stars>
              </div>

              <div *ngIf="place.priceRange" class="pt-2 min-w-[146px]">
                <div class="text-gray-700 font-semibold text-base mb-1">Price Level</div>
                <div class="text-gray-500 font-sm">{{ place.priceRange }}</div>
              </div>
            </div>
            <!-- <div *ngIf="place.priceRange"
              class="pt-4 px-6 hidden border-r-2 border-solid border-gray-300 min-w-[146px] 2xl:block mobile:border-none">
              <p class="text-gray-700 font-semibold text-base mb-1">Price Level</p>
              <div class="text-gray-500 font-sm">{{place.priceRange}}</div>
            </div> -->
            <div class="pt-4 pl-6 pr-4">
              <div class="text-gray-700 font-semibold text-base mb-1">Contact</div>
              <div class="text-gray-500 font-sm mb-1">{{ place.phone }}</div>
              <div *ngIf="hasWebsite" class="flex mb-2 gap-2 items-center cursor-pointer" (click)="visitWebsite()"
                   matTooltip="View website">
                <ig-image class="block w-[26px] h-[26px]" cls=""
                          src="https://iti-images.s3.amazonaws.com/imgs/info.png"></ig-image>
                <a class="ellipsis text-sm">{{ isAccommodation ? 'Book Now' : 'View website' }}</a>
              </div>
              <div *ngIf="hasFacebookLink" class="flex gap-2 items-center cursor-pointer -translate-y-1"
                   (click)="visitFacebookPage()"
                   matTooltip="View facebook page">
                <ig-image class="block w-[26px] h-[26px]" cls=""
                          src="https://iti-images.s3.amazonaws.com/imgs/info.png"></ig-image>
                <a class="ellipsis text-sm">View Facebook Page</a>
              </div>
              <ig-social-share [item]="place" appearance="rounded" [showTip]="false" (onGetLink)="showLink()"
                               class="flex mb-2 justify-center -ml-1.5 mobile:justify-start"></ig-social-share>
            </div>
            <!-- ShelbyCoIN uses the script solution inside an iframe due to the limit of their CMS, which we are not able to show QRcode -->
            <!-- https://visitshelbycounty.com/festivals-events -->
            <div *ngIf="hubName!=='ShelbyCoIN'"
                 class="ig-qr-wrap pt-4 gap-4 items-center absolute right-8 mobile:hidden">
              <ig-qrcode class="flex justify-center"></ig-qrcode>
              <div class="text-xs mt-1 w-[88px] text-center">Scan to view on mobile phone</div>
            </div>
          </div>

          <div *ngIf="hasServes" class="px-6 mt-2 mobile:mt-6">
            <div class="text-gray-600 font-semibold text-base mb-1">Services</div>
            <ig-dine-icons [place]="place" [size]="42" [showText]="false"></ig-dine-icons>
          </div>
        </div>

        <div class="mt-2 mx-[10%] p-6 rounded-3xl bg-white relative -top-24 mobile:top-7 mobile:mx-[5%]">
          <div class="mb-10" *ngIf="place.description||place.about">
            <div class="text-2xl text-gray-600 font-bold mb-4">Description</div>
            <div class="text-gray-600 text-base">{{ place.description || place.about }}</div>
          </div>

          <div *ngIf="place.youtubeLink||place._360VideoKey" class="mb-10">
            <div class="text-2xl text-gray-600 font-bold mb-3">{{ videoTitle }}</div>
            <div class="flex gap-4 mobile:flex-col">
              <div *ngIf="place.youtubeLink"
                   class="text-gray-600 h-[300px] w-[70%] mx-auto mobile:w-full mobile:h-[200px]">
                <iframe vision *ngIf="place.youtubeLink" width="100%" height="100%" [src]="getYoutubeLink()"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
              </div>
              <div *ngIf="place._360VideoKey"
                   class="text-gray-600 h-[300px] w-[70%] mx-auto mobile:w-full mobile:h-[200px]">
                <iframe [src]="get360VideoLink()" frameborder="0" allowfullscreen="1" width="100%" height="100%"
                        style="max-width:100%;"></iframe>
              </div>
            </div>
          </div>

          <div class="mb-10">
            <div class="text-2xl text-gray-600 font-bold">Nearby Places to Explore</div>

            <ig-nearby-places #nearbyPlacesCom [place]="place" [range]="range"
                              (rangeChanged)="isShowingNearbyPlacesOnMap&&toggleShowNearbyPlacesOnMap({checked:true})">
            </ig-nearby-places>

          </div>

          <div *ngIf="settings?.toggle?.nearbyEventsInPlace" class="mb-10 relative ig-nearby-events">
            <div class="text-2xl text-gray-600 font-bold mb-4">Nearby Events to Explore</div>
            <div class="flex gap-1 items-center absolute top-[22px] left-[372px] z-10 text-[#41abf3]">
              <mat-icon class="leading-6">date_range</mat-icon>
              <ig-event-date-filter #dateFilter mode="dropdown" menuClass="ig-date-filter"
                                    (dateSelected)="filterEventsByDate($event)"></ig-event-date-filter>
            </div>
            <div *ngIf="displayingNearbyEvents" class="-mt-3 mb-3 flex justify-between items-center relative">
              <div class="flex gap-6 mobile:block">
                <div>{{ displayingNearbyEvents.length }} events within {{ nearbyEventsRange }} miles range</div>
                <ig-set-range [range]="nearbyEventsRange" (rangeSet)="setNearbyEventsRange($event)"></ig-set-range>
              </div>

              <div *ngIf="nearbyEventsMode==='normal'" class="flex gap-4 absolute right-0 -top-4 mobile:hidden">
                <ng-container *ngIf="displayingNearbyEvents.length > 4">
                  <mat-icon
                    class="!text-[40px] !w-10 !h-10 !leading-10 text-gray-500 rounded-full bg-gray-100 relative z-50"
                    [class.text-gray-200]="isAtStart" [class.cursor-pointer]="!isAtStart"
                    (click)="goLeft()">chevron_left
                  </mat-icon>
                  <mat-icon
                    class="!text-[40px] !w-10 !h-10 !leading-10 text-gray-500 rounded-full bg-gray-100 relative z-50"
                    [class.text-gray-200]="isAtEnd" [class.cursor-pointer]="!isAtEnd"
                    (click)="goRight()">chevron_right
                  </mat-icon>
                </ng-container>
              </div>
            </div>

            <div>
              <div *ngIf="!isLoadingNearby&&!displayingNearbyEvents.length"
                   class="w-1/2 max-h-[260px] mx-auto mobile:mt-0 mobile:w-full">
                <ig-no-content textCls="text-3xl mt-0"></ig-no-content>
              </div>
              <div #nearbyEventsContainer *ngIf="nearbyEventsMode==='normal'"
                   class="flex justify-start items-center gap-4 overflow-hidden scroll-smooth animate-bottom-up mobile:flex-col mobile:max-h-[556px] mobile:overflow-y-auto">
                <div *ngFor="let item of displayingNearbyEvents;let i = index" #card (click)="goToEvent(item)" class="rounded-xl max-w-[calc(25%_-_12px)] min-w-[calc(25%_-_12px)] w-[calc(25%_-_12px)] relative transition-all cursor-pointer
                  mobile:w-full mobile:min-w-full mobile:max-w-full">
                  <div class="rounded-3xl shadow-md mb-3 cursor-pointer mobile:mx-0.5">
                    <ig-image [src]="item.cover?.source" class="block overflow-hidden"
                              cls="aspect-[3/2] w-full rounded-t-3xl transition-all hover:scale-[1.03]"></ig-image>
                    <div class="p-4 bg-white rounded-b-3xl">
                      <div class="text-sm font-semibold text-gray-600 mb-1 -mt-1 ellipsis">{{ item.name }}</div>
                      <div class="text-xs text-gray-500 max-lines-2 min-h-[32px]">
                        <span>{{ item.timeTwoLines?.date }}</span>
                        <span *ngIf="item.eventTimes.length>1||item.timeTwoLines?.time" class="mx-2">·</span>
                        <span>{{ item.eventTimes.length > 1 ? 'Multiple times' : item.timeTwoLines?.time }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="nearbyEventsMode==='timeline'" class="animate-bottom-up">
                <ig-timeline #timeline [events]="displayingNearbyEvents"></ig-timeline>
              </div>
            </div>
            <div *ngIf="isLoadingNearby" #loading>
              <div class="h-[237px] flex justify-center items-center">
                <ig-spinner mode="block" color="#41abf3"></ig-spinner>
              </div>
            </div>
          </div>

          <div class="mb-10 mobile:mb-0">
            <div class="text-2xl text-gray-600 font-bold mb-2">View on Map</div>
            <div class="flex justify-between items-center h-6 mobile:flex-col mobile:h-auto">
              <div class="text-gray-600 text-sm">{{ place.address?.address }}</div>
              <!-- <ig-button [style]="'flat'" class="w-[160px] mobile:mt-2 mobile:!w-full"
                cls="w-full min-w-full !text-base mb-2" (trigger)="getDirection(place)">
                Get Directions
              </ig-button> -->
              <div
                class="w-[160px] mobile:!w-full !text-base mb-2 bg-[#41abf3] text-white flex justify-center items-center h-8 cursor-pointer transition-all rounded-sm hover:shadow-md"
                (click)="getDirection(place)">Get Directions
              </div>
            </div>
            <div class="flex gap-4 relative -left-[10px] mobile:mb-1">
              <mat-checkbox [checked]="isShowingNearbyPlacesOnMap" (change)="toggleShowNearbyPlacesOnMap($event)">Show
                nearby places
              </mat-checkbox>
              <mat-checkbox *ngIf="settings?.toggle?.nearbyEventsInPlace" [checked]="isShowingNearbyEventsOnMap"
                            (change)="toggleShowNearbyEventsOnMap($event)">Show
                nearby events
              </mat-checkbox>
            </div>
            <ig-google-map #map class="h-[300px] w-full transition-all"
                           [class.h-[400px]]="isShowingNearbyEventsOnMap||isShowingNearbyPlacesOnMap"
                           [markers]="mapMarkers"
                           [useDefaultMarkerClickStyle]="false" (onMarkerClick)="markerClicked($event)"></ig-google-map>
          </div>

          <div class="mobile:hidden">
            <hr class="!mt-10">
            <ig-powered-by [style]="'iti-horizontal'"
                           class="mt-6 mx-auto flex justify-center mobile:hidden"></ig-powered-by>
          </div>
        </div>
      </div>
    </section>

    <!-- hide for manual places, and Google places which don't have reviews -->
    <section *ngIf="hasGoogleReviews" class="w-1/4 bg-white rounded-t-xl mobile:w-full mobile:h-fit">
      <div class="text-gray-600 h-full">
        <!-- googleReviews is from database, reviews is from Google API -->
        <ig-google-place-reviews [googleId]="place?.id" [reviews]="place?.googleReviews"></ig-google-place-reviews>
      </div>
    </section>

    <div class="hidden pb-7 mobile:block">
      <hr class="mt-10">
      <ig-powered-by [style]="'iti-horizontal'" class="mt-6 mx-auto flex justify-center"></ig-powered-by>
    </div>
  </div>

  <!--  <ig-im-messager [target]="placeNameAddress" [place]="place"></ig-im-messager>-->
</div>

<div *ngIf="isLoading" class="py-12 px-40 flex h-full mobile:px-4 mobile:py-4">
  <section class="w-[80%] h-full mobile:w-full">
    <div class="!rounded-xl w-full h-1/3 ig-block"></div>
    <div class="mt-8 w-[20%] h-6 ig-block mobile:w-3/5"></div>
    <div class="mt-4 w-[10%] h-6 ig-block mobile:w-2/5"></div>
    <div class="mt-4 w-[60%] h-6 ig-block mobile:w-full"></div>

    <div class="flex gap-4 mt-8 h-[14vw] justify-between mobile:gap-2 mobile:h-[40vw]">
      <div class="ig-block"></div>
      <div class="ig-block"></div>
      <div class="ig-block mobile:hidden"></div>
      <div class="ig-block mobile:hidden"></div>
    </div>
  </section>

  <section class="w-[20%] h-full gap-6 flex-col flex ml-[2vw] mobile:hidden">
    <div class="w-full max-h-[237px] ig-block"></div>
    <div class="w-full max-h-[237px] ig-block"></div>
    <div class="w-full max-h-[237px] ig-block"></div>
  </section>
</div>

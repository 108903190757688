import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';
import { PlaceType } from 'src/app/modules/shared/models/place.type';
import { MatMenuTrigger } from '@angular/material/menu';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';

@Component({
  selector: 'ig-nested-menu',
  templateUrl: './nested.menu.component.html',
  styleUrls: ['./nested.menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NestedMenuComponent
  extends BaseComponent
  implements AfterViewInit
{
  @Input() items: PlaceType[] = [];

  @Input() node: PlaceType = null;
  @Input() isRootNode = true;

  _title: string = 'FILTER BY CATEGORY';
  @Input() set title(title: string) {
    this._title = title || 'FILTER BY CATEGORY';
  }

  @Output() onClick: EventEmitter<PlaceType | null> =
    new EventEmitter<PlaceType | null>();

  @Output() public onIconClick = new EventEmitter();

  isMenuOpened = false;
  isSubMenuOpened = false;

  // if visit a custom place category from URL, only render the category and sub-categories of this node in the dropdown
  // so this is the root node of the specified category
  rootCustomCategoryFromURL: PlaceType = null;

  constructor(
    public visitorPlaceService: VisitorPlaceService,
    public activatedRoute: ActivatedRoute
  ) {
    super({ activatedRoute });
  }
  ngAfterViewInit(): void {
    const btnId = this.allRouteParams['categoryId'];
    if (btnId) {
      const category = this.visitorPlaceService.findCategoryById(
        btnId,
        this.frontendSettings.customSettings.customDefaultPlaceCategory
          .customCategories
      );
      this.rootCustomCategoryFromURL = category || null;
    }
  }

  get isFiltering() {
    return this._title && this._title !== 'FILTER BY CATEGORY';
  }

  isExpandable(node: PlaceType) {
    return node?.subCategories?.length > 0;
  }

  // Savannah topbar custom place category has wierd font issue, so process it here temporarily
  isSavannah() {
    return this.hubName === 'Savannah';
  }

  openMenu(trigger: MatMenuTrigger) {
    trigger.openMenu();
  }
  triggerLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (this.isSubMenuOpened) return;
      trigger.closeMenu();
    }, 80);
  }

  subMenuEnter() {
    this.isSubMenuOpened = true;
  }
  subMenuLeave() {
    this.isSubMenuOpened = false;
  }

  select(node?: PlaceType | null) {
    this.onClick.emit(node);
  }

  iconClicked($event) {
    // item selected, clear filter
    // if (this.title) {
    if (this.isFiltering) {
      $event.stopPropagation();
      this.onIconClick.emit();
    }
  }

  menuOpened() {
    this.isMenuOpened = true;
    setTimeout(() => {
      const menu = document.querySelector('.ig-nested-menu');
      const trigger = this.shadowRoot?.querySelector('.ig-nested-menu-wrap');
      const rect = trigger?.getBoundingClientRect();

      if (!menu || !rect) return;

      (menu as HTMLElement).style.width = rect.width + 'px';
      (menu as HTMLElement).style.maxWidth = rect.width + 'px';
    });
  }
}

import { CreateEventComponent } from './../../../../event-calendar/components/create.event/create.event.component';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import {
  getRouteUrl,
  routeConfigs,
} from 'src/app/common/constants/route.config';
import { MediaQueryService } from 'src/app/common/services/media.query/media.query.service';
import { VisitorEventService } from 'src/app/modules/event-calendar/services/visitor.event.service';
import { CalendarActionCommand } from '../../../models/calendar.action.command';
import { FeatureToggle } from '../../../models/feature.toggle';
import {
  IAppState,
  IFrontendSettings,
} from '../../../ngrx.stores/frontend.settings/states';
import * as EventQueryCommandActions from 'src/app/modules/event-calendar/ngrx.stores/event.query.command/actions';
import * as PlaceQueryCommandActions from 'src/app/modules/place-calendar/ngrx.stores/place.query.command/actions';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { CreatePlaceComponent } from 'src/app/modules/place-calendar/components/create.place/create.place.component';
import { frontendSettingsSelector } from '../../../ngrx.stores/frontend.settings/selectors';

@Component({ template: '' })
export class VisitorMenuBarBaseComponent extends BaseComponent {
  public command = new CalendarActionCommand();
  public searchText: string;
  public featuresToggle = new FeatureToggle();
  public searchControl: FormControl = new FormControl('');
  settings: IFrontendSettings;

  @Input() menuClass: string = '';
  @Output() onClose = new EventEmitter();

  testWithLocationFilter = ['randy', 'test', 'test6'];

  search$ = this.searchControl.valueChanges
    .pipe(
      debounceTime(1600),
      distinctUntilChanged(),
      tap((value) => {
        this._store.dispatch(
          this.command.isEventCalendar
            ? EventQueryCommandActions.setSearchText({
                search: value,
              })
            : PlaceQueryCommandActions.setSearchText({
                search: value,
              })
        );
      })
      // tap(() => {
      //   this._router.navigateByUrl(
      //     getRouteUrl(
      //       this.command.isEventCalendar
      //         ? routeConfigs.visitor.eventCalendar
      //         : routeConfigs.visitor.placeCalendar,
      //       {
      //         hubName: this.hubName,
      //       }
      //     ),
      //     { skipLocationChange: this.noChangeUrlWhenRedirect }
      //   );
      //   // this.close();
      // })
    )
    .subscribe();

  constructor(
    public activatedRoute: ActivatedRoute,
    public mediaQueryService: MediaQueryService,
    public eventService: VisitorEventService,
    public modalService: ModalService,
    protected _router: Router,
    protected _store: Store<IAppState>
  ) {
    super({ activatedRoute });

    const eventCalendarPath = routeConfigs.visitor.eventCalendar;
    this.command.isEventCalendar = this._router.url.includes(
      `/${eventCalendarPath.path}`
    );

    this.command.isMapView = this._router.url.includes(
      `${eventCalendarPath.path}/${eventCalendarPath.eventMap.path}`
    );

    this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
      settings && (this.settings = settings);
    });
  }

  get isEventEnabled(): boolean {
    return (
      this.settings?.toggle?.eventByGeolocation ||
      this.settings?.toggle?.manualEvents
    );
  }
  get isPlaceEnabled(): boolean {
    return this.settings?.toggle?.placeCalendar;
  }

  public viewChange() {
    this.command.isMapView = !this.command.isMapView;

    let path;
    if (this.command.isMapView) {
      path = this.command.isEventCalendar
        ? routeConfigs.visitor.eventCalendar.eventMap
        : routeConfigs.visitor.placeCalendar.placeMap;
    } else {
      path = this.command.isEventCalendar
        ? routeConfigs.visitor.eventCalendar
        : routeConfigs.visitor.placeCalendar;
    }

    this._router.navigateByUrl(
      getRouteUrl(path, {
        hubName: this.hubName,
      }),
      { skipLocationChange: this.noChangeUrlWhenRedirect }
    );

    this.close();
  }

  public addEventsManually() {
    // this._router.navigateByUrl(
    //   getRouteUrl(routeConfigs.visitor.eventCalendar.createEvent, {
    //     hubName: this.hubName,
    //   }),
    //   { skipLocationChange: this.noChangeUrlWhenRedirect }
    // );

    if (this.command.isEventCalendar) {
      this.modalService.show<CreateEventComponent>({
        component: CreateEventComponent,
        panelClass: 'ig-modal-w-full',
      });
    } else {
      this.modalService.show<CreatePlaceComponent>({
        component: CreatePlaceComponent,
        panelClass: 'ig-modal-w-full',
      });
    }

    this.close();
  }

  public addPlacesManually() {
    this.modalService.show<CreatePlaceComponent>({
      component: CreatePlaceComponent,
      panelClass: 'ig-modal-w-full',
    });

    this.close();
  }

  public close() {
    this.onClose.emit();
  }

  filterEventsByDate(command: { from: Date; to: Date }) {
    this._store.dispatch(
      EventQueryCommandActions.setDateRange({
        from: command.from,
        to: command.to,
      })
    );
    this.close();

    // this._router.navigateByUrl(
    //   getRouteUrl(routeConfigs.visitor.eventCalendar, {
    //     hubName: this.hubName,
    //   }),
    //   { skipLocationChange: this.noChangeUrlWhenRedirect }
    // );
  }
}

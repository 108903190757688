import { Component, Inject, InjectionToken } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ButtonTriggerEvent } from 'src/app/common/components/button/button.component';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';

@Component({
  selector: 'ig-edit-place-category-modal',
  templateUrl: './edit.place.category.modal.component.html',
  styleUrls: ['./edit.place.category.modal.component.scss'],
})
export class EditPlaceCategoryModalComponent extends BaseComponent {
  place: VisitorPlace;
  isUseDefault = true;
  settings: IFrontendSettings | null = null;
  actions: EditPlaceCategoryModalActions;

  constructor(
    private _placeService: VisitorPlaceService,
    private _store: Store<IAppState>,
    private dialogRef: MatDialogRef<EditPlaceCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: IEditPlaceCategoryModalData
  ) {
    super();
    this.place = Object.assign({}, data.place);
    this.actions = Object.assign({}, data.actions);

    console.log(this.place.categoryList);

    this._store
      .pipe(takeUntil(this.isDestroyed), select(frontendSettingsSelector))
      .subscribe((settings) => {
        this.settings = settings;
      });
  }

  close() {
    this.dialogRef.close();
  }

  isSelected(categoryTitle: string) {
    return this.place.categoryList.indexOf(this.toCode(categoryTitle)) >= 0;
  }

  public remove(categoryTitle: string) {
    categoryTitle = this.toCode(categoryTitle);
    if (this.place.categoryList.indexOf(categoryTitle) >= 0) {
      this.place.categoryList = this.place.categoryList.filter(
        (x) => x !== categoryTitle
      );
      this.place.category = this.place.categoryList.join(', ');
      const readable = this.place.categoryList.map((x) => this.toReadable(x));
      this.place.categoryText = readable.join(', ');
    }
  }

  public async confirm(event: ButtonTriggerEvent) {
    console.log(this.place);

    this.actions.confirm(event, {
      category: this.place.category,
      categoryList: this.place.categoryList,
      categoryText: this.place.categoryText,
    });
    this.dialogRef.close();
  }

  toReadable(code: string) {
    return this._placeService.toReadableCategory(code);
  }

  toCode(category: string) {
    return this._placeService.toCategoryCode(category);
  }

  getPlaceCategotiesByBusinessType() {
    const businessType = this.settings.dataSource.businessType || 'normal';

    return this.settings.placeCategorySettings?.mergedDefaultCategories?.mergedDefaultCategories.filter(
      (x) => x.forBusinessTypes.includes(businessType)
    );
  }

  normalCategorySelected(categoryTitle: string) {
    categoryTitle = this.toCode(categoryTitle);
    if (this.place.categoryList.indexOf(categoryTitle) >= 0) {
      this.place.categoryList = this.place.categoryList.filter(
        (x) => x !== categoryTitle
      );
    } else {
      this.place.categoryList.push(categoryTitle);
    }
    this.place.category = this.place.categoryList.join(', ');
    const readable = this.place.categoryList.map((x) => this.toReadable(x));
    this.place.categoryText = readable.join(', ');
  }
}

export class EditPlaceCategoryModalActions {
  confirm: (
    event: ButtonTriggerEvent,
    category: {
      category: string;
      categoryList: string[];
      categoryText: string;
    }
  ) => void;
  cancel?: () => void;
}

export interface IEditPlaceCategoryModalData {
  actions: EditPlaceCategoryModalActions;
  place: VisitorPlace;
}

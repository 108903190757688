import { Component, Input } from '@angular/core';

@Component({
  selector: 'ig-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() public diameter = 32;
  @Input() public strokeWidth = 3;
  @Input() public cls = '';
  @Input() public mode = 'circle';
  @Input() public color = 'white';

  constructor() {}
}

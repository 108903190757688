<div>
  <ig-accordion #accordion *ngIf="item.subCategories.length" mode="expand" [title]="item.title">
    <div class="sub-cate-wrapper" expand>

      <div item class="cursor-pointer" (click)="setCategories(item)"
        [ngStyle]="{'color':isSelected?'#41abf3':'rgba(0, 0, 0, 0.87)','fontWeight':isSelected?'bold':'normal'}">All
      </div>

      <div *ngFor="let sub of item.subCategories;let i=index" #subaccordion>
        <ig-nested-accordion-item [item]="sub" [title]="sub.title" [parent]="item" (onClick)="setCategories($event)">
        </ig-nested-accordion-item>
      </div>

    </div>
  </ig-accordion>

  <div item *ngIf="!item.subCategories.length" class="flex justify-between" (click)="setCategories(item)">
    <span class="cursor-pointer">
      <span
        [ngStyle]="{'color':isSelected?'#41abf3':'rgba(0, 0, 0, 0.87)','fontWeight':isSelected?'bold':'normal'}">{{item.title}}</span>
    </span>
  </div>
</div>
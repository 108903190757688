import { Component, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { PlaceType } from 'src/app/modules/shared/models/place.type';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';

@Component({
  selector: 'ig-place-category-selector',
  templateUrl: './place.category.selector.component.html',
  styleUrls: ['./place.category.selector.component.scss'],
})
export class PlaceCategorySelectorComponent {
  @Input() public item: PlaceType = new PlaceType();
  @Input() public place: VisitorPlace;
  @Input() public blockGooglePlaceCategories: string[] = [];

  constructor(private _placeService: VisitorPlaceService) {}

  isCreateNewPlace() {
    return !this.place;
  }

  isSelected(categoryTitle: string) {
    if (this.isCreateNewPlace()) return false;
    return this.place.categoryList.includes(categoryTitle);
  }

  public selectionChange(category: MatCheckboxChange) {
    let categoryTitle = category.source.name;

    categoryTitle = this.toCode(categoryTitle);
    if (this.place.categoryList.indexOf(categoryTitle) >= 0) {
      this.place.categoryList = this.place.categoryList.filter(
        (x) => x !== categoryTitle
      );
    } else {
      this.place.categoryList.push(categoryTitle);
    }
    this.place.category = this.place.categoryList.join(', ');
    const readable = this.place.categoryList.map((x) => this.toReadable(x));
    this.place.categoryText = readable.join(', ');
  }

  toReadable(code: string) {
    return this._placeService.toReadableCategory(code);
  }

  toCode(category: string) {
    return this._placeService.toCategoryCode(category);
  }
}

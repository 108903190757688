<div class="cursor-pointer flex gap-3 w-full mobile:block">
  <div class="flex gap-3 w-[calc(50%-6px)] mobile:w-[calc(100%-1px)]">
    <mat-form-field #start (click)="$event.stopPropagation();picker.open()" appearance="outline">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="picker" (focus)="onFocus()" [formControl]="startFormControl"
        (dateChange)="setFormControlValue(startFormControl, startSelect)" [max]="endFormControl.value" [min]="today()">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field #starttime appearance="outline">
      <mat-label>Time</mat-label>
      <mat-select (selectionChange)="setFormControlValue(startFormControl, startSelect)" (opened)="onFocus()"
        #startSelect>
        <mat-option value="00:00">All Day</mat-option>
        <mat-option *ngFor="let time of timeValues" [value]="time.value">{{time.text}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex gap-3 w-[calc(50%-6px)] mobile:w-[calc(100%-1px)]">
    <mat-form-field #end (click)="$event.stopPropagation();endpicker.open()" appearance="outline">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endpicker" (focus)="onFocus()" [formControl]="endFormControl"
        (dateChange)="setFormControlValue(endFormControl, endSelect)" [min]="startFormControl.value">
      <mat-datepicker #endpicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field #endtime appearance="outline">
      <mat-label>Time</mat-label>
      <mat-select (opened)="onFocus()" (selectionChange)="setFormControlValue(endFormControl, endSelect)"
        (opened)="onFocus()" #endSelect>
        <mat-option value="00:00">All Day</mat-option>
        <mat-option *ngFor="let time of timeValues" [value]="time.value">{{time.text}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ErrorAlertService {
  constructor(private _snackBar: MatSnackBar) {}

  public showErrorAlert(error: string) {
    this._snackBar.open(error, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'ig-error-snackbar',
    });
  }
}

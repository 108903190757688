<div>
  <div *ngFor="let control of controls.controls; let i = index" class="flex gap-2">
    <ig-date-time-picker [startFormControl]="control.controls.startTime" [endFormControl]="control.controls.endTime"
      class="w-[93%]" [timezoneFormatter]="timezoneFormatter"></ig-date-time-picker>
    <div class="flex">
      <button mat-icon-button *ngIf="i===0" (click)="addDates()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button *ngIf="i!==0" (click)="removeDates(i)">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </div>

</div>
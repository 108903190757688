import { Injectable, Provider, TemplateRef, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { isEmptyArray } from '../../utils/object.extensions';
import { LocalStorageService } from 'src/app/modules/shared/services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public static modalConfiguration: MatDialogConfig = {
    width: '600px',
  };

  constructor(
    private dialog: MatDialog,
    private _localStorageService: LocalStorageService
  ) {}

  public show<T>(config: {
    component: Type<T> | TemplateRef<T>;
    data?: any;
    providers?: Provider[];
    panelClass?: string | string[];
    backdropClass?: string;
  }) {
    let dialogRef = this.dialog.open(
      config.component,
      Object.assign({}, ModalService.modalConfiguration, {
        // data: config.providers,
        data: config.data, //config.providers,
        panelClass: config.panelClass,
        backdropClass: config.backdropClass,
      })
    );
    return dialogRef;
  }

  public injectStyle(selector: string, onLoad: () => void): void {
    const all = document.querySelectorAll(selector);
    if (isEmptyArray(all)) {
      console.warn('No element found with the selector', selector);
      return;
    }
    console.log('all ----------------', selector);
    console.log(all);

    const styleUrl =
      this._localStorageService.getItem<string>('STYLES_URL') ||
      'https://localhost:4201/styles.css';
    console.log('styleUrl ----------------', styleUrl);

    console.log('Array.from(all) ----', Array.from(all)?.length);
    console.log(Array.from(all));

    console.log('Array.from(all).last() ----');
    console.log(Array.from(all)?.last());

    let shadowRoot = Array.from(all)?.last()?.shadowRoot;

    if (!Array.from(all)?.length || !Array.from(all)?.last() || !shadowRoot) {
      console.log('noe array length');
      const ele = document.querySelector(selector);
      console.log('ele', ele);
      console.log('ele.shadowRoot', ele.shadowRoot);
      if (ele) {
        shadowRoot = ele.shadowRoot;
      }
    }

    console.log('shadowRoot ----------------');
    console.log(shadowRoot);

    const linkElem = document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', styleUrl);

    linkElem.onload = () => {
      onLoad && onLoad();
    };

    console.log('before appendChild');
    shadowRoot.appendChild(linkElem);
    console.log('after appendChild');
  }
}

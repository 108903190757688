import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PlaceType } from 'src/app/modules/shared/models/place.type';

@Component({
  selector: 'ig-nested-accordion-item',
  templateUrl: './nested.accordion.item.component.html',
  styleUrls: ['./nested.accordion.item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NestedAccordionItemComponent {
  @HostBinding('class') hostClass = 'ig-nested-accordion-item';

  @Input() public item: PlaceType;

  @Output() public onClick = new EventEmitter<PlaceType>();

  setCategories(item: PlaceType): void {
    this.onClick.emit(item);
  }
}

<div class="confirm-modal ig-modal edit-place-category-modal">
  <div class="close-button">
    <mat-icon (click)="close()">clear</mat-icon>
  </div>
  <h3 class="title">EDIT PLACE CATEGORIES</h3>

  <!--    <div class="text-left m-b-3"><b>Selected categories: </b><span>{{place.categoryText}}</span></div>-->
  <div class="text-left mt-3 mb-5">
    <b>Selected categories: </b>

    <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
      <mat-chip *ngFor="let category of place.categoryList" [selectable]="true" [removable]="true"
        (removed)="remove(category)">
        {{toReadable(category)}}
        <mat-icon matChipRemove matTooltip="remove">cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
  </div>

  <div class="max-h-[70vh] overflow-y-auto">
    <ng-container *ngIf="!settings.customSettings.customDefaultPlaceCategory.useDefault">
      <div *ngFor="let item of settings.customSettings.customDefaultPlaceCategory.customCategories" class="mb-3">
        <ig-place-category-selector [item]="item" [place]="place"
          [blockGooglePlaceCategories]="blockGooglePlaceCategories"></ig-place-category-selector>
      </div>
    </ng-container>

    <ng-container *ngIf="settings.customSettings.customDefaultPlaceCategory.useDefault">
      <div *ngFor="let category of getPlaceCategotiesByBusinessType()" class="m-b-3">
        <div>{{category.keys[0]}}</div>
        <hr>
        <span *ngFor="let sub of category.values">
          <mat-checkbox [checked]="isSelected(sub)" (change)="normalCategorySelected(sub)" [name]="sub"
            class="mr-6">{{toReadable(sub)}}
          </mat-checkbox>
          <mat-icon *ngIf="settings.placeCategorySettings?.blockGooglePlaceCategories?.indexOf(sub)>=0"
            class="text-gray-300 my-0 -ml-6 mr-6" matTooltip="This category is blocked by admin">info
          </mat-icon>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="action-btns">
    <ig-button class="btn-primary" #confirmButton color="primary" size="md" [isAsync]="true"
      (trigger)="confirm($event)">
      Save
    </ig-button>
    <div class="cancel-btn" *ngIf="!confirmButton.isLoading" (click)="close()">
      Cancel
    </div>
  </div>
</div>
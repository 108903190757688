<div class="ig-social-login">
  <img #profileImg *ngIf="profilePicture" [matMenuTriggerFor]="actionMenu" [src]="profilePicture"
    alt="user profile picture" class="w-11 rounded-full cursor-pointer aspect-square">

  <ig-google-login #google [class.ig-hide]="profilePicture" class="block">
    <div id="google-signin-btn" class="google-signin-btn"></div>
  </ig-google-login>

  <div [class.ig-hide]="profilePicture">
    <ig-facebook-login #facebook *ngIf="googleReady$ | async; else loading"
      class="block mt-3 w-[294px] smallMobile:w-[260px]">
    </ig-facebook-login>
  </div>

  <ng-template #loading>
    <div class="flex justify-center">
      <ig-spinner></ig-spinner>
    </div>
  </ng-template>

  <mat-menu #actionMenu="matMenu">
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</div>
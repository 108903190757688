import { VisitorEvent } from './../models/visitor.event';
import { EventTimeService } from './../../event-calendar/services/event.time.service';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/common/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private _eventTimeService: EventTimeService) {}

  public toMailableEvent(
    hubName: string,
    item: VisitorEvent,
    url?: string
  ): string {
    let description = item.description || '';
    description = description.replace(/(\n)+|(\r\n)+/g, ' ');
    description = description.replace(/(&#39;)+/g, '');
    let seg = description.split(' ');
    if (seg.length > 90) {
      seg = seg.slice(0, 90);
      description = seg.join(' ') + '...';
    }

    let name = item.name || item.title;
    name = name ? name.replace(/&/, 'and') : '';
    let address = (item.address.address || item.address).toString();
    address = address.replace(/&/, 'and');

    let link = url ? url : this.getEventUrl(hubName, item);
    let startTime = this._eventTimeService.formatToShareTime(item.startTime);
    let content = ConfigService.config.eventShare.mailTo
      .replace('${subject}', name)
      .replace('${body}', description)
      .replace('${startTime}', startTime)
      .replace('${owner}', item.owner.name)
      .replace('${url}', link);
    return content;
  }

  public toMailablePlace(
    hubName: string,
    item: VisitorEvent,
    url?: string
  ): string {
    let description = item.description || '';
    description = description.replace(/(\n)+|(\r\n)+/g, ' ');
    let seg = description.split(' ');
    if (seg.length > 90) {
      seg = seg.slice(0, 90);
      description = seg.join(' ') + '...';
    }
    let category = item.category ? item.category.replace(/&/, 'and') : '';
    let name = item.name || item.title;
    name = name ? name.replace(/&/, 'and') : '';
    let address = (item.address.address || item.address).toString();
    address = address.replace(/&/, 'and');

    let link = url ? url : window.location.href;

    let content = ConfigService.config.placeShare.mailTo
      .replace('${subject}', name)
      .replace('${body}', description)
      .replace('${category}', category)
      .replace('${address}', address)
      .replace('${url}', link);
    return content;
  }

  public getEventUrl(hubName, item: any) {
    let url = environment.serverDomain;
    url = `${url}/${hubName}/events/details/${item._id}`;
    //url = url.substring(0, url.lastIndexOf('/')) + '/events/' + item._id;
    return url;
  }
}

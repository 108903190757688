import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[igOutsideClick]'
})
export class OutsideClickDirective {
  @Output() outsideClick = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    try {
      // Check if the click was outside of the target element
      if (!this.elementRef.nativeElement.contains(event.target) && event.target !== this.elementRef.nativeElement) {
        this.outsideClick.emit(event);
      }
    } catch (ex) {
      console.warn(`Error in OutsideClickDirective: ${ex}`);
    }
  }
}

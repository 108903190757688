import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  public loadJs(trackId: string) {
    try {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackId;

      script.onload = () => {
        this.grabGtag();
        window['gtag']('js', new Date());
        window['gtag']('config', trackId);
      };

      document.head.appendChild(script);
    } catch (error) {
      console.error('Google Analytics loadJs error', error);
    }
  }

  public grabGtag() {
    window['dataLayer'] = window['dataLayer'] || [];
    if (!window['gtag']) {
      window['gtag'] = function () {
        window['dataLayer'].push(arguments);
      };
    }
  }

  // wait 3 seconds to make sure the script is loaded
  public checkScriptLoaded(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.isScriptExist()) {
        resolve(true);
      } else {
        setTimeout(() => {
          resolve(this.isScriptExist());
        }, 3000);
      }
    });
  }

  public async trackPageView() {
    try {
      if ((await this.checkScriptLoaded()) === false) {
        console.log('Google Analytics script not fount');
        return;
      }

      if (!window['gtag']) {
        this.grabGtag();
      }

      const url = this.handleWrongLocationPath(window.location.href);
      console.log('url for Google Analytics', url);

      window['gtag']('event', 'page_view', {
        page_title: window.location.pathname,
        page_location: url,
        page_path: url,
      });
      console.log('Google Analytics trackPageView');
    } catch (e) {
      console.error('Google Analytics trackPageView error', e);
    }
  }

  public isScriptExist(): boolean {
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].getAttribute('src');
      if (src && src.includes('googletagmanager.com/gtag')) {
        return true;
      }
    }
    return false;
  }

  // private handleWrongLocationPath(url: string) {
  //   let result = '';
  //   if (url.lastIndexOf('?') > 0) {
  //     const list = url.split('?');
  //     if (list.length > 2) {
  //       const tempUrl = list[0] + '?' + list[1];
  //       if (
  //         tempUrl.startsWith('http://') &&
  //         tempUrl.lastIndexOf('https:/') > 0
  //       ) {
  //         let arr = tempUrl.split('https:/');
  //         if (arr.length > 1) {
  //           let address = arr[1];
  //           result =
  //             address[0] === '/' ? 'https:/' + address : 'https://' + address;
  //         }
  //       } else if (
  //         tempUrl.startsWith('https://') &&
  //         tempUrl.lastIndexOf('https:/') > 0
  //       ) {
  //         let arr = tempUrl.split('https:/');
  //         if (arr.length > 2) {
  //           let address = arr[2];
  //           result =
  //             address[0] === '/' ? 'https:/' + address : 'https://' + address;
  //         }
  //       } else if (
  //         tempUrl.startsWith('http://') &&
  //         tempUrl.lastIndexOf('http:/') > 0
  //       ) {
  //         let arr = tempUrl.split('http:/');
  //         if (arr.length > 2) {
  //           let address = arr[2];
  //           result =
  //             address[0] === '/' ? 'https:/' + address : 'https://' + address;
  //         }
  //       }
  //     } else {
  //       result = url;
  //     }
  //   } else {
  //     result = url;
  //   }
  //   return result;
  // }

  private handleWrongLocationPath(url: string) {
    /**
     * https://itidigital.atlassian.net/jira/software/c/projects/DXP/boards/13?selectedIssue=DXP-1575
     
       Sometimes, Google Analytics records strange URL page visits, similar to the following
       the correct one looks like: http://visithighpoint.com/calendar/?imgoing-event=high-point-farmers-market-opening-day-65e76c660ce6ec59f5045937
       but sometimes it could be: http://visithighpoint.com/https://visithighpoint.com/calendar/?imgoing-event=high-point-farmers-market-opening-day-65e76c660ce6ec59f5045937?imgoing-event=high-point-farmers-market-opening-day-65e76c660ce6ec59f5045937
       or: http://visithighpoint.com/https:/visithighpoint.com/calendar/?someparames=123&imgoing-event=4s-5s-visit-all-a-flutter-butterfly-farm--6591ee7205c9a21ea6ef813c?imgoing-event=4s-5s-visit-all-a-flutter-butterfly-farm--6591ee7205c9a21ea6ef813c
    
       This method is to extract the correct part from such a URL
       */

    // Replace HTTP with HTTPS uniformly, and for the convenience of unified processing, replace all https:// with https:/, and replace back with https:// at the end of the method
    url = url.replace(/http:/g, 'https:').replace(/https:\/\//g, 'https:/');
    url = url.includes('/https')
      ? url.substring(url.indexOf('/https:')).substring(1)
      : url;

    let newUrl = '',
      index = 0;

    // build the params from the URL and remove the duplicated parts
    const sp = new URLSearchParams(url);
    for (const [key, value] of sp) {
      console.log('key', key);
      console.log('value', value);
      newUrl += `${index ? '&' : ''}${key}${
        value ? '=' + value.split('?')[0] : ''
      }`;
      index++;
    }

    return newUrl.replace('https:/', 'https://');
  }
}

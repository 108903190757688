<div class="place-category-selector">
  <p>{{item.title}}</p>
  <hr>

  <!--    <ng-container *ngIf="!item.subCategories.length">-->
  <ng-container>
    <!--        add the category code that is same as the level category name-->
    <mat-checkbox *ngIf="item.categoryList.indexOf(toCode(item.title))<0" class="place-category-checkbox"
      [checked]="isSelected(item.title)" [name]="item.title" (change)="selectionChange($event)"
      style="margin-right: 26px">{{toReadable(item.title)}}
    </mat-checkbox>
    <span *ngFor="let category of item.categoryList">
      <mat-checkbox class="place-category-checkbox" [checked]="isSelected(category)" [name]="category"
        (change)="selectionChange($event)" style="margin-right: 26px">{{toReadable(category)}}
      </mat-checkbox>
      <mat-icon *ngIf="blockGooglePlaceCategories?.includes(category)" style="color:lightgray;margin: 0 24px 0 -24px"
        matTooltip="This category is blocked by admin">info
      </mat-icon>
    </span>

    <ng-container *ngIf="item.subCategories.length">
      <div *ngFor="let sub of item.subCategories" style="margin: 12px 0 16px 48px">
        <ig-place-category-selector [item]="sub" [place]="place"
          [blockGooglePlaceCategories]="blockGooglePlaceCategories"></ig-place-category-selector>
      </div>
    </ng-container>
  </ng-container>
</div>
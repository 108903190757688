import { ImageService } from './../../services/image/image.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ig-image-selector',
  templateUrl: './image.selector.component.html',
  styleUrls: ['./image.selector.component.scss'],
})
export class ImageSelectorComponent {
  selectedImageData;
  isImageChanged: boolean = false;
  selectedImageSize = {
    width: 0,
    height: 0
  };

  @Input() public disabled: boolean = false;
  @Output() public onImageChanged = new EventEmitter<any>();

  constructor(public imgService: ImageService) {
  }

  public onImageChange($event) {
    const file = $event.srcElement.files[0];
    if (!!file) {
      let reader = new FileReader();

      reader.onload = async (e) => {
        this.selectedImageData = e.target['result'];
        const imageFile = await this.imgService.compressImageFile(file);
        this.selectedImageSize = await this.imgService.getImageFileSize(file);
        console.log('size', this.selectedImageSize);
        this.onImageChanged.emit(imageFile);
      };

      reader.readAsDataURL(file);
      this.isImageChanged = true;
    }
  }

  public clear() {
    this.selectedImageData = null;
  }
}

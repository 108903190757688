import { BaseComponent } from 'src/app/common/components/base.component';
import { VisitorEvent } from './../../models/visitor.event';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitorService } from '../../services/visitor.service';
// import ShareDialogParams = facebook.ShareDialogParams;
import { ConfigService } from 'src/app/common/services/config/config.service';
import { ShareService } from '../../services/share.service';
import { MediaQueryService } from 'src/app/common/services/media.query/media.query.service';

@Component({
  selector: 'ig-social-share',
  templateUrl: './social.share.component.html',
  styleUrls: ['./social.share.component.scss'],
})
export class SocialShareComponent extends BaseComponent implements OnInit {
  @Input() public item: VisitorEvent;
  @Input() public appearance: 'normal' | 'white' | 'rounded' = 'normal';
  @Input() public showTip = true;
  public link = '';
  public type = null;
  @Output() onShareToFB: EventEmitter<any> = new EventEmitter();
  @Output() onShareToTweet: EventEmitter<any> = new EventEmitter();
  @Output() onShareEmail: EventEmitter<any> = new EventEmitter();
  @Output() onGetLink: EventEmitter<any> = new EventEmitter();

  isMobile = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _eventService: VisitorService,
    private _shareService: ShareService,
    private _mediaQueryService: MediaQueryService
  ) {
    super({activatedRoute});

    this._mediaQueryService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.setItemInfo();
    this.initFB();
  }

  public onKeyPress($event, social) {
    if ($event && $event.key === 'Enter') {
      if (social === 'facebook') {
        this.shareToFacebook();
      } else if (social === 'tweet') {
        this.tweetEvent();
      } else if (social === 'email') {
        this.mailEvent();
      }
    }
  }

  public shareToFacebook() {
    this.setItemInfo();

    const link = `https://api.imgoingcalendar.com/api/common/html%3Ftype=${this.type}%26id=${this.item._id}%26hubName=${this.hubName}%26url=${window.location.href}`;
    // this.link.replace('en-gb', 'www');

    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + link,
      'facebook-share-dialog',
      'width=800,height=600'
    );

    this.onShareToFB.emit();
  }

  public tweetEvent() {
    this.setItemInfo();
    const shareLink = ConfigService.config.eventShare.tweet
      .replace('${text}', this.item.name)
      .replace('${url}', encodeURIComponent(this.link));
    this.popupCenter(shareLink);

    this.onShareToTweet.emit();
  }

  public mailEvent() {
    this.setItemInfo();
    const content =
      this._eventService.getItemType(this.item) === 'event'
        ? this._shareService.toMailableEvent(
          this.item.hubName,
          this.item,
          window.location.href
        )
        : this._shareService.toMailablePlace(
          this.item.hubName,
          this.item,
          window.location.href
        );
    if (this.isMobile) {
      window.open(content);
    } else {
      window.location.href = content;
    }

    this.onShareEmail.emit();
  }

  public getLink() {
    this.onGetLink.emit();
  }

  private setItemInfo() {
    if (this._eventService.getItemType(this.item) === 'event') {
      this.type = 'event';
      this.link = this.item.eventLink || this.item.hostLink;
    } else {
      this.type = 'place';
      // place
    }
    // this.link = this.item.startTime
    //   ? this.item.eventLink
    //   : this.item.hostLink ||
    //     (this.item as HubPlace).hostGoogleLink ||
    //     this.item.hostFacebookLink;
    this.link = this.link || window.location.href;
  }

  private popupCenter(url, title = '', width = 575, height = 400) {
    const dualScreenLeft = window.screenLeft;
    const dualScreenTop = window.screenTop;

    const windowWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    const windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    const left = windowWidth / 2 - width / 2 + dualScreenLeft;
    const top = windowHeight / 2 - height / 2 + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );

    if (window.focus) {
      newWindow.focus();
    }
  }

  private initFB() {
    window['FB'].init(ConfigService.config.facebook.sdkConfig);
  }
}

let pristineObj = {};

export function isTrue(value: boolean) {
  return value;
}

export function isFunction(functionToCheck) {
  return (
    functionToCheck &&
    pristineObj.toString.call(functionToCheck) === '[object Function]'
  );
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export function isEmptyValue(value) {
  return value === undefined || value === null || value === '';
}

export function isEmptyArray(value) {
  return value === undefined || value === null || value.length === 0;
}

export function isEmptyObject(obj) {
  return isObject(obj) && Object.keys(obj).length === 0;
}

export function isNumber(value) {
  return !isNaN(value);
}

export function isNumberWithLength(obj: any, length: number) {
  if (!obj) {
    return false;
  }
  if (!isNumber(obj)) {
    return false;
  }

  let value = parseInt(obj);

  return !isNaN(value) && value.toString().length === length;
}

// export function isNumberWithLengthOrDefault(obj: string | number, length: number) {
//     if (!obj) {
//         return true;
//     }
//
//     return isNumberWithLength(parseInt(obj.toString().replaceAll(' ', '')), length);
// }

export function isEmail(email: string) {
  let reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return reg.test(email);
}

export function isEmailOrDefault(email: string) {
  if (isEmptyValue(email)) {
    return true;
  }

  return isEmail(email);
}

export function bindFunctionsToThis(obj: any) {
  if (!obj) {
    return obj;
  }

  for (let key in obj) {
    if (!pristineObj.hasOwnProperty(key)) {
      let property = obj[key];
      if (isFunction(property)) {
        obj[key] = property.bind(obj);
      }
    }
  }
  return undefined;
}

export function clone<T>(obj: T): T {
  if (!!obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  return obj;
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function upperFirstLetter(text: string) {
  return text ? text[0].toUpperCase() + text.slice(1) : null;
}

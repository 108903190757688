import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { catchError, map, Observable, of, share, tap } from 'rxjs';
import { ConfigService } from 'src/app/common/services/config/config.service';
import {
  IAppState,
  IFrontendSettings,
} from '../ngrx.stores/frontend.settings/states';
import { frontendSettingsSelector } from '../ngrx.stores/frontend.settings/selectors';
import { ApiErrorHandler } from 'src/app/common/services/api/api.error.handler';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  apiLoaded$: Observable<boolean>;
  isLoaded = false;
  ngRxStore = inject(Store<IAppState>);
  apiErrorHandler = inject(ApiErrorHandler);
  frontendSettings: IFrontendSettings;

  constructor(public httpClient: HttpClient) {
    this.ngRxStore
      .pipe(select(frontendSettingsSelector))
      .subscribe((settings) => {
        settings && (this.frontendSettings = settings);
      });
  }

  loadGoogleMapApi(): Observable<boolean> {
    if (this.isLoaded) return of(true);

    if (!this.frontendSettings.customSettings.dataSource.googleKey) {
      this.apiErrorHandler.showError('Google app key not found!');
      return of(false);
    }

    this.apiLoaded$ = this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?v=3&libraries=places&language=en&key=${this.frontendSettings.customSettings.dataSource.googleKey}`,
        // `https://maps.googleapis.com/maps/api/js?v=3&libraries=places&language=en&key=${ConfigService.config.google.appKeys.getRandom()}`,
        'callback'
      )
      .pipe(
        map(() => true),
        share(),
        tap(() => {
          console.log('Google map loaded');
          this.isLoaded = true;
        }),
        catchError((e) => {
          console.error('Loading Google Map Script Error', e);
          return of(false);
        })
      );

    return this.apiLoaded$;
  }

  getDistanceFromLatLng = function (lat1, lon1, lat2, lon2) {
    if (!lat1 || !lon1 || !lat2 || !lon2) {
      return 0;
    }

    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d * 1000;
  };

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MediaQueryService } from '../../services/media.query/media.query.service';

@Component({
  selector: 'ig-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent {
  panelOpenState = false;
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Input() set title(title: string) {
    this.displayTitle = title;
    if (!this.inititalTitle) {
      this.inititalTitle = this.displayTitle;
    }
    this.isItemSelected = this.inititalTitle !== this.displayTitle;
  }
  @Input() canClear: boolean = true;
  @Input() menuClass: string = '';
  @ViewChild('trigger', { static: false }) menuTrigger: ElementRef;
  @ViewChild(MatMenuTrigger, { static: false })
  matMenuTrigger: MatMenuTrigger;
  @ViewChild('menu', { static: false }) menu: MatMenu;

  @Output() public onIconClicked = new EventEmitter();

  isMenuOpened = false;
  isItemSelected = false;
  inititalTitle: string = null;
  displayTitle: string = null;

  constructor(public mediaQueryService: MediaQueryService) {}

  menuOpened() {
    this.isMenuOpened = true;
    const rect = this.menuTrigger.nativeElement.getBoundingClientRect();
    const menu = document.querySelector('.ig-accordion-menu');

    if (!menu || !rect) return;

    (menu as HTMLElement).style.width = rect.width + 'px';
    (menu as HTMLElement).style.maxWidth = rect.width + 'px';
  }

  closeMenu() {
    this.matMenuTrigger?.closeMenu();
  }

  iconClicked($event) {
    if (this.isItemSelected) {
      $event.stopPropagation();
      this.onIconClicked.emit();
    }
  }
}

export const config = {
  // serverDomain: 'https://imgoingcalendar.com/',
  defaultImageUrl: 'assets/imgs/new_placeholder_image.jpg',
  domainV3: 'https://next.imgoingcalendar.com/',
  google: {
    appKeys: [
      'AIzaSyBTK-7XADsofCdPvDWP4Rh2QzSK6t-POaw',
      // 'AIzaSyCJolWCJpnbwVApKEPgWcuadMsJf2lm3Go',
    ],
    mapId: '639d8cda3b06a73f', // custom style map id
    darkModeMapId: '9379425bdc4f148b',
    USCenter: {
      lat: 40.030979347,
      lng: -96.85546875,
    },
    googleMapGetDirection: 'https://maps.google.com?q=${address}',
    placePhoto:
      'https://maps.googleapis.com/maps/api/place/photo?maxwidth=960&photoreference=${photo_reference}&key=${appKey}',
    navigationApiShort:
      'origin=${GPS}&destination=${destination}&waypoints=${waypoints}&travelmode=${mode}&dir_action=navigate',
  },
  facebook: {
    sdkConfig: {
      appId: '252327515144523',
      autoLogAppEvents: true,
      xfbml: false,
      version: 'v15.0',
    },
    logo: 'assets/imgs/facebook.png',
    approvedApps: [
      '252327515144523|c60c9112f5cceb32e279ea060522dd97', // frontend
    ],
    graphApi: {
      scope: 'public_profile',
      profile: 'https://www.facebook.com/${userId}',
      profilePicture:
        'https://graph.facebook.com/${userId}/picture?type=normal',
    },
  },
  youtubeUrl: {
    videoLink: 'https://www.youtube.com/embed/${code}',
  },
  _360VideoUrl:
    'https://cloud.threshold360.com/embed/locations/${id}?web-key=0V3ND-dzGvrv',
  eventShare: {
    mailDateFormat: 'dddd MMM, D, YYYY [at] h:mm a',
    mailTo:
      'mailto:?subject=${subject}&body=${body}%0D%0A%0D%0AStart at: ${startTime}%0D%0A%0D%0AOrganizer: ${owner}%0D%0A%0D%0A${url}',
    tweet: 'https://twitter.com/share?text=${text}&url=${url}',
    googleMapGetDirection: 'https://maps.google.com?q=${address}',
  },
  placeShare: {
    mailTo:
      'mailto:?subject=${subject}&body=${body}%0D%0A%0D%0ACategory: ${category}%0D%0A%0D%0AAddress: ${address}%0D%0A%0D%0A${url}',
  },
  tripPlanner: {
    maxTripCount: 12,
    eventMapIcon: 'assets/imgs/trips/Events-PNG.png',
    placeMapIcon: {
      accommodation: 'assets/imgs/trips/Accommodations-PNG.png',
      arts: 'assets/imgs/trips/Arts-PNG.png',
      dinging: 'assets/imgs/trips/Dining-PNG.png',
      activity: 'assets/imgs/trips/Activities-PNG.png',
      shopping: 'assets/imgs/trips/Shopping-PNG.png',
      outdoor: 'assets/imgs/trips/Outdoors-PNG.png',
      entertainment: 'assets/imgs/trips/Entertainment-PNG.png',
      transportation: 'assets/imgs/trips/Travel-PNG.png',
      other: 'assets/imgs/trips/Other-PNG.png',
    },
  },
};
